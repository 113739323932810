import React from 'react';
import {Box, Container, Grid, useMediaQuery} from '@tenancy.nz/design-system';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import * as constants from '../../utils/constants';
import Logo from '../Logo';
import {StyledHeader} from './Styles';

function Header(props) {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
  const {children, logoAlt, logoSrc, logoSrcMobile, ...rest} = props;
  return (
    <StyledHeader {...rest}>
      <Container fixed>
        <Grid container alignItems="center">
          <Grid item xs={3} lg={2}>
            <Link to="/">
              <Logo
                src={logoSrc}
                srcMobile={logoSrcMobile}
                alt={logoAlt}
                width={isMobile ? '120px' : '150px'}
              />
            </Link>
          </Grid>
          <Grid item xs={9} lg={10}>
            <Box display="flex" justify="flex-end" gap="12px" align="center">
              {children}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </StyledHeader>
  );
}

Header.propTypes = {
  /**
   * Text, component or node
   */
  children: PropTypes.node,

  /**
   * Image alt text to display if logo image is broken
   */
  logoAlt: PropTypes.string,

  /**
   * Path the logo image
   */
  logoSrc: PropTypes.string,

  /**
   * Path the logo image for mobile devices
   */
  logoSrcMobile: PropTypes.string,
};

Header.defaultProps = {
  children: '',
  logoAlt: constants.DEFAULT_LOGO_ALT,
  logoSrc: constants.DEFAULT_LOGO,
  logoSrcMobile: constants.DEFAULT_LOGO_MOBILE,
};

export default Header;
