import {isObject} from './boolean';
import {API_EMAIL} from '../constants/api';

/**
 *
 * @param {object} values
 * @returns {object}
 */
export const prepareProfileSubmitPayload = values => {
  if (!isObject(values)) {
    return {};
  }
  const exclude = [API_EMAIL];
  return Object.keys(values).reduce((vals, item) => {
    if (item && !exclude.includes(item)) {
      return {
        ...vals,
        [item]: values[item],
      };
    }
    return vals;
  }, {});
};
