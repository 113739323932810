/* eslint-disable no-param-reassign */
/* eslint-disable fp/no-mutation */
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import Service from '../../api/Service';
import ValidationError from '../../Errors/ValidationError';
import {
  ENDPOINT_TENANT_AGREEMENTS,
  API_TENANCY_PROPERTY,
} from '../../utils/constants';
import {implode} from '../../utils/helpers';

export const fetchAgreementsAsync = createAsyncThunk(
  'tenant/fetchAgreementsAsync',
  async (_, thunkAPI) => {
    try {
      const includes = [API_TENANCY_PROPERTY];
      const response = await Service.get(
        `${ENDPOINT_TENANT_AGREEMENTS}?include=${implode(
          ',',
          includes
        )}&sort=modified desc`
      );
      return response.data;
    } catch (error) {
      if (error instanceof ValidationError) {
        return thunkAPI.rejectWithValue({
          error,
        });
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateAgreementsAsync = createAsyncThunk(
  'tenant/updateAgreementsAsync',
  async (data, thunkAPI) => {
    try {
      const response = await Service.post(ENDPOINT_TENANT_AGREEMENTS, data);
      return response.data;
    } catch (error) {
      if (error instanceof ValidationError) {
        return thunkAPI.rejectWithValue({
          error,
        });
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteAgreementsVehicleAsync = async id => {
  try {
    const response = await Service.delete(
      `${ENDPOINT_TENANT_AGREEMENTS}/vehicles/${id}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const agreementSlice = createSlice({
  name: 'agreement',
  initialState: {
    fetching: false,
    error: false,
    errorMsg: '',
    agreements: [],
  },
  reducers: {
    setFetching: (state, {payload}) => {
      state.fetching = payload;
    },
  },
  extraReducers: {
    [fetchAgreementsAsync.fulfilled]: (state, {payload}) => {
      state.fetching = false;
      state.error = false;
      state.errorMsg = '';
      state.agreements = [...state.agreements, ...payload];
    },
    [fetchAgreementsAsync.pending]: (state, {payload}) => {
      state.fetching = true;
      state.error = false;
      state.errorMsg = '';
    },
    [fetchAgreementsAsync.rejected]: (state, {payload}) => {
      state.fetching = false;
      state.error = true;
      state.errorMsg = '';
    },
  },
});

export default agreementSlice.reducer;

export const {setAgreements} = agreementSlice.actions;

export const agreementState = state => state.agreement;
