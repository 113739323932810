import React from 'react';
import PropTypes from 'prop-types';
import {Box} from '@tenancy.nz/design-system';

function Nav({items, vertical, renderItem, ...rest}) {
  return (
    <Box
      as="nav"
      color="#fff"
      display="flex"
      direction={vertical ? 'column' : 'row'}
      {...rest}>
      {items.map(renderItem)}
    </Box>
  );
}

Nav.propTypes = {
  /**
   */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ),

  /**
   */
  vertical: PropTypes.bool,

  /**
   */
  renderItem: PropTypes.func.isRequired,
};

Nav.defaultProps = {
  items: [],
  vertical: false,
};

export default Nav;
