import StackTraceError from './StackTraceError';

export default class UnauthorizedError extends StackTraceError {
  constructor(code, message = 'You are not authorised to view this resource') {
    super(message);
    this.code = code;
  }

  getCode = () => this.code;
}
