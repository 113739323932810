/* eslint-disable no-param-reassign */
/* eslint-disable fp/no-mutation */
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import Service from '../../api/Service';
import ValidationError from '../../Errors/ValidationError';
import UnauthorizedError from '../../Errors/UnauthorizedError';
import {
  KEY_AUTHENTICATED,
  KEY_AUTHENTICATION_TOKEN,
} from '../../utils/constants';
import {setCookie, deleteCookie, getCookie} from '../../utils/helpers';

export const checkTokenAsync = createAsyncThunk(
  'users/checkTokenAsync',
  async (_, thunkAPI) => {
    try {
      const response = await Service.get('auth/user');
      return {
        token: true,
        verified: response.verified,
        roles: response.roles,
        permissions: response.permissions,
        email: response.email,
        username: response.username,
        pending: false,
        has_security_question: response.has_security_question,
        known_as: response.profile.known_as,
      };
    } catch (error) {
      if (
        error instanceof ValidationError ||
        error instanceof UnauthorizedError
      ) {
        // 422, 403, 401
        return thunkAPI.rejectWithValue({
          error: {
            message: error.message,
          },
          token: error.getCode() === 403 ? true : null,
          verified: error.getCode() === 403 ? false : undefined,
        });
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const loginAsync = createAsyncThunk(
  'users/loginAsync',
  async ({password, username}, thunkAPI) => {
    try {
      const response = await Service.post('auth/login', {password, username});
      if (response.access_token === undefined) {
        return {
          error: {message: 'Please check your username or email & password'},
        };
      }
      return {
        token: response.access_token,
        error: false,
        pending: false,
        has_security_question: response.has_security_question,
        roles: response.roles,
        permissions: response.permissions,
        verified: response.verified,
        username: response.username || null,
      };
    } catch (error) {
      if (error instanceof ValidationError) {
        return thunkAPI.rejectWithValue({
          error: {message: 'Please check your username or email & password'},
        });
      }
      return thunkAPI.rejectWithValue({
        error: {message: error.message, code: error.getCode && error.getCode()},
      });
    }
  }
);

export const logoutAsync = createAsyncThunk(
  'users/logoutAsync',
  async (_, thunkAPI) => {
    try {
      await Service.get('auth/logout');
      return {
        email: null,
        error: false,
        has_security_question: false,
        known_as: '',
        pending: false,
        permissions: [],
        roles: [],
        token: null,
        username: null,
        verified: undefined,
        registered: false,
      };
    } catch (error) {
      if (error instanceof ValidationError) {
        return thunkAPI.rejectWithValue({
          error: {message: 'Unexpected error occured.'},
        });
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteProfileAsync = createAsyncThunk(
  'users/deleteProfileAsync',
  async (_, thunkAPI) => {
    try {
      await Service.delete('tenant', {data: {confirmed_delete: 1}});
      return true;
    } catch (error) {
      if (error instanceof ValidationError) {
        return thunkAPI.rejectWithValue({
          error: {message: 'Unexpected error occured.'},
        });
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const sendVerificationLinkEmaiAsync = createAsyncThunk(
  'users/sendVerificationLinkEmaiAsync',
  async (_, thunkAPI) => {
    try {
      await Service.get('tenant/verification-notification');
      return {};
    } catch (error) {
      if (error instanceof ValidationError) {
        return thunkAPI.rejectWithValue({
          error: {message: error.message || 'Unexpected error occured.'},
        });
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const verifyAsync = createAsyncThunk(
  'users/verifyAsync',
  async ({id, hash, params}, thunkAPI) => {
    try {
      await Service.get(`tenant/verify/${id}/${hash}?${params}`);
      return {};
    } catch (error) {
      if (error instanceof ValidationError) {
        return thunkAPI.rejectWithValue({
          error: {message: error.message || 'Unexpected error occured.'},
        });
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    email: null,
    error: false,
    error_code: -1,
    has_security_question: false,
    known_as: '',
    pending: true,
    permissions: [],
    roles: [],
    token: null,
    username: null,
    verified: undefined,
    registered: false,
  },
  reducers: {
    setError: (state, {payload}) => {
      state.error = payload;
    },
    setRegistered: (state, {payload}) => {
      state.registered = payload;
    },
  },
  extraReducers: {
    [checkTokenAsync.fulfilled]: (state, {payload}) => {
      state.pending = false;
      state.error = false;
      state.token = getCookie(KEY_AUTHENTICATION_TOKEN);
      state.known_as = payload.known_as;
      state.roles = payload.roles;
      state.permissions = payload.permissions;
      state.email = payload.email;
      state.username = payload.username;
      state.verified = payload.verified;
      state.has_security_question = payload.has_security_question;
    },
    [checkTokenAsync.pending]: (state, {payload}) => {
      state.pending = true;
      state.error = false;
    },
    [checkTokenAsync.rejected]: (state, {payload}) => {
      state.pending = false;
      state.error = false;
      state.verified = payload.verified;
      state.token = payload.token;
      if (payload.verified !== false) {
        deleteCookie(KEY_AUTHENTICATION_TOKEN);
        deleteCookie(KEY_AUTHENTICATED);
      }
    },
    [loginAsync.fulfilled]: (state, {payload}) => {
      state.pending = payload.pending;
      state.error = payload.error;
      state.token = payload.token;
      state.roles = payload.roles;
      state.permissions = payload.permissions;
      state.has_security_question = payload.has_security_question;
      state.verified = payload.verified;
      state.username = payload.username;
      setCookie(KEY_AUTHENTICATION_TOKEN, payload.token);
      setCookie(KEY_AUTHENTICATED, 'true');
    },
    [loginAsync.pending]: (state, {payload}) => {
      state.pending = true;
      state.error = false;
    },
    [loginAsync.rejected]: (state, {payload}) => {
      state.pending = false;
      state.error = payload.error;
      state.verified = false;
      deleteCookie(KEY_AUTHENTICATION_TOKEN);
      deleteCookie(KEY_AUTHENTICATED);
    },
    [deleteProfileAsync.pending]: (state, {payload}) => {
      state.pending = true;
      state.error = false;
    },
    [deleteProfileAsync.rejected]: (state, {payload}) => {
      state.pending = false;
      state.error = payload.error;
    },
    [deleteProfileAsync.fulfilled]: (state, {payload}) => {
      state = {pending: false, error: false, ...payload};
      deleteCookie(KEY_AUTHENTICATION_TOKEN);
      deleteCookie(KEY_AUTHENTICATED);
      return state;
    },
    [logoutAsync.fulfilled]: (state, {payload}) => {
      state = {pending: false, error: false, ...payload};
      deleteCookie(KEY_AUTHENTICATION_TOKEN);
      deleteCookie(KEY_AUTHENTICATED);
      return state;
    },
    [logoutAsync.rejected]: (state, {payload}) => {
      state.pending = false;
      state.error = true;
      deleteCookie(KEY_AUTHENTICATION_TOKEN);
      deleteCookie(KEY_AUTHENTICATED);
    },
  },
});

// export const {setPending} = userSlice.actions;

export const userState = state => state.user;

export const {setError, setRegistered} = userSlice.actions;

export default userSlice.reducer;
