import styled, {css} from 'styled-components';
import {motion} from 'framer-motion';

const StyledMobileNav = styled(motion.nav)`
  ${({open}) => css`
    display: flex;
    flex-direction: column;
    background-color: #002984;
    position: fixed;
    top: 0;
    left: 0;
    width: 70%;
    padding: 20px;
    z-index: 1299;
    height: 100vh;
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.5);
    fontsize: 18px;
    transition: all 0.3s ${!open ? '0.5s' : ''} ease-in-out;
    transform: translateX(${open ? `0%` : `-105%`}) translateZ(0px);
    column-gap: 10px;
    row-gap: 10px;
  `}
`;

export const StyledMobileNavItem = styled(motion.span)`
  display: flex;
  padding: 10px 0;
  font-size: 18px;
`;

export default StyledMobileNav;
