/**
 * @type {string}
 */
export const API_TENANCY_AGENCY = 'agency';

/**
 * @type {string}
 */
export const API_TENANCY_AGENCY_LOGO_FILE_ID = 'logo_file_id';

/**
 * @type {string}
 */
export const API_TENANCY_BOND_AMOUNT = 'bond_amount';

/**
 * @type {string}
 */
export const API_TENANCY_ID = 'id';

/**
 * @type {string}
 */
export const API_TENANCY_IS_FINALISED = 'is_finalised';

/**
 * @type {string}
 */
export const API_TENANCY_SIGNED_BY_ID = 'signed_by_id';

/**
 * @type {string}
 */
export const API_TENANCY_STATUS = 'status';

/**
 * @type {string}
 */
export const API_TENANCY_MOVING_HUB = 'moving_hub';

/**
 * @type {string}
 */
export const API_TENANCY_PM_COMPANY = 'pm_company_name';

/**
 * @type {string}
 */
export const API_TENANCY_PM_NAME = 'pm_contact_person';

/**
 * @type {string}
 */
export const API_TENANCY_PM_EMAIL_ADDRESS = 'pm_email';

/**
 * @type {string}
 */
export const API_TENANCY_PM_MOBILE_NUMBER = 'pm_mobile';

/**
 * @type {string}
 */
export const API_TENANCY_PM_PHONE_NUMBER = 'pm_phone';

/**
 * @type {string}
 */
export const API_TENANCY_PROPERTY = 'property';

/**
 * @type {string}
 */
export const API_TENANCY_PROPERTY_ID = 'property_id';

/**
 * @type {string}
 */
export const API_TENANCY_PROPERTY_UNIT_NUMBER = 'unit';

/**
 * @type {string}
 */
export const API_TENANCY_PROPERTY_STREET_NUMBER = 'street_number';

/**
 * @type {string}
 */
export const API_TENANCY_PROPERTY_STREET_NAME = 'street_name';

/**
 * @type {string}
 */
export const API_TENANCY_PROPERTY_SUBURB = 'suburb';

/**
 * @type {string}
 */
export const API_TENANCY_PROPERTY_CITY = 'city';

/**
 * @type {string}
 */
export const API_TENANCY_PROPERTY_POSTCODE = 'postcode';

/**
 * @type {string}
 */
export const API_TENANCY_PROPERTY_IMAGES = 'images';

/**
 * @type {string}
 */
export const API_TENANCY_PROPERTY_IMAGE_URL = 'url';

/**
 * @type {string}
 */
export const API_TENANCY_PROPERTY_IMAGE_DETAILS = 'description';

/**
 * @type {string}
 */
export const API_TENANCY_START_DATE = 'tenancy_start_date';

/**
 * @type {string}
 */
export const API_TENANCY_TERM = 'tenancy_term';

/**
 * @type {string}
 */
export const API_TENANCY_TENANTS = 'tenants';

/**
 * @type {string}
 */
export const API_TENANCY_TENANT_ID = 'id';

/**
 * @type {string}
 */
export const API_TENANCY_TENANT_FIRST_NAME = 'first_name';

/**
 * @type {string}
 */
export const API_TENANCY_TENANT_LAST_NAME = 'last_name';

/**
 * @type {string}
 */
export const API_TENANCY_TENANT_MOBILE = 'mobile';

/**
 * @type {string}
 */
export const API_TENANCY_TENANT_EMAIL = 'email';

/**
 * @type {string}
 */
export const API_TENANCY_TENANT_RENEWAL_STATUS = 'renewal_status';

/**
 * @type {string}
 */
export const API_TENANCY_TENANT_SIG_FILE_ID = 'signature_file_id';

/**
 * @type {string}
 */
export const API_TENANCY_TENANT_SIG_DATE = 'signed_on_datetime';

/**
 * @type {string}
 */
export const API_TENANCY_TENANT_BOND_AGREEMENT_REFUND_AMT =
  'total_refund_amount';

/**
 * @type {string}
 */
export const API_TENANCY_TENANT_SIGN_TERMS = 'tc_for_signing_agreement';

/**
 * @type {string}
 */
export const API_TENANCY_TENANT_SIGN_TERMS_TYPE = 'tc_type';

/**
 * @type {string}
 */
export const API_TENANCY_TENANT_SIGN_BOND_LODGE = 'fill_bond_lodgement';

/**
 * @type {string}
 */
export const API_TENANCY_TENANT_SIGN_BOND_REFUND = 'fill_bond_refund';

/**
 * @type {string}
 */
export const API_TENANCY_TENANT_SIGN_CHANGE_TENANT = 'fill_change_of_tenant';

/**
 * @type {string}
 */
export const API_TENANCY_TENANT_BOND_CONTRIBUTION = 'bond_contribution_amount';

/**
 * @type {string}
 */
export const API_TENANCY_TENANT_BOND_REFUND_AMOUNT = 'pay_the_tenant_amount';

/**
 * @type {string}
 */
export const API_TENANCY_TENANT_BOND_DISPUTE_AMOUNT = 'hold_in_dispute_amount';

/**
 * @type {string}
 */
export const API_TENANCY_TENANT_TYPE = 'agreement_type';

/**
 * @type {string}
 */
export const API_TENANCY_TENANT_SUB_TYPE = 'agreement_sub_type';

/**
 * @type {string}
 */
export const API_TENANCY_VEHICLES = 'vehicles';

/**
 * @type {string}
 */
export const API_TENANCY_BOND = 'bond';

/**
 * @type {string}
 */
export const API_NOT_SEND_ANY_BOND_FORMS = 'not_send_any_bond_forms';

/**
 * @type {string}
 */
export const API_BOND_TENANT = 'bondTenant';

/**
 * @type {string}
 */
export const API_BOND_AGREEMENT = 'bondAgreement';

/**
 * @type {string}
 */
export const VARIATION = 'variation';

/**
 * @type {string}
 */
export const JOINING = 'joining';

/**
 * @type {string}
 */
export const REMAINING = 'remaining';
