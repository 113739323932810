import {numberOrDefault} from '../number';

export default class CreditCheck {
  data = {
    id: null,
    age: null,
    dob: null,
    name: null,
    type: null,
    firstName: null,
    middleName: null,
    lastName: null,
    address: null,
    creditAmount: null,
    creditScore: null,
    previousEnquiries: [],
    quotationEnquiries: [],
    previousAddresses: [],
    knownNames: [],
    adverseData: null,
    insolvencies: [],
    companyAffiliations: [],
    properties: [],
    enquiryDate: null,
    enquiryNumber: null,
    judgments: [],
    courtFines: [],
    defaults: [],
    publicNotices: [],
    directorships: [],
    summary: {
      ageOfFile: null,
      totalNames: null,
      knownNames: null,
      totalCoAffil: null,
      totalDefaults: null,
      totalPrevEnq: null,
      totalAddresses: null,
      totalInsolvencies: null,
      totalJudgments: null,
      totalBankruptcies: null,
    },
  };

  setId(id) {
    this.data.id = id;
    return this;
  }

  setAge(age) {
    this.data.age = numberOrDefault(age);
    return this;
  }

  setDob(dob) {
    this.data.dob = dob;
    return this;
  }

  setType(type) {
    this.data.type = type;
    return this;
  }

  setName(name) {
    this.data.name = name;
    return this;
  }

  setFirstName(name) {
    this.data.firstName = name;
    return this;
  }

  setMiddleName(name) {
    this.data.middleName = name;
    return this;
  }

  setLastName(name) {
    this.data.lastName = name;
    return this;
  }

  setPreviousAddresses(address) {
    this.data.previousAddresses = address;
    return this;
  }

  setPreviousEnquiries(enquiries) {
    this.data.previousEnquiries = enquiries;
    return this;
  }

  setQuotationEnquiries(enquiries) {
    this.data.quotationEnquiries = enquiries;
    return this;
  }

  setDirectorships(directorships) {
    this.data.directorships = directorships;
    return this;
  }

  setAddress(address) {
    this.data.address = address;
    return this;
  }

  setCreditScore(creditScore) {
    this.data.creditScore = creditScore;
    return this;
  }

  setProperties(properties) {
    this.data.properties = properties;
    return this;
  }

  setAdverseData(adverseData) {
    this.data.adverseData = adverseData;
    return this;
  }

  setInsolvencies(insolvencies) {
    this.data.insolvencies = insolvencies;
    return this;
  }

  setAgeOfFile(ageOfFile) {
    this.data.summary.ageOfFile = ageOfFile;
    return this;
  }

  setKnownNames(knownNames) {
    this.data.knownNames = knownNames;
    return this;
  }

  setTotalNames(totalNames) {
    this.data.summary.totalNames = numberOrDefault(totalNames);
    return this;
  }

  setPublicNotices(publicNotices) {
    this.data.publicNotices = publicNotices;
    return this;
  }

  setTotalDefaults(totalDefaults) {
    this.data.summary.totalDefaults = numberOrDefault(totalDefaults);
    return this;
  }

  setTotalCoAffil(totalCoAffil) {
    this.data.summary.totalCoAffil = numberOrDefault(totalCoAffil);
    return this;
  }

  setTotalPrevEnq(totalPrevEnq) {
    this.data.summary.totalPrevEnq = numberOrDefault(totalPrevEnq);
    return this;
  }

  setTotalAddresses(totalAddresses) {
    this.data.summary.totalAddresses = numberOrDefault(totalAddresses);
    return this;
  }

  setTotalInsolvencies(totalInsolvencies) {
    this.data.summary.totalInsolvencies = numberOrDefault(totalInsolvencies);
    return this;
  }

  setTotalJudgments(totalJudgments) {
    this.data.summary.totalJudgments = numberOrDefault(totalJudgments);
    return this;
  }

  setTotalBankruptcies(totalBankruptcies) {
    this.data.summary.totalBankruptcies = numberOrDefault(totalBankruptcies);
    return this;
  }

  setCourtFines(courtFines) {
    this.data.courtFines = courtFines;
    return this;
  }

  setCompanyAffiliations(companyAffiliations) {
    this.data.companyAffiliations = companyAffiliations;
    return this;
  }

  setDefaults(defaults) {
    this.data.defaults = defaults;
    return this;
  }

  setEnquiryDate(enquiryDate) {
    this.data.enquiryDate = enquiryDate;
    return this;
  }

  setEnquiryNumber(enquiryNumber) {
    this.data.enquiryNumber = enquiryNumber;
    return this;
  }

  setJudgments(judgments) {
    this.data.judgments = judgments;
    return this;
  }

  setCreditAmount(creditAmount) {
    this.data.creditAmount = creditAmount;
    return this;
  }

  toObject() {
    return this.data;
  }
}
