/**
 * @type {string}
 */
export const API_APPLICANT_ADDRESSES = 'addresses';

/**
 * @type {string}
 */
export const API_APPLICANT_APPLICATION = 'application';

/**
 * @type {string}
 */
export const API_APPLICANT_APPLICATION_ADDRESS = 'application_address';

/**
 * @type {string}
 */
export const API_APPLICANT_AGENCY = 'agency';

/**
 * @type {string}
 */
export const API_APPLICANT_CO_APPLICANTS = 'co_applicants';

/**
 * @type {string}
 */
export const API_APPLICANT_DOCUMENTS = 'documents';

/**
 * @type {string}
 */
export const API_APPLICANT_GUARANTOR = 'guarantor';

/**
 * @type {string}
 */
export const API_APPLICANT_REFERENCS = 'references';

/**
 * @type {string}
 */
export const API_APPLICANT_RESIDENTS = 'residents';

/**
 * @type {string}
 */
export const API_APPLICATION_RESIDENTS = 'application_residents';

/**
 * @type {string}
 */
export const API_APPLICANT_IDENTITY = 'identity';

/**
 * @type {string}
 */
export const API_APPLICANT_PROPERTY_IMAGES = 'application_property_images';

/**
 * @type {string}
 */
export const API_APPLICANT_APPLICATION_ID = 'application_id';

/**
 * @type {string}
 */
export const API_APPLICANT_BIO = 'bio';

/**
 * @type {string}
 */
export const API_APPLICANT_COMPLETED_AT = 'completed_at';

/**
 * @type {string}
 */
export const API_APPLICANT_COMPLETED_PART_1_AT = 'completed_part_1_at';

/**
 * @type {string}
 */
export const API_APPLICANT_EMAIL_SENT_AT = 'email_sent_at';

/**
 * @type {string}
 */
export const API_APPLICANT_DOB = 'dob';

/**
 * @type {string}
 */
export const API_APPLICANT_EMAIL = 'email';

/**
 * @type {string}
 */
export const API_APPLICANT_FIRST_NAME = 'first_name';

/**
 * @type {string}
 */
export const API_APPLICANT_ID = 'id';

/**
 * @type {string}
 */
export const API_APPLICANT_IS_ADULT = 'is_over_18';

/**
 * @type {string}
 */
export const API_APPLICANT_LAST_NAME = 'last_name';

/**
 * @type {string}
 */
export const API_APPLICANT_MIDDLE_NAME = 'middle_names';

/**
 * @type {string}
 */
export const API_APPLICANT_MOBILE = 'mobile';

/**
 * @type {string}
 */
export const API_APPLICANT_PHONE = 'home_phone';

/**
 * @type {string}
 */
export const API_APPLICANT_UTILITIES = 'moving_hub';

/**
 * @type {string}
 */
export const API_APPLICANT_MARKED_PREFERRED_AT = 'marked_preferred_at';

/**
 * @type {string}
 */
export const API_APPLICANT_NOTES = 'notes';

/**
 * @type {string}
 */
export const API_APPLICANT_PETS = 'pets_collection';

/**
 * @type {string}
 */
export const API_APPLICANT_PREFFERED_NAME = 'preferred_name';

/**
 * @type {string}
 */
export const API_APPLICANT_AVATAR = 'profile_photo_url';

/**
 * @type {string}
 */
export const API_APPLICANT_IS_SMOKER = 'smokers';

/**
 * @type {string}
 */
export const API_APPLICANT_TENANT_ID = 'tenant_id';

/**
 * @type {string}
 */
export const API_APPLICANT_UUID = 'uuid';

/**
 * @type {string}
 */
export const API_APPLICANT_WORK_PHONE = 'work_phone';
