/* eslint-disable no-param-reassign */
/* eslint-disable fp/no-mutation */
import axios from 'axios';
import {createSlice} from '@reduxjs/toolkit';
import Service from '../../api/Service';
import {prepareFileBlob} from '../../utils/helpers';

const extMap = {
  'image/jpeg': 'jpg',
  'image/png': 'png',
  'application/pdf': 'pdf',
};

export const fetchFileUrlAsync = async fileId => {
  try {
    const response = await Service.get(`tenant/file/link/${fileId}`);
    return response;
  } catch (error) {
    return error;
  }
};

/**
 * Creates a presigned file.
 *
 * @param {string} key -
 *
 * @return {Promise}
 */
export const createPresignedFile = (key, storage, guest = false) =>
  Service.post(`${guest ? 'guest/files/sign' : 'files/presigned_create'}`, {
    [guest ? 'filename' : 'uuid_file_name']: key,
    ...(storage && {storage}),
  });

/**
 * Creates the file on AWS.
 *
 * @param {data} object - parameters and header retrived from AWS after signature was created
 * @param {object} blob
 *
 * @return {Promise}
 */
export const handleSignature = async (
  {data},
  blob,
  storage = '',
  guest = false
) => {
  const {
    formAttributes: {action, enctype, method},
    formInputs,
  } = data;
  const formData = new FormData();
  Object.keys(formInputs).forEach(formInputKey => {
    formData.append(formInputKey, formInputs[formInputKey]);
  });
  formData.append('file', blob);
  return axios({
    method,
    url: action,
    data: formData,
    headers: {'Content-Type': enctype},
  }).then(() => createPresignedFile(formInputs.key, storage, guest));
};

/**
 * Create AWS signature for uploaded file.
 *
 * @param {string} name - name of the file
 * @param {object} blob
 *
 * @return {Promise}
 */
export const uploadFileAsync = async (
  file,
  name,
  storage = '',
  guest = false
) => {
  try {
    const blob = prepareFileBlob(file);
    const ext = extMap[blob.type];
    return new Promise((resolve, reject) => {
      Service.post(`${guest ? 'guest/files/upload' : 'files/upload'}`, {
        name: !name.includes(`.${ext}`) ? `${name}.${ext}` : name,
        type: blob.type,
        ...(storage && {storage}),
      })
        .then(response =>
          handleSignature(response, blob, storage, guest)
            .then(resolve)
            .catch(reject)
        )
        .catch(reject);
    });
  } catch (error) {
    return error;
  }
};

export const fileSlice = createSlice({
  name: 'file',
  initialState: {
    fetching: false,
    error: false,
    errorMsg: '',
    files: {},
  },
  reducers: {
    setFile: (state, {payload}) => {
      state.files = {
        ...state.files,
        [payload.id]: payload,
      };
      return state;
    },
  },
});

export default fileSlice.reducer;

export const {setFile} = fileSlice.actions;

export const fileState = state => state.file;
