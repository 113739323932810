import styled from 'styled-components';

export const StyledLogo = styled.figure`
  width: ${props => props.width || '150px'};
  margin: 0;
  padding: 0;
`;

export const StyledLogoImg = styled.img`
  max-width: 100%;
`;

export const StyledLogoImgMobile = styled.img`
  max-width: 100%;
  @media screen and (min-width: 768px) {
    display: none;
  }
`;
