import styled, {css} from 'styled-components';

export const StyledHamburger = styled.button`
  ${({active}) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 1.7rem;
    height: 1.7rem;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;

    &:focus {
      outline: none;
    }

    span {
      width: 100%;
      height: 0.25rem;
      background: #fff;
      border-radius: 10px;
      transition: all 0.3s linear;
      position: relative;
      transform-origin: 1px;
    }

    span:first-child {
      transform: ${active ? 'rotate(45deg)' : 'rotate(0)'};
    }

    span:nth-child(2) {
      opacity: ${active ? '0' : '1'};
      transform: ${active ? 'translateX(20px)' : 'translateX(0)'};
    }

    span:nth-child(3) {
      transform: ${active ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  `}
`;
