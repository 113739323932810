export const findFlagStrategy = ({strategies = []} = {}, strategy) =>
  strategies.find(s => s.name === strategy);

export const flagAllowsUserId = (flag = {}, userId) => {
  if (!flag.enabled) return true;
  const userWithIdsStrategy = findFlagStrategy(flag, 'userWithId');

  if (!userWithIdsStrategy) return false;

  const {parameters: {userIds = ''} = {}} = userWithIdsStrategy;
  return !!userIds.split(',').find(id => String(id) === String(userId));
};
