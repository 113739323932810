const browserSupport = {
  notifications: typeof Notification !== 'undefined',
  serviceWorker:
    typeof navigator !== 'undefined' &&
    typeof navigator.serviceWorker !== 'undefined',
  pushNotifications:
    typeof Notification !== 'undefined' && typeof PushManager !== 'undefined',
};
export function browserSupports() {
  return Object.freeze(browserSupport);
}
