import styled, {css} from 'styled-components';

export const StyledWrapper = styled.div`
  ${({align, theme}) => css`
    display: flex;

    @media screen and (max-width: ${theme.breakpoints.width('sm') - 1}px) {
      flex-direction: column;

      > * {
        width: 100%;
        margin: 10px 0;
      }
    }

    @media screen and (min-width: ${theme.breakpoints.width('sm')}px) {
      margin: 5px 0;
      > * {
        margin: 0 5px;
      }
      ${align === 'start' &&
      css`
        justify-content: flex-start;
      `}
      ${align === 'end' &&
      css`
        justify-content: flex-end;
      `}
      ${align === 'edges' &&
      css`
        justify-content: space-between;
        > * {
          margin: 0;
        }
      `}
    }
  `}
`;
