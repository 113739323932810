import {toFloat, toNumber} from './number';

export const toDollars = cents => {
  const prepared = toNumber(cents);
  if (!prepared) {
    return false;
  }
  return prepared / 100;
};

export const toCents = dollars => {
  const prepared = toFloat(dollars);
  if (!prepared) {
    return false;
  }
  return prepared * 100;
};
