import React from 'react';
import styled, {css} from 'styled-components';
import {Avatar, Badge} from '@tenancy.nz/design-system';
import {getObjectProp} from 'utils/helpers';

export const StyledAvatar = styled(
  ({variant, borderColor, children, ...rest}) => (
    <Avatar {...rest}>{children}</Avatar>
  )
)`
  && {
    ${({theme, width, height, borderColor, variant}) => css`
      ${width && `width: ${width};`}
      ${height && `height: ${height};`}
      ${variant === 'outlined' &&
      `border: 3px solid ${getObjectProp(
        theme,
        `palette.${borderColor}`,
        'transparent'
      )};
      `}
    `}
  }
`;

export const StyledBadge = styled(({badgeSize, ...rest}) => (
  <Badge {...rest} />
))`
  & .MuiBadge-badge {
    width: ${props => props.badgeSize};
    height: ${props => props.badgeSize};
    font-size: ${props => props.badgeSize};
    background: #fff;
    border: 2px solid
      ${({theme, borderColor}) =>
        getObjectProp(theme, `palette.${borderColor}`, 'transparent')};
    color: ${({theme, borderColor}) =>
      getObjectProp(theme, `palette.${borderColor}`, 'transparent')};
  }
  & .MuiSvgIcon-root {
    color: ${props => props.borderColor};
    font-size: ${props => `calc(${props.badgeSize} - 5px)`};
  }
`;
