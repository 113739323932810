import styled from 'styled-components';

export const StyledHeader = styled.header`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 80px;
  color: #fff;
  display: flex;
  align-items: center;
  z-index: 1298;
  border-bottom: 1px solid rgba(255, 255, 255, 0.45);
  background: #002984;
  // background: linear-gradient(278deg, #93178a 0%, #302386 19%);
`;
