import dayjs from 'dayjs';
import {getDate} from './date';
import {
  getLastShownMaintanenceModal,
  setLastShownMaintanenceModal,
} from './session';

export const nztaOnMaintainance = () => {
  const dateObj = getDate();
  if (dateObj.day() === 6) {
    const date = dateObj.format('YYYY-MM-DD');
    return dateObj.isBetween(`${date} 17:00:00`, `${date} 23:59:59`);
  }
  return false;
};

export const shouldShowNztaModal = () => {
  const current = dayjs().format('YYYY-MM-DD');
  if (nztaOnMaintainance() && getLastShownMaintanenceModal() !== current) {
    setLastShownMaintanenceModal(current);
    return true;
  }
  return false;
};
