import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {FlagsProvider} from 'react-unleash-flags';
import {ThemeProvider} from '@tenancy.nz/design-system';
import store from './store';
import theme from './theme';
import App from './App';
import GlobalStyle from './components/GlobalStyle';
import './push';

/* eslint-disable */
const render = () =>
  ReactDOM.render(
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Provider store={store}>
        <FlagsProvider>
          <App />
        </FlagsProvider>
      </Provider>
    </ThemeProvider>,
    document.getElementById('root')
  );
/* eslint-enable */

render();

if (module.hot) {
  module.hot.accept('./App', () => {
    /* eslint-disable global-require */
    const NextApp = require('./App').default;
    render(NextApp);
    /* eslint-enable */
  });
}
