import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import {useLocation} from 'react-router-dom';
import {motion} from 'framer-motion';
import {useMediaQuery} from '@tenancy.nz/design-system';
import Footer from '../Footer';
import * as consts from '../../utils/constants';

const StyledContainer = styled(motion.div)`
  ${({variant, theme}) => css`
    color: inherit;
    display: flex;
    flex: 1;
    flex-direction: column;
    position: relative;
    justify-content: ${props => props.justifyContent};

    ${variant === 'light' &&
    css`
      background: #f0f0f0;
      color: ${theme.palette.default.light};
    `}

    ${variant === 'xlight' &&
    css`
      background: #fff;
      color: ${theme.palette.default.light};
    `}

    ${variant === 'gradient' &&
    css`
      background: transparent linear-gradient(178deg, #002984 0%, #ed0d8d 100%)
        0% 0% no-repeat padding-box;
      padding-top: 50px;
      color: ${theme.palette.background.default};
    `}

    @media (min-width: 768px) {
      padding-bottom: 100px;
    }
  `}
`;

function Container({children, loggedIn, ...rest}) {
  const {pathname} = useLocation();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const gradientVariantFor = [
    consts.SLUG_REGISTER,
    consts.SLUG_RESET,
    consts.SLUG_VERIFY_FULL_RESET,
    consts.SLUG_FORGOTTEN,
    consts.SLUG_LOGIN,
    consts.SLUG_HOME,
    consts.SLUG_REQUEST_RESET,
  ];

  const isGradientBg = !loggedIn && gradientVariantFor.includes(pathname);

  const footerColor = () => {
    if (!isMobile && isGradientBg) {
      return 'transparent';
    }
    if (isMobile && isGradientBg) {
      return 'secondary';
    }
    return 'primary';
  };

  const wrapperBgColor = () => {
    if (pathname === consts.URL_PRIVACY_POLICY) {
      return 'xlight';
    }
    if (isGradientBg) {
      return 'gradient';
    }
    return 'light';
  };

  return (
    <>
      <StyledContainer
        {...rest}
        initial="hidden"
        animate="visible"
        variant={wrapperBgColor()}
        variants={{
          hidden: {
            opacity: 0,
          },
          visible: {
            opacity: 1,
            transition: {
              delay: 0.1,
            },
          },
        }}>
        {children}
      </StyledContainer>
      <Footer color={footerColor()} privacyUrl={consts.URL_PRIVACY_POLICY} />
    </>
  );
}

Container.propTypes = {
  /**
   * Text, component or node
   */
  children: PropTypes.node.isRequired,

  /**
   *
   */
  loggedIn: PropTypes.bool,

  /**
   * Alignment of child nodes
   */
  justifyContent: PropTypes.oneOf([
    'center',
    'normal',
    'flex-start',
    'flex-end',
  ]),
};

Container.defaultProps = {
  loggedIn: false,
  justifyContent: 'normal',
};

export default Container;
