import React from 'react';
import PropTypes from 'prop-types';
import {Box, Avatar} from '@tenancy.nz/design-system';
import {StyledAvatar, StyledBadge} from './Styles';

function ActionAvatar({
  src,
  alt,
  width,
  height,
  borderColor,
  style,
  containerProps,
  badgeIcon,
  badgeSize,
  showBadge,
  variant,
  fallbackChild,
  ...rest
}) {
  const avatar = (
    <StyledAvatar
      width={width}
      height={height}
      variant={variant}
      alt={alt}
      borderColor={borderColor}
      src={src}
      {...rest}>
      {!src && fallbackChild}
    </StyledAvatar>
  );

  return (
    <Box position="relative" {...containerProps} width={width} height={height}>
      {showBadge && (
        <StyledBadge
          badgeContent={badgeIcon}
          borderColor={borderColor}
          badgeSize={badgeSize}
          variant="standard">
          {avatar}
        </StyledBadge>
      )}
      {!showBadge && avatar}
    </Box>
  );
}

ActionAvatar.propTypes = {
  ...Avatar.propTypes,
  src: PropTypes.string,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  borderColor: PropTypes.string,
  style: PropTypes.object,
  containerProps: PropTypes.object,
  badgeIcon: PropTypes.node,
  showBadge: PropTypes.bool,
  variant: PropTypes.oneOf(['standard', 'outlined']),
  badgeSize: PropTypes.string,
  alt: PropTypes.string.isRequired,
  fallbackChild: PropTypes.node,
};

ActionAvatar.defaultProps = {
  src: '',
  borderColor: '',
  style: {},
  containerProps: {},
  badgeIcon: undefined,
  showBadge: false,
  variant: 'standard',
  badgeSize: '22px',
  fallbackChild: undefined,
};

export default ActionAvatar;
