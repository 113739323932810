/* eslint-disable fp/no-mutating-methods */
import React from 'react';
import {uid} from 'react-uid';
import {useFlag} from 'react-unleash-flags';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {Box, useMediaQuery} from '@tenancy.nz/design-system';

import Hamburger from 'components/Hamburger';
import {
  hasVerifiedIDState,
  upgradeState,
} from 'features/upgrade_products/upgradeProductsSlice';
import Hidden from 'components/Hidden';
import LinkButton from '../../components/LinkButton';
import HeaderActions from '../../components/HeaderActions';
import Nav from '../../components/Nav';
import NavLink from '../../components/NavLink';
import MobileNav from '../../components/MobileNav';

import {headerNavState, setOpen} from './headerNavSlice';
import {setFetching, setViewings} from '../viewing/viewingSlice';
import {tenantState, setProfileInitialData} from '../tenant/tenantSlice';
import {logoutAsync, userState, deleteProfileAsync} from '../user/userSlice';

import * as consts from '../../utils/constants';
import {isEmpty, getCookie, flagAllowsUserId} from '../../utils/helpers';

import navLinks from '../../json/navLinks.json';

function HeaderNavFeature() {
  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
  const {pathname} = useLocation();
  const {token, verified} = useSelector(userState);
  const {open} = useSelector(headerNavState);
  const {profile} = useSelector(tenantState);
  const {lastFetchedUpgrades, creditCheckUpgrade} = useSelector(upgradeState);
  const hasVerifiedID = useSelector(hasVerifiedIDState);
  const tenantSelfCheckFlag = useFlag(consts.FEATURE_FLAG_TENANT_SELF_CHECK);

  const handleAccountDelete = () =>
    dispatch(deleteProfileAsync()).then(() => {
      setFetching(false);
      setViewings([]);
      history.push('/');
    });

  const handleLogout = () =>
    dispatch(logoutAsync()).then(() => {
      dispatch(setFetching(false));
      dispatch(setViewings([]));
      dispatch(setProfileInitialData());
      history.push(consts.SLUG_LOGIN);
    });

  const handleToggleNav = () => {
    dispatch(setOpen(!open));
  };

  React.useEffect(() => {
    if (isMobile && open) dispatch(setOpen(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderNavItem = (link, i) => (
    <NavLink
      active={link.path === pathname}
      to={
        link.path === '/contact'
          ? {pathname: link.path, state: {prevPath: pathname}}
          : link.path
      }
      color="inherit"
      weight="600"
      key={uid(link, i)}>
      {link.label}
    </NavLink>
  );

  const isLoggedIn = !!getCookie(consts.KEY_AUTHENTICATION_TOKEN) || !!token;

  return (
    <>
      {isMobile && (
        <MobileNav items={navLinks} open={open} renderItem={renderNavItem} />
      )}
      {!isMobile && (
        <Nav mr={6} gap={6} items={navLinks} renderItem={renderNavItem} />
      )}
      <Box
        sx={{minWidth: {sm: '85px'}}}
        display="flex"
        align="center"
        justify="flex-end"
        gap="8px">
        <Hidden show={isLoggedIn}>
          <Hidden show={verified && lastFetchedUpgrades && !creditCheckUpgrade}>
            {flagAllowsUserId(tenantSelfCheckFlag, profile.email) && (
              <LinkButton
                to="/profile/mydata"
                size="small"
                variant="contained"
                color="tertiary">
                {isMobile ? 'Upgrade' : 'Upgrade Now'}
              </LinkButton>
            )}
          </Hidden>
          <HeaderActions
            avatarName={
              !isEmpty(profile[consts.API_FIRST_NAME])
                ? profile[consts.API_FIRST_NAME]
                : ''
            }
            avatarSrc={profile[consts.API_PROFILE_PHOTO_ID]}
            onLogout={handleLogout}
            onDelete={handleAccountDelete}
            verified={verified}
            hasVerifiedID={hasVerifiedID}
          />
        </Hidden>
        {!isLoggedIn && (
          <LinkButton
            to={consts.SLUG_LOGIN}
            variant="outlined"
            light
            size="small">
            Login
          </LinkButton>
        )}
        {isMobile && (
          <Box ml={!isLoggedIn ? 6 : 3} mr={isLoggedIn ? 2 : 0}>
            <Hamburger active={open} onClick={handleToggleNav} />
          </Box>
        )}
      </Box>
    </>
  );
}

export default HeaderNavFeature;
