import StackTraceError from './StackTraceError';

export default class ServerError extends StackTraceError {
  constructor(code = 500, message = 'Server Error') {
    super(message);
    this.code = code;
  }

  getCode = () => this.code;
}
