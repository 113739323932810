/* eslint-disable no-param-reassign */
/* eslint-disable fp/no-mutation */
import axios from 'axios';

export const defaultHandlers = (statusKey, pendingKey) => ({
  [statusKey.pending]: state => {
    state[pendingKey] = true;
    state.error = null;
  },
  [statusKey.rejected]: (state, {payload}) => {
    state[pendingKey] = false;
    state.error = payload.error;
  },
});

export const abortHandler = thunkAPI => {
  const source = axios.CancelToken.source();
  const handler = () => {
    source.cancel();
  };
  thunkAPI.signal.addEventListener('abort', handler);

  const finish = result => {
    thunkAPI.signal.removeEventListener('abort', handler);
    return result;
  };

  return [source, finish];
};
