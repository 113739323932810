import React from 'react';
import PropTypes from 'prop-types';
import {StyledHamburger} from './Hamburger.styled';

function Hamburger({active, onClick}) {
  return (
    <StyledHamburger active={active} onClick={onClick}>
      <span />
      <span />
      <span />
    </StyledHamburger>
  );
}

Hamburger.propTypes = {
  /**
   *
   */
  active: PropTypes.bool,

  /**
   *
   */
  onClick: PropTypes.func.isRequired,
};

Hamburger.defaultProps = {
  active: false,
};

export default Hamburger;
