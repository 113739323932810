import {prepareAgencyProps} from './agency';
import {prepareCoApplicantProps, prepareApplicantProps} from './applicant';
import {
  propertyFullAddress,
  propertyMainImage,
  preparePropertyProps,
} from './property';
import {getObjectProp} from './object';
import * as consts from '../constants';
import {isArray} from './boolean';
import {toNumber} from './number';

/**
 *
 * @param {string} val
 * @returns {string}
 */
export const prepareApplicationFilterQueryParams = val => {
  if (val === 'all') {
    return 'with_archived=1';
  }
  if (val === 'archived') {
    return 'only_archived=1';
  }
  return '';
};

/**
 *
 * @param {array} reasons
 * @returns {array}
 */
export const prepareApplicationWithdrawReasonOptions = reasons => {
  if (isArray(reasons)) {
    return reasons.map(reason => ({
      label: reason.name,
      value: reason.id,
    }));
  }
  return [];
};

/**
 *
 * @param {array} items
 * @param {number} id
 * @returns {array}
 */
export const removeApplicationFromList = (items, id) => {
  if (isArray(items)) {
    return items.filter(item => toNumber(item.id) !== toNumber(id));
  }
  return items;
};

/**
 * Gets prepared application status string
 *
 * @param {object} property
 *
 * @return {string}
 */
export const applicationStatus = (application, tenantEmail) => {
  const applicant = prepareApplicantProps(
    application[consts.API_APPLICATION_APPLICANT]
  );
  if (
    application[consts.API_APPLICATION_AGREEMENT] &&
    application[consts.API_APPLICATION_AGREEMENT].status === 'sent'
  ) {
    return 'with_agent';
  }
  if (application[consts.API_APPLICATION_STATUS_ID] === 6) {
    return 'with_agent';
  }
  if (
    applicant.part1Completed &&
    !applicant.part2Completed &&
    applicant.markedPreferredAt === true
  ) {
    return 'action_needed';
  }
  if (
    applicant.markedPreferredAt === true ||
    (applicant.part1Completed && applicant.part2Completed)
  ) {
    return 'with_agent';
  }
  return 'action_needed';
};

/**
 * Format and prepares the application agreement props.
 *
 * @param {object} data - agreement data
 *
 * @return {object}
 */
export const prepareApplicationAgreementProps = data => {
  if (!data) {
    return null;
  }
  return {
    id: getObjectProp(data, consts.API_TENANCY_ID),
    isFinalised: !!getObjectProp(data, consts.API_TENANCY_IS_FINALISED),
    signedById: getObjectProp(data, consts.API_TENANCY_SIGNED_BY_ID) || null,
    signedOn: getObjectProp(data, consts.API_TENANCY_TENANT_SIG_DATE) || null,
    status: getObjectProp(data, consts.API_TENANCY_STATUS),
  };
};

/**
 * Format and prepares the application props.
 *
 * @param {object} data
 *
 * @return {object}
 */
export const prepareApplicationProps = (data, tenantEmail) => {
  const applicant = data[consts.API_APPLICATION_APPLICANT] || {};
  const allApplicants = [
    applicant,
    ...(data[consts.API_APPLICATION_CO_APPLICANTS] || []),
  ];
  return {
    id: getObjectProp(data, consts.API_APPLICATION_ID),
    agency: prepareAgencyProps(data[consts.API_APPLICATION_AGENCY]),
    agreement: prepareApplicationAgreementProps(
      data[consts.API_APPLICATION_AGREEMENT]
    ),
    applicant: prepareApplicantProps(applicant),
    coApplicants: prepareCoApplicantProps(allApplicants, tenantEmail),
    property: preparePropertyProps(data[consts.API_APPLICATION_PROPERTY]),
  };
};

/**
 * Format and prepares the props of the list of application.
 *
 * @param {array} data
 *
 * @return {array}
 */
export const prepareApplicationListProps = (data, tenantEmail) => {
  if (isArray(data)) {
    return data.map(item => ({
      applicant: prepareApplicantProps(item[consts.API_APPLICATION_APPLICANT]),
      coApplicants: prepareCoApplicantProps(
        item[consts.API_APPLICATION_CO_APPLICANTS],
        tenantEmail
      ),
      fullAddress: propertyFullAddress(item[consts.API_APPLICATION_PROPERTY]),
      hasAgreement: !!item[consts.API_APPLICATION_AGREEMENT],
      archived: !!item[consts.API_APPLICATION_ARCHIVED_AT],
      id: getObjectProp(item, consts.API_APPLICATION_ID),
      mainImage: propertyMainImage(item[consts.API_APPLICATION_PROPERTY]),
      property: preparePropertyProps(item[consts.API_APPLICATION_PROPERTY]),
      status: applicationStatus(item, tenantEmail),
    }));
  }
  return [];
};
