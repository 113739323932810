import {configureStore} from '@reduxjs/toolkit';
import agreementReducer from '../features/agreement/agreementSlice';
import contactReducer from '../features/contact/contactSlice';
import cartReducer from '../features/cart/cartSlice';
import fileReducer from '../features/file/fileSlice';
import headerNavReducer from '../features/header_nav/headerNavSlice';
import messageReducer from '../features/message/messageSlice';
import tenantReducer from '../features/tenant/tenantSlice';
import userUpgradeReducer from '../features/upgrade_products/upgradeProductsSlice';
import userReducer from '../features/user/userSlice';
import viewingReducer from '../features/viewing/viewingSlice';
import withdrawReasonReducer from '../features/withdraw_reason/withdrawReasonSlice';
import requestDataReducer from '../features/request_data/requestDataSlice';
import loginSubscriber from './loginSubscriber';

const store = configureStore({
  reducer: {
    agreement: agreementReducer,
    contact: contactReducer,
    file: fileReducer,
    headerNav: headerNavReducer,
    message: messageReducer,
    user: userReducer,
    tenant: tenantReducer,
    upgrade: userUpgradeReducer,
    cart: cartReducer,
    viewing: viewingReducer,
    withdrawReason: withdrawReasonReducer,
    requestData: requestDataReducer,
  },
});

// Subscribe to login / logout
loginSubscriber(store);

export default store;
