export const getHostNameFromURL = url => {
  try {
    const parsedURL = new URL(url);
    return parsedURL.hostname;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error parsing URL:', error);
    return '';
  }
};

export const getQueryParam = name => {
  const p = String(name);
  if (!p) {
    return null;
  }
  const params = new URLSearchParams(window.location.search);
  return params.get(p);
};
