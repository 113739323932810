import axios from 'axios';
import NotFoundError from 'Errors/NotFoundError';
import ValidationError from '../Errors/ValidationError';
import UnauthorizedError from '../Errors/UnauthorizedError';
import {env, getObjectProp, getCookie, deleteCookie} from '../utils/helpers';
import {KEY_AUTHENTICATED, KEY_AUTHENTICATION_TOKEN} from '../utils/constants';

export const baseUrl = `${env(
  'REACT_APP_BOOKME_API_BASE_URL',
  'https://tracker-api.tpsportal.co.nz'
)}/api/`;

const TrackerService = axios.create({
  baseURL: baseUrl,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    Accept: 'application/json',
  },
  withCredentials: true,
});

const handleSuccess = response => response.data;

const handleError = error => {
  const {response} = error;
  const message =
    getObjectProp(response, 'data.message') ||
    getObjectProp(response, 'message');

  if (response) {
    // eslint-disable-next-line default-case
    switch (response.status) {
      case 422:
        return Promise.reject(
          new ValidationError(response.data.errors, message)
        );
      case 404:
        return Promise.reject(new NotFoundError(message));
      case 403:
      case 401:
        return Promise.reject(
          new UnauthorizedError(
            response.status,
            `${message}. You likely have too many TABS open causing an error with the system. Please LOG OUT, close all the other tabs, and try again.`
          )
        );
    }
  }
  return Promise.reject(
    new Error(
      `${
        message ? `${message}.` : ``
      } There appears to be a problem with your profile. Please refresh the page and try again. If the problem persists, contact info@tenant.co.nz to get this resolved.`
    )
  );
};

TrackerService.interceptors.response.use(handleSuccess, handleError);

TrackerService.interceptors.request.use(
  config => {
    const auth = getCookie(KEY_AUTHENTICATED);
    const token = getCookie(KEY_AUTHENTICATION_TOKEN);
    if (token && auth === 'true') {
      // eslint-disable-next-line no-param-reassign, fp/no-mutation
      config.headers.Authorization = `Bearer ${token}`;
    } else if (!token && auth === 'true') {
      deleteCookie(KEY_AUTHENTICATED);
      // eslint-disable-next-line fp/no-mutation
      window.location.href = '/';
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

export default TrackerService;
