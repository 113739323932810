/**
 * @type {string}
 */
export const API_PROPERTY_EMAIL = 'agent_email';

/**
 * @type {string}
 */
export const API_PROPERTY_MOBILE = 'agent_mobile';

/**
 * @type {string}
 */
export const API_PROPERTY_AGENT_NAME = 'agent_name';

/**
 * @type {string}
 */
export const API_PROPERTY_ADDR_CITY = 'city';

/**
 * @type {string}
 */
export const API_PROPERTY_ADDR_ID = 'id';

/**
 * @type {string}
 */
export const API_PROPERTY_IMAGES = 'images';

/**
 * @type {string}
 */
export const API_PROPERTY_ADDR_POSTCODE = 'postcode';

/**
 * @type {string}
 */
export const API_PROPERTY_ADDR_STREET = 'street_name';

/**
 * @type {string}
 */
export const API_PROPERTY_ADDR_STREET_NUMBER = 'street_number';

/**
 * @type {string}
 */
export const API_PROPERTY_ADDR_SUBURB = 'suburb';

/**
 * @type {string}
 */
export const API_PROPERTY_ADDR_UNIT = 'unit';
