import StackTraceError from './StackTraceError';

export default class BadRequestError extends StackTraceError {
  constructor(message = 'Bad Request', code = 400) {
    super(message);
    this.code = code;
  }

  getCode = () => this.code;
}
