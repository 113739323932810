import React from 'react';
import PropTypes from 'prop-types';
import {Button} from '@tenancy.nz/design-system';
import {StyledWrapper} from './Styles';

function FormActions({
  align,
  cancelText,
  disabled,
  onCancel,
  size,
  submitText,
  submitting,
}) {
  return (
    <StyledWrapper align={align}>
      <Button
        color="primaryAlt"
        variant="outlined"
        size={size}
        type="button"
        onClick={onCancel}
        className="form-cancel-button">
        {cancelText}
      </Button>
      <Button
        color="primary"
        size={size}
        type="submit"
        loading
        pending={submitting}
        disabled={disabled}
        className="form-submit-button">
        {submitText}
      </Button>
    </StyledWrapper>
  );
}

FormActions.propTypes = {
  /**
   * Sets the alignment of buttons
   */
  align: PropTypes.oneOf(['edges', 'end', 'start']),

  /**
   * Display text of cancel button
   */
  cancelText: PropTypes.string,

  /**
   * If `true`, will make submit button disabled.
   */
  disabled: PropTypes.bool,

  /**
   * Function to handle cancel action.
   */
  onCancel: PropTypes.func,

  /**
   * Function to handle cancel action.
   */
  size: PropTypes.oneOf(['small', 'medium', 'large']),

  /**
   * Display text of submit button
   */
  submitText: PropTypes.string,

  /**
   * If `true`, will show loading state in submit button.
   */
  submitting: PropTypes.bool,
};

FormActions.defaultProps = {
  align: 'end',
  cancelText: 'Cancel',
  disabled: false,
  onCancel: undefined,
  size: 'large',
  submitText: 'Save',
  submitting: false,
};

export default FormActions;
