import CreditCheck from './CreditCheck';
import * as consts from '../../constants';

class CentrixCreditCheck {
  creditCheck = new CreditCheck();

  constructor(data) {
    this.data = data;
  }

  build() {
    const {data} = this;

    return this.creditCheck
      .setId(data.id)
      .setType(consts.CHECK_TYPES.centrix_credit_check)
      .setFirstName(data.first_name)
      .setLastName(data.last_name)
      .setMiddleName(data.middle_name)
      .setAge(data.age)
      .setDob(data.dob)
      .setName(`${data.first_name} ${data.last_name}`)
      .setAddress(data.last_known_address)
      .setCreditScore(data.score)
      .setPreviousEnquiries(data.previous_enquiries)
      .setInsolvencies(data.insolvencies)
      .setCourtFines(data.court_fines)
      .setCompanyAffiliations(data.company_affiliations)
      .setDefaults(data.defaults)
      .setEnquiryDate(data.enquiry_date)
      .setEnquiryNumber(data.enquiry_number)
      .setJudgments(data.judgments)
      .setKnownNames(data.known_names)
      .setAdverseData(data.adverse_data)
      .setPreviousAddresses(data.previous_addresses)
      .setProperties(data.properties)
      .setAgeOfFile(data.summary_age_of_file)
      .setTotalAddresses(data.summary_total_addresses)
      .setTotalDefaults(data.summary_total_defaults)
      .setTotalInsolvencies(data.summary_total_insolvencies)
      .setTotalJudgments(data.summary_total_judgments)
      .setTotalNames(data.summary_total_names)
      .setTotalPrevEnq(data.summary_total_prev_enq)
      .setTotalCoAffil(data.summary_total_co_affil)
      .setTotalBankruptcies(null);
  }

  getCheck() {
    return this.creditCheck;
  }

  get check() {
    return this.getCheck();
  }
}

export default CentrixCreditCheck;
