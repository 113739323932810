import {createGlobalStyle} from 'styled-components';
import fonttenancyeot from '../../assets/fonts/tenancy/tenancy.eot';
import fonttenancywoff from '../../assets/fonts/tenancy/tenancy.woff';
import fonttenancysvg from '../../assets/fonts/tenancy/tenancy.svg';
import fonttenancyttf from '../../assets/fonts/tenancy/tenancy.ttf';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "FontTenancyIcon";
    src:url(${fonttenancyeot});
    src:url(${fonttenancyeot}?#iefix) format('embedded-opentype'),
      url(${fonttenancywoff}) format('woff'),
      url(${fonttenancyttf}) format('truetype'),
      url(${fonttenancysvg}#tenancy) format('svg');
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
  }

  .content-list li {
    margin: 5px 0px;
  }
`;

export default GlobalStyle;
