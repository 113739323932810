/* eslint-disable prefer-const */
/* eslint-disable fp/no-mutation */
import * as consts from 'utils/constants';
import {CHECK_STATUSES, CHECK_TYPES} from 'utils/constants';
import CentrixCreditCheck from './models/CentrixCreditCheck';
import EquifaxCreditCheck from './models/EquifaxCreditCheck';

export const getCheckStatus = check =>
  check && check[consts.TENANT_CHECK_API_STATUS];

export const getCheckType = check =>
  check && check[consts.TENANT_CHECK_API_TYPE];

export const getCreditCheckData = check =>
  check && check[consts.TENANT_CHECK_API_CREDIT_CHECK];

export const findCheck = (upgrade = {}, type) =>
  Array.isArray(upgrade.checks)
    ? upgrade.checks.find(c => c[consts.TENANT_CHECK_API_TYPE] === type)
    : undefined;

export const checkHasStatus = (status, anyStatus = []) =>
  anyStatus.indexOf(status) !== -1;

export const checkHasType = (type, anyType = []) =>
  anyType.indexOf(type) !== -1;

export const isCreditCheckType = type =>
  checkHasType(type, [
    CHECK_TYPES.centrix_credit_check,
    CHECK_TYPES.equifax_credit_check,
  ]);

export const checkStatusMatches = (check, statuses = []) =>
  !!check && checkHasStatus(getCheckStatus(check), statuses);

export const checkTypesMatches = (check, types = []) =>
  !!check && checkHasType(check[consts.TENANT_CHECK_API_TYPE], types);

export const checkIsComplete = check =>
  !!check && checkStatusMatches(check, [CHECK_STATUSES.complete]);

export const checkIsErrorOrFailed = check =>
  !!check &&
  checkStatusMatches(check, [CHECK_STATUSES.error, CHECK_STATUSES.failed]);

export const checkIsCompleteOrError = check =>
  !!check &&
  checkStatusMatches(check, [CHECK_STATUSES.error, CHECK_STATUSES.complete]);

export const checkIsLoading = check =>
  !check ||
  checkStatusMatches(check, [CHECK_STATUSES.pending, CHECK_STATUSES.queued]);

export const isCreditCheck = check =>
  !!check && isCreditCheckType(getCheckType(check));

export const checkCanRefresh = check => !!check && !check.refreshed;

export const compareChecksSame = (check, otherCheck) =>
  !!check && !!otherCheck && check.id === otherCheck.id;

export const checkSummaryDataIsGreaterThan = (check, summaryKey, val) => {
  const summary = check?.summary ?? check?.credit_check?.summary;

  if (!check || !summary) return false;
  return summary[summaryKey] !== null && summary[summaryKey] > val;
};

export const formatIdCheckErrors = idCheck =>
  (idCheck?.errors ?? [])
    .map(error => error?.innerErrors)
    .map(inner =>
      inner?.map(innerError => innerError?.message ?? 'Unknown issue')
    )
    .filter(inner => Array.isArray(inner))
    .reduce((a, b) => [...a, ...b], []);

export const prepareCentrixCreditCheck = (data = {}) =>
  new CentrixCreditCheck(data).build().toObject();

export const prepareEquifaxCreditCheck = ({report: data = {}}) =>
  new EquifaxCreditCheck(data).build().toObject();

export const prepareCentrixFreeCreditCheck = (centrixLimitedCheck = {}) => ({
  ...centrixLimitedCheck,
  summary: {
    totalPrevEnq: centrixLimitedCheck?.summary_total_prev_enq,
    totalNames: centrixLimitedCheck?.summary_total_names,
    totalDefaults: centrixLimitedCheck?.summary_total_defaults,
    totalJudgments: centrixLimitedCheck?.summary_total_judgments,
    totalAddresses: centrixLimitedCheck?.summary_total_addresses,
    totalInsolvencies: centrixLimitedCheck?.summary_total_insolvencies,
  },
});

export const prepareCreditCheck = (data = {}, type) => {
  const isCentrix = type === consts.CHECK_TYPES.centrix_credit_check;
  const isEquifax = type === consts.CHECK_TYPES.equifax_credit_check;
  const isCentrixFreeCheck = type === consts.CHECK_TYPES.centrix_limited_check;

  if (isCentrix) {
    return prepareCentrixCreditCheck(data.centrix);
  }

  if (isEquifax) {
    return prepareEquifaxCreditCheck(data.equifax);
  }

  if (isCentrixFreeCheck) {
    return prepareCentrixFreeCreditCheck(data);
  }

  return null;
};

export const prepareTenantCheck = check => {
  if (!getCreditCheckData(check) || !checkIsComplete(check)) {
    return check;
  }

  return {
    ...check,
    [consts.TENANT_CHECK_API_CREDIT_CHECK]: prepareCreditCheck(
      check[consts.TENANT_CHECK_API_CREDIT_CHECK],
      check[consts.TENANT_CHECK_API_TYPE]
    ),
  };
};
