/**
 * @type {string}
 */
export const API_APPLICATION_AGENCY = 'agency';

/**
 * @type {string}
 */
export const API_APPLICATION_AGREEMENT = 'agreement';

/**
 * @type {string}
 */
export const API_APPLICATION_APPLICANTS = 'applicants';

/**
 * @type {string}
 */
export const API_APPLICATION_APPLICANT = 'applicant';

/**
 * @type {string}
 */
export const API_APPLICATION_CO_APPLICANTS = 'co_applicants';

/**
 * @type {string}
 */
export const API_APPLICATION_ID = 'id';

/**
 * @type {string}
 */
export const API_APPLICATION_PROPERTY = 'property';

/**
 * @type {string}
 */
export const API_APPLICATION_STATUS_ID = 'status_id';

/**
 * @type {string}
 */
export const API_APPLICATION_ARCHIVED_AT = 'archived_at';
