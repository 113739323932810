import StackTraceError from './StackTraceError';

export default class ValidationError extends StackTraceError {
  constructor(errors, message = 'A validation error occurred', code = 422) {
    super(message);
    this.errors = errors;
    this.code = code;
  }

  getErrors = () => {
    const {errors} = this;
    Object.keys(errors).forEach(key => {
      // eslint-disable-next-line fp/no-mutation
      errors[key] = Array.isArray(errors[key]) ? errors[key][0] : errors[key];
    });
    return errors;
  };

  getCode = () => this.code;
}
