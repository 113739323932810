import React, {useState} from 'react';
import {Check} from '@material-ui/icons';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonIcon from '@material-ui/icons/Person';
import SecurityIcon from '@material-ui/icons/Security';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import {
  ActionArea,
  Box,
  ClickAwayListener,
  Divider,
  Popper,
  Text,
} from '@tenancy.nz/design-system';
import ActionAvatar from 'components/ActionAvatar';
import PropTypes from 'prop-types';
import {StyledAction, StyledActionLink, StyledPopperContent} from './Styles';
import {
  SLUG_PROFILE,
  SLUG_SECURITY,
  URL_PRIVACY_POLICY,
} from '../../utils/constants';
import {ucFirstChar, unsetLoggedIn, isEmpty} from '../../utils/helpers';
import ModalDeleteProfile from '../ModalDeleteProfile';

function HeaderActions({
  avatarName,
  avatarSrc,
  onLogout,
  onDelete,
  verified,
  hasVerifiedID,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleClick = e => {
    setAnchorEl(e.currentTarget);
    setOpen(!open);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleLogout = e => {
    e.preventDefault();
    onLogout().then(() => {
      unsetLoggedIn();
    });
  };

  const handleDeleteAccount = values => {
    if (values.confirm === true) {
      setOpenDeleteModal(false);
      return onDelete().then(() => {
        unsetLoggedIn();
      });
    }
    return null;
  };

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleClose = () => {
    setOpenDeleteModal(false);
  };

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <StyledAction>
          <ActionArea onClick={handleClick}>
            <Box as="div">
              <ActionAvatar
                src={isEmpty(avatarSrc) ? '' : avatarSrc}
                alt="Profile Image"
                width="40px"
                height="40px"
                borderColor="tertiary.main"
                variant={hasVerifiedID ? 'outlined' : 'standard'}
                badgeIcon={<Check />}
                badgeSize="20px"
                showBadge={hasVerifiedID}
                fallbackChild={
                  <Box fontSize="21px">
                    <Text variant="inherit">{ucFirstChar(avatarName)}</Text>
                  </Box>
                }
              />
            </Box>
          </ActionArea>
          <Popper anchorEl={anchorEl} open={open}>
            <StyledPopperContent>
              <StyledActionLink to="#" onClick={handleLogout}>
                <ExitToAppIcon /> Logout
              </StyledActionLink>
              {verified === true && (
                <>
                  <Divider disableGutter />
                  <StyledActionLink to={SLUG_PROFILE} onClick={handleClickAway}>
                    <PersonIcon /> Edit Profile
                  </StyledActionLink>
                  <Divider disableGutter />
                  <StyledActionLink
                    to={SLUG_SECURITY}
                    onClick={handleClickAway}>
                    <VerifiedUserIcon /> Security
                  </StyledActionLink>
                </>
              )}
              <Divider disableGutter />
              <StyledActionLink
                as="a"
                href={URL_PRIVACY_POLICY}
                rel="noopener noreferrer"
                target="_blank"
                onClick={handleClickAway}>
                <SecurityIcon /> Privacy & Policy
              </StyledActionLink>
              <>
                <Divider disableGutter />
                <StyledActionLink
                  to="#"
                  onClick={handleOpenDeleteModal}
                  color="secondary">
                  <DeleteForeverIcon /> &nbsp;Delete Profile
                </StyledActionLink>
              </>
            </StyledPopperContent>
          </Popper>
        </StyledAction>
      </ClickAwayListener>
      <ModalDeleteProfile
        isModal
        show={openDeleteModal}
        onCancel={handleClose}
        onSubmit={handleDeleteAccount}
      />
    </>
  );
}

HeaderActions.propTypes = {
  /**
   * Avatar image alt text. First letter is displayed as avatar is src is missing
   */
  avatarName: PropTypes.string.isRequired,

  /**
   * Path to avatar image
   */
  avatarSrc: PropTypes.string,

  /**
   * Function to call when logout action is requested
   */
  onLogout: PropTypes.func.isRequired,

  /**
   * Function to call when delete action is requested
   */
  onDelete: PropTypes.func.isRequired,

  /**
   * If `true`, logged in user is verified.
   */
  verified: PropTypes.bool,
  /**
   * If `true`, the  user has a verified ID.
   */
  hasVerifiedID: PropTypes.bool,
};

HeaderActions.defaultProps = {
  avatarSrc: '',
  verified: undefined,
  hasVerifiedID: false,
};

export default HeaderActions;
