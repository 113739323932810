/**
 * @type {string}
 */
export const API_BIO = 'bio';

/**
 * @type {string}
 */
export const API_DOB = 'dob';

/**
 * @type {string}
 */
export const API_IS_OVER_18 = 'is_over_18';

/**
 * @type {string}
 */
export const API_EMAIL = 'email';

/**
 * @type {string}
 */
export const API_FIRST_NAME = 'first_name';

/**
 * @type {string}
 */
export const API_HOME_PHONE = 'home_phone';

/**
 * @type {string}
 */
export const API_LAST_NAME = 'last_name';

/**
 * @type {string}
 */
export const API_MIDDLE_NAMES = 'middle_names';

/**
 * @type {string}
 */
export const API_MOBILE = 'mobile';

/**
 * @type {string}
 */
export const API_MOBILE_TIME = 'mobile_time';

/**
 * @type {string}
 */
export const API_PREFERRED_NAME = 'preferred_name';

/**
 * @type {string}
 */
export const API_PROFILE_PHOTO_ID = 'profile_photo_id';

/**
 * @type {string}
 */
export const API_SMOKERS = 'smokers';

/**
 * @type {string}
 */
export const API_WORK_PHONE = 'work_phone';

/**
 * @type {string}
 */
export const API_UPLOAD_DIR = 'id_uploads';

/**
 * @type {string}
 */
export const API_DRIVER_LICENCE_NUMBER = 'dl_number';

/**
 * @type {string}
 */
export const API_DRIVER_LICENCE_VERSION = 'dl_version';

/**
 * @type {string}
 */
export const API_DRIVER_LICENCE_FILE_ID = 'dl_file_id';

/**
 * @type {string}
 */
export const API_DRIVER_LICENCE_BACK_FILE_ID = 'dl_back_file_id';

/**
 * @type {string}
 */
export const API_PASSPORT_NUMBER = 'pp_number';

/**
 * @type {string}
 */
export const API_PASSPORT_EXPIRY = 'pp_expiry';

/**
 * @type {string}
 */
export const API_PASSPORT_FILE_ID = 'pp_file_id';

/**
 * @type {string}
 */
export const API_18_PLUS_NUMBER = 'eighteenplus_number';

/**
 * @type {string}
 */
export const API_18_PLUS_EXPIRY = 'eighteenplus_expiry';

/**
 * @type {string}
 */
export const API_18_PLUS_FILE_ID = 'eighteenplus_file_id';

/**
 * @type {string}
 */
export const API_EXTRA_ID_NUMBER = 'extra_id_number';

/**
 * @type {string}
 */
export const API_EXTRA_ID_EXPIRY = 'extra_id_expiry';

/**
 * @type {string}
 */
export const API_EXTRA_ID_TYPE = 'extra_id_type';

/**
 * @type {string}
 */
export const API_EXTRA_ID_FILE_ID = 'extra_id_file_id';

/**
 * @type {string}
 */
export const API_ID = 'id';

/**
 * @type {string}
 */
export const API_FILE_NAME = 'filename';

/**
 * @type {string}
 */
export const API_FILE_ID = 'file_id';

/**
 * @type {string}
 */
export const API_TYPE = 'type';

/**
 * @type {string}
 */
export const API_URL = 'url';

/**
 * @type {string}
 */
export const API_IDENTITY = 'identity';

/**
 * @type {string}
 */
export const API_DOCUMENTS = 'documents';

/**
 * @type {string}
 */
export const API_PROFILE = 'profile';

/**
 * @type {string}
 */
export const API_GUARANTOR = 'guarantor';

/**
 * @type {string}
 */
export const API_TENANT = 'tenant';

/**
 * @type {string}
 */
export const API_RESIDENTS = 'residents';

/**
 * @type {string}
 */
export const API_REFERENCES = 'references';

/**
 * @type {string}
 */
export const API_VEHICLES = 'vehicles';

/**
 * @type {string}
 */
export const API_ADDRESSES = 'addresses';

/**
 * @type {string}
 */
export const API_CURRENT_ADDRESS = 'current_address';

/**
 * @type {string}
 */
export const API_LANDLORD_REFERENCES = 'landlord_references';

/**
 * @type {string}
 */
export const API_EMPLOYMENT = 'employment';

/**
 * @type {string}
 */
export const API_EMPLOYER = 'employer';

/**
 * @type {string}
 */
export const API_OCCUPATION = 'occupation';

/**
 * @type {string}
 */
export const API_EMPLOYER_CONTACT = 'employer_contact';

/**
 * @type {string}
 */
export const API_EMPLOYER_EMAIL = 'employer_email';

/**
 * @type {string}
 */
export const API_EMPLOYER_TIME_YEARS = 'employer_time_years';

/**
 * @type {string}
 */
export const API_EMPLOYER_TIME_MONTHS = 'employer_time_months';

/**
 * @type {string}
 */
export const API_EMPLOYER_PHONE = 'employer_phone';

/**
 * @type {string}
 */
export const API_INCOME = 'income';

/**
 * @type {string}
 */
export const API_EMPLOYER_STREET_1 = 'employer_street_1';

/**
 * @type {string}
 */
export const API_EMPLOYER_STREET_2 = 'employer_street_2';

/**
 * @type {string}
 */
export const API_EMPLOYER_SUBURB = 'employer_suburb';

/**
 * @type {string}
 */
export const API_EMPLOYER_CITY = 'employer_city';

/**
 * @type {string}
 */
export const API_EMPLOYER_POSTCODE = 'employer_postcode';

/**
 * @type {string}
 */
export const API_WORK_STATUS_EMPLOYMENT = 'work_status_employment';

/**
 * @type {string}
 */
export const API_WORK_STATUS_BENEFIT = 'work_status_benefit';

/**
 * @type {string}
 */
export const API_WORK_STATUS_SELF_EMPLOYED = 'work_status_self_employed';

/**
 * @type {string}
 */
export const API_WINZ_NUMBER = 'winz_number';

/**
 * @type {string}
 */
export const API_WINZ_CASE_MANAGER_EMAIL = 'winz_case_manager_email';

/**
 * @type {string}
 */
export const API_STUDENT = 'student';

/**
 * @type {string}
 */
export const API_COURSE_END_DATE = 'course_end_date';

/**
 * @type {string}
 */
export const API_PROFILE_INSTITUTION_NAME = 'institution_name';

/**
 * @type {string}
 */
export const API_PROFILE_COURSE_NAME = 'course_name';

/**
 * @type {string}
 */
export const API_PROFILE_HAS_GUARANTOR = 'has_guarantor';

/**
 * @type {string}
 */
export const API_NAME = 'name';

/**
 * @type {string}
 */
export const API_PHONE = 'phone';

/**
 * @type {string}
 */
export const API_NOTES = 'notes';

/**
 * @type {string}
 */
export const API_RELATIONSHIP = 'relationship';

/**
 * @type {string}
 */
export const API_NO_OF_BEDROOMS = 'req_num_bedrooms';

/**
 * @type {string}
 */
export const API_NO_OF_BATHROOMS = 'req_num_bathrooms';

/**
 * @type {string}
 */
export const API_NO_OF_ADULTS = 'req_num_adults';

/**
 * @type {string}
 */
export const API_NO_OF_GARAGING = 'req_num_garaging';

/**
 * @type {string}
 */
export const API_NO_OF_CHILDREN = 'req_num_children';

/**
 * @type {string}
 */
export const API_HAS_FENCING = 'req_fencing';

/**
 * @type {string}
 */
export const API_VEHICLE_PARKING = 'vehicle_parking';

/**
 * @type {string}
 */
export const API_PETS = 'pets';

/**
 * @type {string}
 */
export const API_HAS_FIREARMS = 'will_store_firearms';

/**
 * @type {string}
 */
export const API_HAS_FIREARMS_LICENCE = 'has_firearm_license';

/**
 * @type {string}
 */
export const API_HAS_TENANCY_TRIBUNAL = 'has_termination_or_tribunal';

/**
 * @type {string}
 */
export const API_TENANCY_TRIBUNAL_DETAILS = 'termination_or_tribunal_details';

/**
 * @type {string}
 */
export const API_HAS_INCARCERATION = 'has_been_incarcerated';

/**
 * @type {string}
 */
export const API_INCARCERATION_DETAILS = 'incarceration_details';

/**
 * @type {string}
 */
export const API_OWING_LANDLOARD_PM = 'owing_landlord_pm';

/**
 * @type {string}
 */
export const API_OWING_LANDLOARD_PM_AMOUNT = 'owing_landlord_pm_amount';

/**
 * @type {string}
 */
export const API_CONSUMER_FINANCE = 'consumer_finance';

/**
 * @type {string}
 */
export const API_CONSUMER_FINANCE_AMOUNT = 'consumer_finance_amount';

/**
 * @type {string}
 */
export const API_EMERGENCY_CONTACT_NAME = 'emrg_name';

/**
 * @type {string}
 */
export const API_EMERGENCY_CONTACT_PHONE = 'emrg_phone';

/**
 * @type {string}
 */
export const API_EMERGENCY_CONTACT_RELATION = 'emrg_relationship';

/**
 * @type {string}
 */
export const API_EMERGENCY_CONTACT_ADDR_LINE_1 = 'emrg_street_1';

/**
 * @type {string}
 */
export const API_EMERGENCY_CONTACT_ADDR_LINE_2 = 'emrg_street_2';

/**
 * @type {string}
 */
export const API_EMERGENCY_CONTACT_ADDR_SUBURB = 'emrg_suburb';

/**
 * @type {string}
 */
export const API_EMERGENCY_CONTACT_ADDR_CITY = 'emrg_city';

/**
 * @type {string}
 */
export const API_EMERGENCY_CONTACT_ADDR_POSTCODE = 'emrg_postcode';

/**
 * @type {string}
 */
export const API_STREET_NAME_1 = 'street_name_1';

/**
 * @type {string}
 */
export const API_STREET_NAME_2 = 'street_name_2';

/**
 * @type {string}
 */
export const API_SUBURB = 'suburb';

/**
 * @type {string}
 */
export const API_CITY = 'city';

/**
 * @type {string}
 */
export const API_POSTCODE = 'postcode';

/**
 * @type {string}
 */
export const API_IS_NZ = 'is_nz';

/**
 * @type {string}
 */
export const API_MOVE_IN_DATE = 'move_in_date';

/**
 * @type {string}
 */
export const API_MOVE_OUT_DATE = 'move_out_date';

/**
 * @type {string}
 */
export const API_LANDLORD_NAME = 'landlord_name';

/**
 * @type {string}
 */
export const API_LANDLORD_PHONE = 'landlord_phone';

/**
 * @type {string}
 */
export const API_LANDLORD_EMAIL = 'landlord_email';

/**
 * @type {string}
 */
export const API_RENT_AMOUNT = 'rent_amount';

/**
 * @type {string}
 */
export const API_CAN_CONTACT = 'can_contact';

/**
 * @type {string}
 */
export const API_IS_CURRENT = 'is_current';

/**
 * @type {string}
 */
export const API_REASON_FOR_LEAVING = 'reason_for_leaving';

/**
 * @type {string}
 */
export const API_DEPENDENT_AGE = 'age';

/**
 * @type {string}
 */
export const API_DEPENDENT_NAME = 'name';

/**
 * @type {string}
 */
export const API_DEPENDENT_RELATIONSHIP = 'relationship';

/**
 * @type {string}
 */
export const API_VEHICLE_MAKE = 'make';

/**
 * @type {string}
 */
export const API_VEHICLE_TYPE = 'type';

/**
 * @type {string}
 */
export const API_VEHICLE_MODEL = 'model';

/**
 * @type {string}
 */
export const API_VEHICLE_COLOR = 'color';

/**
 * @type {string}
 */
export const API_VEHICLE_REGISTRATION = 'registration';

/**
 * @type {string}
 */
export const API_PET_IS_REGISTERED = 'registered';

/**
 * @type {string}
 */
export const API_PET_NAME = 'name';

/**
 * @type {string}
 */
export const API_PET_TYPE = 'type';

/**
 * @type {string}
 */
export const API_PET_BREED = 'breed';

/**
 * @type {string}
 */
export const API_PET_AGE = 'age';

/**
 * @type {string}
 */
export const API_PET_DETAILS = 'description';

/**
 * @type {string}
 */
export const API_PET_PHOTO_ID = 'photo_id';

/**
 * @type {string}
 */
export const API_PET_SUPPORTING_DOC_IDS = 'supporting_doc_ids';

/**
 * @type {string}
 */
export const API_GUARANTOR_FIRST_NAME = 'first_name';

/**
 * @type {string}
 */
export const API_GUARANTOR_LAST_NAME = 'last_name';

/**
 * @type {string}
 */
export const API_GUARANTOR_EMAIL = 'email';

/**
 * @type {string}
 */
export const API_GUARANTOR_PHONE = 'phone';
