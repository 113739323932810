import Service from 'api/Service';
import NotificationHandler from 'worker/notificationHandler';
import PushSubscriptionHandler from 'worker/pushSubscriptionHandler';
import ServiceWorkerHandler from 'worker/serviceWorkerHandler';

/*
  Call on login, logout and initial (undefined)
*/
export const handleServiceWorkerSubscriptions = async (
  swUrl = '',
  options = {}
) => {
  return; // TODO: Uncomment when push notifications go live.

  // eslint-disable-next-line no-unreachable
  try {
    // Extract options with defaults
    const {
      applicationServerKey,
      userVisibleOnly = true,
      subscriptionEndpoint,
      isLoggedIn = false,
      requestNotificationPermission = true,
      subscribePushNotifications = true,
    } = options;

    // Attempt to unsubscribe if we're subscribed and not logged in..
    if (!isLoggedIn) {
      const subscription =
        await PushSubscriptionHandler.unsubscribePushNotifications(swUrl);

      // Send to backend for deletion
      if (subscription) {
        await Service.delete(subscriptionEndpoint, {
          data: subscription.toJSON(),
        });
      }
      return;
    }

    // Some browsers only allow if triggered by user interaction
    if (requestNotificationPermission) {
      await NotificationHandler.handleRequestNotificationPermission();
    }

    // Register service worker unless one already exists...
    await ServiceWorkerHandler.registerServiceWorker(swUrl);

    // Post subscription to backend
    if (subscribePushNotifications) {
      const subscription =
        await PushSubscriptionHandler.handleRegisterPushNotification(swUrl, {
          applicationServerKey, // Our public VAPID key.
          userVisibleOnly,
        });

      if (subscription) {
        await Service.post(subscriptionEndpoint, subscription.toJSON());
      }
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
};
