export const API_BASE_URL = `${process.env.REACT_APP_API_ENDPOINT}/api/`;

/**
 * @type {string}
 */
export const ENDPOINT_AUTH_RESET = 'auth/reset';

/**
 * @type {string}
 */
export const ENDPOINT_AUTH_RECOVERY_OPTIONS = 'auth/recovery_options';

/**
 * @type {string}
 */
export const ENDPOINT_FILES = 'files';

/**
 * @type {string}
 */
export const ENDPOINT_APPLICATION_TENANT = 'tenancy/application/tenant';

/**
 * @type {string}
 */
export const ENDPOINT_TENANT = 'tenant';

/**
 * @type {string}
 */
export const ENDPOINT_TENANT_REGISTER = 'tenant/register';

/**
 * @type {string}
 */
export const ENDPOINT_TENANT_FORGOTTEN = 'tenant/forgotten';

/**
 * @type {string}
 */
export const ENDPOINT_TENANT_RESET = 'tenant/forgotten';

/**
 * @type {string}
 */
export const ENDPOINT_TENANT_DOCUMENTS = 'tenant/documents';

/**
 * @type {string}
 */
export const ENDPOINT_TENANT_RESIDENTS = 'tenant/residents';

/**
 * @type {string}
 */
export const ENDPOINT_TENANT_REFERENCES = 'tenant/references';

/**
 * @type {string}
 */
export const ENDPOINT_TENANT_ADDRESSES = 'tenant/addresses';

/**
 * @type {string}
 */
export const ENDPOINT_TENANT_VEHICLES = 'tenant/vehicles';

/**
 * @type {string}
 */
export const ENDPOINT_TENANT_PETS = 'tenant/pets';

/**
 * @type {string}
 */
export const ENDPOINT_TENANT_AGREEMENTS = 'tenant/agreements';

/**
 * @type {string}
 */
export const ENDPOINT_TENANT_APPLICATIONS =
  'tenancy/application/tenant/applications';

/**
 * @type {string}
 */
export const ENDPOINT_AUTH_UPDATE_PASSWORD = 'auth/updatepassword';

/**
 * @type {string}
 */
export const ENDPOINT_AUTH_UPDATE_SECURITY_QUESTION =
  'auth/update_security_question';

/**
 * Slug representing home page
 *
 * @type {string}
 */
export const SLUG_HOME = `/`;

/**
 * Slug representing profile page
 *
 * @type {string}
 */
export const SLUG_PROFILE = `/profile`;

/**
 * Slug representing applications page
 *
 * @type {string}
 */
export const SLUG_APPLICATIONS = `/applications`;

/**
 * Slug representing tenancies page
 *
 * @type {string}
 */
export const SLUG_TENANCIES = `/tenancies`;

/**
 * Slug representing tenancies page
 *
 * @type {string}
 */
export const SLUG_VIEWINGS = `/viewings`;

/**
 * Slug representing tenancies page
 *
 * @type {string}
 */
export const SLUG_PROFILE_MY_DATA = `${SLUG_PROFILE}/mydata`;

/**
 * Slug representing resources page
 *
 * @type {string}
 */
export const SLUG_RESOURCES = `/resources`;

/**
 * Slug representing agreements page
 *
 * @type {string}
 */
export const SLUG_AGREEMENTS = `/agreements`;

/**
 * Slug representing login page
 *
 * @type {string}
 */
export const SLUG_LOGIN = `/login`;

/**
 * Slug representing register page
 *
 * @type {string}
 */
export const SLUG_REGISTER = `/register`;

/**
 * Slug representing password reset page
 *
 * @type {string}
 */
export const SLUG_RESET = `/auth/reset`;

/**
 * Slug representing password reset page
 *
 * @type {string}
 */
export const SLUG_VERIFY_FULL_RESET = `/auth/verify-reset`;

/**
 * Slug representing forgotten password page
 *
 * @type {string}
 */
export const SLUG_FORGOTTEN = `/forgotten`;

/**
 * Slug representing forgotten password page
 *
 * @type {string}
 */
export const SLUG_REQUEST_RESET = `/account_reset`;

/**
 * Slug representing video tutorial page
 *
 * @type {string}
 */
export const SLUG_VIDEO_TUTORIAL = `/video-tutorial`;

/**
 * Slug representing security page
 *
 * @type {string}
 */
export const SLUG_SECURITY = `/security`;

/**
 * Slug representing `details` segment in URL
 *
 * @type {string}
 */
export const SLUG_DETAILS = `details`;

/**
 * Slug representing `reference` segment in URL
 *
 * @type {string}
 */
export const SLUG_REFERENCE = `reference`;

/**
 * Slug representing `requirements` segment in URL
 *
 * @type {string}
 */
export const SLUG_REQUIREMENTS = `requirements`;

/**
 * Slug representing `history` segment in URL
 *
 * @type {string}
 */
export const SLUG_HISTORY = `history`;

/**
 * Url to privacy policy page
 *
 * @type {string}
 */
export const URL_PRIVACY_POLICY = `/privacy-policy`;

/**
 *  @type {string}
 */
export const SLUG_VIEWING_BOOKINGS = `/viewings`;

/**
 *  @type {string}
 */
export const SLUG_PROFILE_UPGRADE = `${SLUG_PROFILE}/upgrade`;

/**
 *  @type {string}
 */
export const SLUG_PROFILE_CREDIT_REPORTS = `${SLUG_PROFILE}/credit_reports`;

/**
 * @type {string}
 */
export const SLUG_ABOUT = `/about`;

/**
 * @type {string}
 */
export const SLUG_PRIVACY = `/privacy`;

/**
 * @type {string}
 */
export const SLUG_CONTACT = `/contact`;

/**
 * @type {string}
 */
export const SLUG_REQUEST_INFO = `/information-request`;
