import {createSlice} from '@reduxjs/toolkit';

export const messageSlice = createSlice({
  name: 'message',
  initialState: {
    flash: true,
    text: '',
    show: false,
    type: 'error',
  },
  reducers: {
    setMessageText: (state, {payload}) => ({
      ...state,
      text: payload,
    }),
    setMessageShow: (state, {payload}) => ({
      ...state,
      show: payload,
    }),
    setMessageType: (state, {payload}) => ({
      ...state,
      type: payload,
    }),
    setMessageIsFlash: (state, {payload}) => ({
      ...state,
      flash: payload,
    }),
    showErrorMessage: (state, {payload}) => ({
      flash: true,
      show: true,
      type: 'error',
      text: payload,
    }),
    showSuccessMessage: (state, {payload}) => ({
      flash: true,
      show: true,
      type: 'success',
      text: payload,
    }),
    showAlertErrorMessage: (state, {payload}) => ({
      flash: false,
      show: true,
      type: 'error',
      text: payload,
    }),
    showAlertSuccessMessage: (state, {payload}) => ({
      flash: false,
      show: true,
      type: 'success',
      text: payload,
    }),
  },
});

export const messageState = state => state.message;

export const {
  setMessageText,
  setMessageShow,
  setMessageType,
  setMessageIsFlash,
  showErrorMessage,
  showSuccessMessage,
  showAlertErrorMessage,
  showAlertSuccessMessage,
} = messageSlice.actions;

export default messageSlice.reducer;
