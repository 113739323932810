import {implode} from './array';
import {isArray, isEmpty, isObject, isString} from './boolean';
import {dateToDisplayFormat} from './date';
import {toNumber} from './number';
import {getObjectProp} from './object';
import {propertyMainImage} from './property';
import {naIfEmpty, strToLower} from './string';
import * as consts from '../constants/applicant';

/**
 *
 * @param {array} items
 * @param {number} id
 * @returns {array}
 */
export const updateApplicantDetails = (items, {id, ...values}) => {
  if (isArray(items)) {
    return items.map(item => {
      if (!!id && toNumber(item.id) === toNumber(id)) {
        return {
          ...item,
          ...values,
        };
      }
      return item;
    });
  }
  return items;
};

/**
 *
 * @param {array} items
 * @param {number} id
 * @returns {array}
 */
export const removeApplicantFromList = (items, id) => {
  if (isArray(items)) {
    return items.filter(item => toNumber(item.id) !== toNumber(id));
  }
  return items;
};

/**
 * Show '—' if provided applicant var is empty
 *
 * @param {mixed} val
 *
 * @return {mixed}
 */
export const applicantNaIfEmpty = val => naIfEmpty(val, '—');

/**
 *
 * @return {object}
 */
export const prepareGuarantorProps = guarantor => {
  if (isEmpty(guarantor)) {
    return {};
  }
  return {
    firstName: getObjectProp(guarantor, 'first_name'),
    lastName: getObjectProp(guarantor, 'last_name'),
    email: getObjectProp(guarantor, 'email'),
    phone: getObjectProp(guarantor, 'phone'),
  };
};

/**
 *
 * @return {object}
 */
export const prepareApplicantIdentityProps = identity =>
  // @TODO: add constants
  ({
    dlFileId: getObjectProp(identity, 'dl_file_id'),
    dlUrl: getObjectProp(identity, 'dl_file_url'),
    dlBackUrl: getObjectProp(identity, 'dl_back_file_url'),
    dlNumber: getObjectProp(identity, 'dl_number'),
    dlVersion: getObjectProp(identity, 'dl_version'),
    eighteenplusExpiry: getObjectProp(identity, 'eighteenplus_expiry'),
    eighteenplusFileId: getObjectProp(identity, 'eighteenplus_file_id'),
    eighteenplusUrl: getObjectProp(identity, 'eighteenplus_file_url'),
    eighteenplusNumber: getObjectProp(identity, 'eighteenplus_number'),
    extraIdExpiry: getObjectProp(identity, 'extra_id_expiry'),
    extraIdFileId: getObjectProp(identity, 'extra_id_file_id'),
    extraIdUrl: getObjectProp(identity, 'extra_id_file_url'),
    extraIdNumber: getObjectProp(identity, 'extra_id_number'),
    extraIdType: getObjectProp(identity, 'extra_id_type'),
    ppExpiry: getObjectProp(identity, 'pp_expiry'),
    ppFileId: getObjectProp(identity, 'pp_file_id'),
    ppUrl: getObjectProp(identity, 'pp_file_url'),
    ppNumber: getObjectProp(identity, 'pp_number'),
  });

/**
 * Gets the co applicants of application
 *
 * @param {array} applicants
 * @param {string} email - Applicant email to match
 *
 * @return {array}
 */
export const prepareCoApplicantProps = (applicants, email) => {
  if (isArray(applicants) && email) {
    return applicants.map((item, index) => {
      const isCurrent =
        strToLower(item[consts.API_APPLICANT_EMAIL]) === strToLower(email);
      const part1Completed = !!getObjectProp(
        item,
        consts.API_APPLICANT_COMPLETED_PART_1_AT
      );
      return {
        email: getObjectProp(item, consts.API_APPLICANT_EMAIL),
        firstName: isCurrent
          ? getObjectProp(item, consts.API_APPLICANT_FIRST_NAME)
          : `Co Applicant ${index}`,
        id: getObjectProp(item, consts.API_APPLICANT_ID),
        isCurrent,
        lastName: isCurrent
          ? getObjectProp(item, consts.API_APPLICANT_LAST_NAME)
          : '',
        mobile: '',
        status: part1Completed ? 'Application Submitted' : '',
        emailSentAt: !!getObjectProp(item, consts.API_APPLICANT_EMAIL_SENT_AT),
        markedPreferredAt: !!getObjectProp(
          item,
          consts.API_APPLICANT_MARKED_PREFERRED_AT
        ),
        part1Completed,
        part2Completed: !!getObjectProp(
          item,
          consts.API_APPLICANT_COMPLETED_AT
        ),
      };
    });
  }
  return [];
};

/**
 * Prepares applicant residents props
 *
 * @param {array} residents
 *
 * @return {array}
 */
export const prepareResidentsProps = residents =>
  // @TODO: add constants
  (isArray(residents) &&
    residents.map(resident => ({
      id: getObjectProp(resident, 'id'),
      name: getObjectProp(resident, 'name'),
    }))) ||
  [];

/**
 * Prepares applicant current address props
 *
 * @param {array} addresses
 *
 * @return {object}
 */
export const prepareCurrentAddressProps = addresses => {
  // @TODO: add constants
  const current =
    (isArray(addresses) &&
      addresses.find(address => address.is_current === true)) ||
    {};
  const fullAddress = implode(',', [
    getObjectProp(current, 'street_name_1'),
    getObjectProp(current, 'suburb'),
    getObjectProp(current, 'city'),
    getObjectProp(current, 'postcode'),
  ]);
  return {
    address: fullAddress,
    domestic: !!getObjectProp(current, 'is_nz'),
    id: getObjectProp(current, 'id'),
    landlordEmail: getObjectProp(current, 'landlord_email'),
    landlordName: getObjectProp(current, 'landlord_name'),
    landlordPhone: getObjectProp(current, 'landlord_phone'),
  };
};

/**
 * Prepares applicant landlord references props
 *
 * @param {array} addresses
 *
 * @return {array}
 */
export const prepareLandlordReferencesProps = addresses =>
  // @TODO: add constants
  (isArray(addresses) &&
    addresses
      .filter(address => address.is_current === false)
      .map(address => ({
        email: getObjectProp(address, 'landlord_email'),
        id: getObjectProp(address, 'id'),
        name: getObjectProp(address, 'landlord_name'),
        phone: getObjectProp(address, 'landlord_phone'),
      }))) ||
  [];

/**
 * Prepares applicant references props
 *
 * @param {array} references
 *
 * @return {array}
 */
export const prepareReferencesProps = references =>
  // @TODO: add constants
  (isArray(references) &&
    references.map(reference => ({
      email: getObjectProp(reference, 'email'),
      id: getObjectProp(reference, 'id'),
      name: getObjectProp(reference, 'name'),
      phone: getObjectProp(reference, 'phone'),
      relationship: getObjectProp(reference, 'relationship'),
    }))) ||
  [];

/**
 * Prepares supporting docs props of each pet
 *
 * @param {array} documents
 *
 * @return {array}
 */
export const preparePetsDocsProps = documents =>
  // @TODO: add constants
  (isObject(documents) &&
    Object.keys(documents).map(key => ({
      id: toNumber(key),
      url: getObjectProp(documents, key),
    }))) ||
  [];

/**
 * Prepares applicant pets props
 *
 * @param {array} pets
 *
 * @return {array}
 */
export const preparePetsProps = pets =>
  // @TODO: add constants
  (isArray(pets) &&
    pets.map(pet => ({
      age: getObjectProp(pet, 'age'),
      breed: getObjectProp(pet, 'breed'),
      description: getObjectProp(pet, 'description'),
      id: getObjectProp(pet, 'id'),
      name: getObjectProp(pet, 'name'),
      photoUrl: getObjectProp(pet, 'photo_url'),
      supportingDocs: preparePetsDocsProps(
        getObjectProp(pet, 'supporting_docs')
      ),
      registered: !!getObjectProp(pet, 'registered'),
      type: getObjectProp(pet, 'type'),
    }))) ||
  [];

/**
 * Prepares applicant documents props
 *
 * @param {array} documents
 *
 * @return {array}
 */
export const prepareDocumentsProps = documents =>
  // @TODO: add constants
  (isArray(documents) &&
    documents.map(document => ({
      type: document.type,
      id: document.id,
      url: document.url,
    }))) ||
  [];

/**
 * Prepares utilities props
 *
 * @param {object} applicant
 *
 * @return {array}
 */
export const prepareUtilitiesProps = applicant => {
  const utilities = getObjectProp(applicant, consts.API_APPLICANT_UTILITIES);
  if (utilities && isString(utilities)) {
    const parsed = JSON.parse(utilities);
    return parsed || [];
  }
  return utilities || [];
};

/**
 * Prepares the basic list of props of single applicant
 *
 * @param {array} applicant
 *
 * @return {object}
 */
export const prepareApplicantProps = applicant => {
  const firstName = getObjectProp(applicant, consts.API_APPLICANT_FIRST_NAME);
  const dob = getObjectProp(applicant, consts.API_APPLICANT_DOB);
  return {
    avatar: getObjectProp(applicant, consts.API_APPLICANT_AVATAR),
    applicationId: getObjectProp(
      applicant,
      consts.API_APPLICANT_APPLICATION_ID
    ),
    detailsEncrypted: !!(firstName.length > 100),
    dob,
    dobForDisplay: dateToDisplayFormat(dob) || '',
    email: getObjectProp(applicant, consts.API_APPLICANT_EMAIL),
    firstName,
    id: getObjectProp(applicant, consts.API_APPLICANT_ID),
    lastName: getObjectProp(applicant, consts.API_APPLICANT_LAST_NAME),
    markedPreferredAt: !!getObjectProp(
      applicant,
      consts.API_APPLICANT_MARKED_PREFERRED_AT
    ),
    middleName: getObjectProp(applicant, consts.API_APPLICANT_MIDDLE_NAME),
    mobile: getObjectProp(applicant, consts.API_APPLICANT_MOBILE),
    phone: getObjectProp(applicant, consts.API_APPLICANT_PHONE),
    notes: getObjectProp(applicant, consts.API_APPLICANT_NOTES),
    part1Completed: !!getObjectProp(
      applicant,
      consts.API_APPLICANT_COMPLETED_PART_1_AT
    ),
    part2Completed: !!getObjectProp(
      applicant,
      consts.API_APPLICANT_COMPLETED_AT
    ),
    preferredName: getObjectProp(
      applicant,
      consts.API_APPLICANT_PREFFERED_NAME
    ),
    tenantId: getObjectProp(applicant, consts.API_APPLICANT_TENANT_ID),
    uuid: getObjectProp(applicant, consts.API_APPLICANT_UUID),
    workPhone: getObjectProp(applicant, consts.API_APPLICANT_WORK_PHONE),
  };
};

/**
 * Prepares the full list of props, relational data of single applicant
 *
 * @param {array} applicant
 *
 * @return {object}
 */
export const prepareApplicantAllProps = applicant => {
  const firstName = getObjectProp(applicant, consts.API_APPLICANT_FIRST_NAME);
  const dob = getObjectProp(applicant, consts.API_APPLICANT_DOB);
  const guarantor = prepareGuarantorProps(
    applicant[consts.API_APPLICANT_GUARANTOR]
  );
  const applicantResidents = applicant[consts.API_APPLICANT_RESIDENTS];
  const applicationResidents = applicant[consts.API_APPLICATION_RESIDENTS];
  const residents =
    isArray(applicantResidents) && applicantResidents.length > 0
      ? applicantResidents
      : applicationResidents;

  return {
    avatar: getObjectProp(applicant, consts.API_APPLICANT_AVATAR),
    applicationId: getObjectProp(
      applicant,
      consts.API_APPLICANT_APPLICATION_ID
    ),
    bio: getObjectProp(applicant, consts.API_APPLICANT_BIO),
    currentAddress: prepareCurrentAddressProps(
      applicant[consts.API_APPLICANT_ADDRESSES]
    ),
    dob,
    dobForDisplay: dateToDisplayFormat(dob) || '',
    documents: prepareDocumentsProps(applicant[consts.API_APPLICANT_DOCUMENTS]),
    coApplicants: prepareCoApplicantProps(
      getObjectProp(applicant, consts.API_APPLICANT_CO_APPLICANTS),
      getObjectProp(applicant, consts.API_APPLICANT_EMAIL)
    ),
    email: getObjectProp(applicant, consts.API_APPLICANT_EMAIL),
    detailsEncrypted: !!(firstName.length > 100),
    firstName,
    guarantor,
    hasGuarantor: !isEmpty(guarantor),
    id: getObjectProp(applicant, consts.API_APPLICANT_ID),
    identity: prepareApplicantIdentityProps(
      applicant[consts.API_APPLICANT_IDENTITY]
    ),
    isAdult: !!getObjectProp(applicant, consts.API_APPLICANT_IS_ADULT),
    isSmoker: !!getObjectProp(applicant, consts.API_APPLICANT_IS_SMOKER),
    lastName: getObjectProp(applicant, consts.API_APPLICANT_LAST_NAME),
    landlordReferences: prepareLandlordReferencesProps(
      applicant[consts.API_APPLICANT_ADDRESSES]
    ),
    markedPreferredAt: !!getObjectProp(
      applicant,
      consts.API_APPLICANT_MARKED_PREFERRED_AT
    ),
    middleName: getObjectProp(applicant, consts.API_APPLICANT_MIDDLE_NAME),
    mobile: getObjectProp(applicant, consts.API_APPLICANT_MOBILE),
    phone: getObjectProp(applicant, consts.API_APPLICANT_PHONE),
    notes: getObjectProp(applicant, consts.API_APPLICANT_NOTES),
    part1Completed: !!getObjectProp(
      applicant,
      consts.API_APPLICANT_COMPLETED_PART_1_AT
    ),
    part2Completed: !!getObjectProp(
      applicant,
      consts.API_APPLICANT_COMPLETED_AT
    ),
    pets: preparePetsProps(applicant[consts.API_APPLICANT_PETS]),
    preferredName: getObjectProp(
      applicant,
      consts.API_APPLICANT_PREFFERED_NAME
    ),
    propertyAddress: getObjectProp(
      applicant,
      consts.API_APPLICANT_APPLICATION_ADDRESS
    ),
    propertyImage: propertyMainImage(
      getObjectProp(applicant, consts.API_APPLICANT_PROPERTY_IMAGES)
    ),
    references: prepareReferencesProps(
      applicant[consts.API_APPLICANT_REFERENCS]
    ),
    residents: prepareResidentsProps(residents),
    tenantId: getObjectProp(applicant, consts.API_APPLICANT_TENANT_ID),
    uuid: getObjectProp(applicant, consts.API_APPLICANT_UUID),
    utilities: prepareUtilitiesProps(applicant),
    workPhone: getObjectProp(applicant, consts.API_APPLICANT_WORK_PHONE),
  };
};

/**
 * Plucks a tenant from the list which matches with provided email
 *
 * @param {array} applicants
 * @param {string} email - Applicant email to match
 *
 * @return {object}
 */
export const prepareApplicantPropsFromList = (applicants, email) => {
  const applicant =
    (isArray(applicants) &&
      email &&
      applicants.find(
        item =>
          strToLower(item[consts.API_APPLICANT_EMAIL]) === strToLower(email)
      )) ||
    {};
  return prepareApplicantProps(applicant);
};

/**
 * Prepares applicants documents for form submission.
 *
 * @param {array} documents
 *
 * @return {array}
 */
export const prepareDocumentsPayload = documents =>
  // @TODO: add constants
  (isArray(documents) &&
    documents.map(document => ({
      file_id: document.file_id,
      filename: document.filename,
      filetype: document.filetype,
      type: document.type,
    }))) ||
  [];
