import StackTraceError from './StackTraceError';

export default class NotFoundError extends StackTraceError {
  constructor(message = 'Resource Not Found') {
    super(message);
    this.code = 404;
  }

  getCode = () => this.code;
}
