import logoimg from '../../assets/logo-v2.png';
import logoimgMobile from '../../assets/logo-mobile.png';
import notFoundImg from '../../assets/404-illustration.png';

/**
 * A data uri to create blank image
 *
 * @type {string}
 */
export const BLANK_IMG_SRC =
  'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';

/**
 * Default app logo. Typically displayed in header
 *
 * @type {string}
 */
export const DEFAULT_LOGO = logoimg;

/**
 * Default app logo for mobile devices. Typically displayed in header
 *
 * @type {string}
 */
export const DEFAULT_LOGO_MOBILE = logoimgMobile;

/**
 * Default image to display on 404 page
 *
 * @type {string}
 */
export const DEFAULT_404_IMG = notFoundImg;

/**
 * Default app logo alt text if logo image link is broken
 *
 * @type {string}
 */
export const DEFAULT_LOGO_ALT = 'TENANT';

/**
 * Acceptable image extentions
 *
 * @type {string}
 */
export const LIST_IMAGE_EXTS = ['png', 'jpg', 'jpeg', 'heic'];

/**
 * Acceptable document extentions
 *
 * @type {string}
 */
export const LIST_DOCUMENT_EXTS = ['pdf'];

/**
 * Key which creates local storage entry to remember authentication
 *
 * @type {string}
 */
export const KEY_AUTHENTICATED = 'authenticated';

/**
 * Key which creates local storage entry to remember the last id_modal shown
 *
 * @type {string}
 */
export const ID_VERIFIED_MODAL = 'id_modal';

/**
 * Key which creates local storage entry to remember if the nzta maintenance modal has be shown
 *
 * @type {string}
 */
export const NZTA_MAINTANENCE_MODE_MODAL = 'nzta_modal';

/**
 *
 * @type {string}
 */
export const STATUS_FINALISED = 'finalised';

/**
 *
 * @type {string}
 */
export const STATUS_WAITING_SIGNATURE = 'waiting_signature';

/**
 *
 * @type {string}
 */
export const STATUS_WAITING_AGENT_SIGNATURE = 'waiting_agent_signature';

/**
 *
 * @type {string}
 */
export const STEP_BOND_AMOUNT = 'bond_amount';

/**
 *
 * @type {string}
 */
export const STEP_SIGN_AGREEMENT = 'sign_agreement';

/**
 * @type {number}
 */
export const DEFAULT_MAX_UPLOAD_SIZE = 1.8 * 1024 * 1024;

/**
 * Cookie key which holds the value of auth token
 *
 * @type {string}
 */
export const KEY_AUTHENTICATION_TOKEN = 'api-token';
