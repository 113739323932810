import styled, {css} from 'styled-components';

export const StyledFooter = styled.footer`
  ${({color}) => css`
    color: #bcbcbc;
    color: #fff;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 15px 0;

    @media (min-width: 768px) {
      height: 100px;
      position: fixed;
      left: 0;
      bottom: 0;
      z-index: 999;
    }

    ${color === 'primary' &&
    css`
      background: #0e1f48;
      border-top: 1px solid #707070;
    `}

    ${color === 'secondary' &&
    css`
      background: #ed0d8d;
    `}

    ${color === 'transparent' &&
    css`
      background: transparent;
    `}
  `}
`;

export const StyledSocialIconList = styled.div`
  margin: 10px 0;
  display: flex;
  align-items: center;
  @media (min-width: 992px) {
    justify-content: flex-end;
  }
`;

export const StyledSocialIcon = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  width: 30px;
  height: 30px;
  color: #f3f3f3;
  margin-right: 10px;

  svg {
    font-size: 30px;
  }

  &:hover {
    color: #d5d5d5;
  }
`;
