import React from 'react';
import PropTypes from 'prop-types';
import {FormControl} from '@tenancy.nz/design-system';

function ValidationMessage({message, name, show}) {
  return (
    <>
      {show && message && (
        <div>
          <FormControl errorMessage={message} showError={show} name={name} />
        </div>
      )}
    </>
  );
}

ValidationMessage.propTypes = {
  message: PropTypes.string,
  name: PropTypes.string.isRequired,
  show: PropTypes.bool,
};

ValidationMessage.defaultProps = {
  message: '',
  show: false,
};

export default ValidationMessage;
