import React from 'react';
import PropTypes from 'prop-types';
import {uid} from 'react-uid';
import {Container, Grid, Text} from '@tenancy.nz/design-system';

import Link from '../Link';
import Nav from '../Nav';
import NavLink from '../NavLink';

import {StyledFooter} from './Styles';

import navLinks from '../../json/navLinks.json';

function Footer({color, copyYear, privacyUrl}) {
  return (
    <StyledFooter color={color}>
      <Container fixed>
        <Grid container alignItems="center">
          <Grid item xs={12} md={6}>
            <Nav
              items={navLinks}
              gap={{xs: 2, md: 6}}
              mb={{xs: 5, md: 0}}
              renderItem={(link, i) => (
                <NavLink
                  to={link.path}
                  color="inherit"
                  weight="600"
                  key={uid(link, i)}>
                  {link.label}
                </NavLink>
              )}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{display: 'flex', justifyContent: {md: 'flex-end'}}}>
            <Text color="inherit">
              <small>
                Copyright &copy; {copyYear} Tenant.co.nz
                {privacyUrl && (
                  <Text as="span" variant="inherit">
                    To view our privacy policy and see how we protect your data,{' '}
                    <Link to={privacyUrl} underline="always">
                      click here
                    </Link>
                  </Text>
                )}
              </small>
            </Text>
          </Grid>
        </Grid>
      </Container>
    </StyledFooter>
  );
}

Footer.propTypes = {
  /**
   * Apply background to element
   */
  color: PropTypes.oneOf(['primary', 'secondary', 'transparent']),

  /**
   * Copyright year. Typically the year company has started
   */
  copyYear: PropTypes.number,

  /**
   * Url to privacy policy page
   */
  privacyUrl: PropTypes.string,
};

Footer.defaultProps = {
  color: 'primary',
  copyYear: new Date().getFullYear(),
  privacyUrl: undefined,
};

export default Footer;
