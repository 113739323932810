import styled, {css} from 'styled-components';
import {Link} from 'react-router-dom';

export const StyledPopperContent = styled.nav`
  width: 250px;
`;

export const StyledAction = styled.span`
  display: inline-flex;

  // .MuiAvatar-root {
  //   border: 2px solid transparent;
  //   transition: border-color 0.1s;
  // }
  // .MuiAvatar-root:hover {
  //   border-color: rgba(255, 255, 255, 0.3);
  // }
`;

export const StyledActionLink = styled(Link)`
  ${({color, theme}) => css`
    padding: 9px 20%;
    display: flex;
    width: 100%;
    align-items: center;
    text-decoration: none;
    font-weight: 500;
    ${!color &&
    css`
      color: ${theme.palette.text.primary};
    `}

    ${color &&
    theme.palette[color] &&
    css`
      color: ${theme.palette[color].main};
    `}

    &:first-child {
      border-radius: ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px
        0px 0px;
    }

    &:last-child {
      border-radius: 0px 0px ${theme.shape.borderRadius}px
        ${theme.shape.borderRadius}px;
    }

    &:hover {
      background: #8c98b2;
      color: #fff;
    }
  `}
`;
