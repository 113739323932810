import React from 'react';
import PropTypes from 'prop-types';
import {StyledButton} from './Styles';

function CustomButton({children, light, ...rest}) {
  return (
    <StyledButton light={+light} {...rest}>
      {children}
    </StyledButton>
  );
}

CustomButton.propTypes = {
  /**
   * React component, HTML node or string
   */
  children: PropTypes.node.isRequired,

  /**
   * If `true`, button will be displayed in white color
   */
  light: PropTypes.bool,
};

CustomButton.defaultProps = {
  light: false,
};

export default CustomButton;
