import {prepareAddresses} from './address';
import {
  API_ADDRESSES,
  API_EMERGENCY_CONTACT_ADDR_CITY,
  API_EMERGENCY_CONTACT_ADDR_LINE_1,
  API_EMERGENCY_CONTACT_ADDR_POSTCODE,
  API_EMERGENCY_CONTACT_ADDR_SUBURB,
} from '../constants';

/**
 * Creates the `include` api params to parse with request.
 *
 * @param {mixed} withParam
 * @returns {string}
 */
export const parseIncludes = withParam => {
  if (typeof withParam !== 'string' && typeof withParam !== 'undefined') {
    return withParam.join(',');
  }
  return withParam;
};

/**
 * Creates the api params to parse with request.
 *
 * @param {object} params
 * @param {string} prefix
 * @returns {string}
 */
export const parseParams = (params, prefix) => {
  const getStr = [];
  let param;

  for (param in params) {
    if (Object.prototype.hasOwnProperty.call(params, param)) {
      const key = prefix ? `${prefix}[${param}]` : param;
      const value = params[param];

      if (value !== null && value !== undefined) {
        // If is an array of strings / numbers if there is a single object (that is not null (typeof null is 'object)) then
        // seperate as per normal - else use the object filter.
        if (
          Array.isArray(value) &&
          value.filter(val => typeof val === 'object' && val !== null)
            .length === 0
        ) {
          // eslint-disable-next-line fp/no-mutating-methods
          getStr.push(
            `${encodeURIComponent(key)}=${encodeURIComponent(value.join('||'))}`
          );
        } else {
          // eslint-disable-next-line fp/no-mutating-methods
          getStr.push(
            typeof value === 'object'
              ? parseParams(value, key)
              : `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
          );
        }
        /* eslint-enable */
      }
    }
  }
  return getStr.join('&');
};

/**
 * Prepares the object which is send as payload to save as tenant data.
 *
 * @param {object} tenant
 * @returns {object
 */
export const prepareTenantRequestPayload = tenant => {
  const {current_address, landlord_references, profile, ...rest} = tenant;
  return {
    ...rest,
    profile: {
      [API_EMERGENCY_CONTACT_ADDR_CITY]: '',
      [API_EMERGENCY_CONTACT_ADDR_LINE_1]: '',
      [API_EMERGENCY_CONTACT_ADDR_POSTCODE]: '',
      [API_EMERGENCY_CONTACT_ADDR_SUBURB]: '',
      ...profile,
    },
    [API_ADDRESSES]: prepareAddresses(current_address, landlord_references),
  };
};
