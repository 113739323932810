import React from 'react';
import PropTypes from 'prop-types';
import {Form} from 'react-final-form';
import {
  Alert,
  Box,
  Heading,
  Modal,
  ModalContent,
  ModalHeader,
  ModalActions,
  useMediaQuery,
} from '@tenancy.nz/design-system';
import FormActions from '../FormActions';
import FieldCheckbox from '../FieldCheckbox';
import {isRequired} from '../../utils/helpers';

function ModalDeleteProfile({onCancel, onSubmit, show}) {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <Form
      initialValues={{
        confirm: false,
      }}
      onSubmit={onSubmit}
      render={({handleSubmit, pristine, submitting}) => (
        <Modal open={show} maxWidth="sm" fullWidth fullScreen={isMobile}>
          <form onSubmit={handleSubmit} noValidate autoComplete="off">
            <ModalHeader onClose={onCancel} elevate={false}>
              <Heading as="h4" gutterBottom={false}>
                Delete Profile
              </Heading>
            </ModalHeader>
            <ModalContent>
              <Box marginBottom="15px">
                <Alert color="error" title="Are you sure?">
                  This will permanently delete your Tenant Profile. This action
                  is not reversible.
                </Alert>
              </Box>
              <Box marginTop="25px">
                <FieldCheckbox
                  name="confirm"
                  label="Yes, I am sure."
                  validate={isRequired}
                  textProps={{
                    weight: '400',
                  }}
                />
              </Box>
            </ModalContent>
            <ModalActions elevate={false}>
              <FormActions
                onCancel={onCancel}
                submitting={submitting}
                disabled={submitting || pristine}
                submitText="Delete"
              />
            </ModalActions>
          </form>
        </Modal>
      )}
    />
  );
}

ModalDeleteProfile.propTypes = {
  /**
   * Function to handle cancel operation
   */
  onCancel: PropTypes.func.isRequired,

  /**
   * Function to handle form submission
   */
  onSubmit: PropTypes.func.isRequired,

  /**
   * Use to hide or show Modal
   */
  show: PropTypes.bool,
};

ModalDeleteProfile.defaultProps = {
  show: false,
};

export default ModalDeleteProfile;
