import {createSlice} from '@reduxjs/toolkit';

export const headerNavSlice = createSlice({
  name: 'headerNav',
  initialState: {
    open: false,
  },
  reducers: {
    setOpen: (state, {payload}) => ({
      ...state,
      open: payload,
    }),
  },
});

export const headerNavState = state => state.headerNav;

export const {setOpen} = headerNavSlice.actions;

export default headerNavSlice.reducer;
