import styled, {css} from 'styled-components';
import {Button} from '@tenancy.nz/design-system';

export const StyledButton = styled(Button)`
  ${({theme, light}) => css`
    ${light &&
    css`
      && {
        border: 1px solid ${theme.palette.background.default};
        color: ${theme.palette.background.default} !important;
      }

      &&:hover {
        background: ${theme.palette.background.default};
        color: ${theme.palette.default.main} !important;
      }
    `}
  `}
`;
