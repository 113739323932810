const palette = {
  type: 'light',
  default: {
    light: '#394869',
    main: '#16223E',
    contrastText: '#fff',
  },
  primary: {
    main: '#00c0f3',
    contrastText: '#fff',
  },
  primaryAlt: {
    main: '#264A9A',
    dark: '#142855',
    contrastText: '#fff',
  },
  secondary: {
    main: '#ec008c',
  },
  secondaryAlt: {
    main: '#AA0270',
    dark: '#7E0052',
    contrastText: '#fff',
  },
  tertiary: {
    light: 'rgb(79, 222, 195)',
    main: '#24D6B4',
    dark: 'rgb(25, 149, 125)',
    contrastText: '#fff',
  },
  error: {
    main: '#dc3545',
    contrastText: '#fff',
  },
  warning: {
    main: '#ffc107',
    contrastText: '#fff',
  },
  success: {
    main: '#28a745',
    contrastText: '#fff',
  },
  info: {
    main: '#ec008c',
    contrastText: '#fff',
  },
  text: {
    primary: '#394869',
    secondary: '#627392',
    muted: '#D5D5D5',
  },
  grey: {
    transparent: '#fff',
    main: '#BCBCBC',
    outline: '#c4c4c4',
    light: '#f1f1f1',
  },
  background: {
    default: '#ffffff',
    paper: '#ffffff',
    transparent: 'rgba(39, 50, 74, 0.7)',
  },
  divider: '#707070',
};

const breakpoints = {
  values: {
    xs: 0,
    sm: 768,
    md: 992,
    lg: 1280,
    xl: 1920,
  },
};

const typography = {
  fontWeightLight: 700,
  fontSize: 15,
  fontFamily: '"Montserrat", sans-serif',
  button: {
    fontSize: 16,
    fontFamily: '"Montserrat", sans-serif',
    fontWeight: 600,
  },
  h1: {
    fontSize: 28,
    fontWeight: 600,
    lineHeight: 1.3,
    [`@media (min-width: ${breakpoints.values.sm}px)`]: {
      fontSize: 32,
    },
  },
  h2: {
    fontSize: 26,
    fontWeight: 600,
    lineHeight: 1.3,
    [`@media (min-width: ${breakpoints.values.sm}px)`]: {
      fontSize: 28,
    },
  },
  h3: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: 1.3,
    [`@media (min-width: ${breakpoints.values.sm}px)`]: {
      fontSize: 26,
    },
  },
  h4: {
    fontSize: 22,
    fontWeight: 600,
    lineHeight: 1.3,
    [`@media (min-width: ${breakpoints.values.sm}px)`]: {
      fontSize: 24,
    },
  },
  h5: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: 1.3,
  },
  h6: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 1.3,
  },
  subtitle1: {
    fontSize: 15,
    lineHeight: 'inherit',
  },
  subtitle2: {
    fontSize: 14,
    fontWeight: 400,
  },
  body1: {
    fontSize: 15,
    fontWeight: 500,
  },
  body2: {
    fontSize: 12,
  },
  caption: {
    fontSize: '0.75rem',
    fontWeight: 500,
  },
  overline: {
    fontSize: '0.75rem',
    fontWeight: 500,
  },
  size: {
    scale: factor => `${0.25 * factor}em`,
    small: '12px',
    medium: '16px',
    large: '20px',
  },
};

const shape = {
  borderRadius: 5,
};

const components = {
  MuiBackdrop: {
    styleOverrides: {
      root: {
        backgroundColor: 'rgba(39, 50, 74, 0.8)',
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        backgroundColor: 'rgba(39, 50, 74, 0.7)',
      },
      arrow: {
        color: 'rgba(39, 50, 74, 0.7)',
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      popupIndicator: {
        transform: 'rotate(0deg) !important',
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        fontSize: '12px !important',
        lineHeight: '14px !important',
        marginBottom: '0px !important',
        '&.Mui-focused': {
          color: palette.default.light,
        },
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        transition: 'background 0.3s ease, color 0.3s ease',
        '&:not(.Mui-selected):hover': {
          background: '#27324a',
        },
      },
    },
  },
  MuiAlert: {
    styleOverrides: {
      message: {
        lineHeight: '1.6',
      },
    },
  },
  MuiAccordion: {
    styleOverrides: {
      root: {
        '&&': {
          padding: '17px',
          background: '#fff',
          borderRadius: '4px',
          position: 'relative',
          boxShadow: '0 0 12px 0 rgba(57, 72, 105, 0.12)',
          margin: '15px 0',
        },
        '&:before': {
          display: 'none',
        },
        '& .MuiAccordionSummary-root': {
          padding: '0',
          minHeight: '10px !important',
          '&.Mui-expanded ': {
            margin: 0,
          },
        },
        '& .MuiAccordionSummary-content': {
          margin: '0',
          '&.Mui-expanded ': {
            margin: '0',
          },
        },
        '& .MuiAccordionDetails-root': {
          padding: '17px 0 0 0',
          margin: 0,
        },
      },
    },
  },
};

const spacing = factor => `${0.25 * factor}rem`;

const props = {
  MuiButton: {
    disableRipple: true,
  },
  MuiButtonBase: {
    disableRipple: true,
  },
};

const theme = {
  palette,
  typography,
  spacing,
  shape,
  breakpoints,
  props,
  components,
};

export default theme;
