/* eslint-disable fp/no-mutating-methods */
/* eslint-disable fp/no-mutation */
import {isArray, isEmpty} from './boolean';
import * as consts from '../constants';
import {indexFractionalChar, isFractionalChar, ucFirst} from './string';
import streetTypes from '../../json/street-types.json';

/**
 * Gets the latest address from the provided list of addresses.
 *
 * @param {array} addresses - List of addresses
 * @param {boolean} current
 * @param {boolean} fallback - If `true` and no address matches the conditions,  will return first address in list
 *
 * @returns {object}
 */
export const getLatestAddress = (addresses, current = 1, fallback = true) => {
  if (Array.isArray(addresses) && addresses.length > 0) {
    // eslint-disable-next-line fp/no-mutating-methods
    const sorted = [...addresses].sort((a, b) => b.id - a.id);
    return (
      sorted.find(address => address[consts.API_IS_CURRENT] === current) ||
      (fallback ? sorted[0] : undefined)
    );
  }
  return undefined;
};

/**
 * Gets landlord details of all previous addresses
 *
 * @param {array} addresses
 *
 * @returns {array}
 */
export const getPreviousLandlordsFromAddress = addresses => {
  if (!isArray(addresses)) {
    return [];
  }
  return addresses
    .filter(address => address[consts.API_IS_CURRENT] === 0)
    .map(address => ({
      [consts.API_ID]: address[consts.API_ID],
      [consts.API_LANDLORD_EMAIL]: address[consts.API_LANDLORD_EMAIL],
      [consts.API_LANDLORD_NAME]: address[consts.API_LANDLORD_NAME],
      [consts.API_LANDLORD_PHONE]: address[consts.API_LANDLORD_PHONE],
    }));
};

/**
 * Concat current and previous addresses details.
 *
 * @param {object} currentAddress
 * @param {array} previousAddresses
 *
 * @returns {array}
 */
export const prepareAddresses = (currentAddress, previousAddresses) => {
  let addresses = [];
  if (!isEmpty(currentAddress)) {
    // eslint-disable-next-line fp/no-mutation
    addresses = [
      ...addresses,
      {
        [consts.API_CITY]: currentAddress[consts.API_CITY] || '',
        [consts.API_ID]: currentAddress[consts.API_ID],
        [consts.API_IS_CURRENT]: 1,
        [consts.API_IS_NZ]: currentAddress[consts.API_IS_NZ],
        [consts.API_LANDLORD_EMAIL]:
          currentAddress[consts.API_LANDLORD_EMAIL] || '',
        [consts.API_LANDLORD_NAME]:
          currentAddress[consts.API_LANDLORD_NAME] || '',
        [consts.API_LANDLORD_PHONE]:
          currentAddress[consts.API_LANDLORD_PHONE] || '',
        [consts.API_POSTCODE]: currentAddress[consts.API_POSTCODE] || '',
        [consts.API_STREET_NAME_1]:
          currentAddress[consts.API_STREET_NAME_1] || '',
        [consts.API_SUBURB]: currentAddress[consts.API_SUBURB] || '',
      },
    ];
  }
  if (isArray(previousAddresses) && !isEmpty(previousAddresses)) {
    const prepared = previousAddresses.map(address => ({
      [consts.API_ID]: address[consts.API_ID],
      [consts.API_IS_CURRENT]: 0,
      [consts.API_LANDLORD_EMAIL]: address[consts.API_LANDLORD_EMAIL] || '',
      [consts.API_LANDLORD_NAME]: address[consts.API_LANDLORD_NAME],
      [consts.API_LANDLORD_PHONE]: address[consts.API_LANDLORD_PHONE] || '',
    }));
    // eslint-disable-next-line fp/no-mutation
    addresses = [...addresses, ...prepared];
  }

  return addresses;
};

export const splitStreet = street => {
  const parts = street.split(' ');
  if (parts.length <= 1) {
    return {
      street,
      type: '',
    };
  }

  let street_name = parts.slice(0, -1).join(' ');
  let street_type = street.match(/\s([\w]+)$/);

  if (street_type !== null) {
    const typeFormatted = ucFirst(street_type[1].toLowerCase());

    const hasSubType = streetTypes.find(
      type =>
        Array.isArray(type?.otherKnownValues) &&
        type.otherKnownValues.indexOf(typeFormatted) !== -1
    );

    const streetValues = streetTypes.map(({value}) =>
      ucFirst(value.toLowerCase())
    );

    if (streetValues.indexOf(typeFormatted) === -1 && !hasSubType) {
      street_name += ` ${street_type[1]}`;
      street_type = null;
    }
  }

  return {
    street: street_name !== null ? street_name : '',
    type: street_type !== null ? ucFirst(street_type[1].toLowerCase()) : '',
  };
};

export const splitAddress = (address, splitType = false) => {
  const parts = address.trim().split(' ');
  let number;

  if (parts.length <= 1) {
    return {
      number: '',
      space: '',
      street: splitType ? splitStreet(address.trim()) : address.trim(),
    };
  }

  if (
    !Number.isNaN(Number(parts[0].substr(0, 1))) ||
    isFractionalChar(parts[0].substr(0, 1))
  ) {
    number = parts.shift();
  } else {
    return {
      number: '',
      space: '',
      street: splitType ? splitStreet(address.trim()) : address.trim(),
    };
  }

  if (
    /[0-9]\/[0-9]/.exec(parts[0]) ||
    indexFractionalChar(parts[0]) !== false
  ) {
    number += ` ${parts.shift()}`;
  }

  return {
    number,
    space: ' ',
    street: splitType ? splitStreet(parts.join(' ')) : parts.join(' '),
  };
};
