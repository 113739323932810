import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import {motion} from 'framer-motion';
import {CircularProgress} from '@tenancy.nz/design-system';

const StyledLoader = styled(motion.div)`
  ${({fixed}) => css`
    width: 70px;
    height: 70px;
    overflow: visible;
    border-radius: 50%;
    box-shadow: 0px 7px 17px 1px rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    background: #fff;

    ${fixed &&
    css`
      position: fixed;
      left: 50%;
      top: 20%;
      transform: translateX(-50%);
      z-index: 99999;
    `}
  `}
`;

function Loader({fixed, ...rest}) {
  const variants = {
    initial: {
      y: -20,
      opacity: 0,
    },
    in: {
      y: 0,
      opacity: 1,
    },
    out: {
      y: -20,
      opacity: 0,
    },
  };
  return (
    <StyledLoader
      variants={variants}
      animate="in"
      exit="out"
      initial="initial"
      fixed={fixed}
      transition={{duration: 0.25}}>
      <CircularProgress {...rest} />
    </StyledLoader>
  );
}

Loader.propTypes = {
  color: PropTypes.oneOf(['inherit', 'primary', 'secondary']),
  fixed: PropTypes.bool,
  size: PropTypes.number,
  thickness: PropTypes.number,
};

Loader.defaultProps = {
  color: 'secondary',
  fixed: true,
  size: 50,
  thickness: 5,
};

export default Loader;
