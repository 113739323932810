/* eslint-disable no-param-reassign */
/* eslint-disable fp/no-mutation */
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import Service from '../../api/Service';
import ValidationError from '../../Errors/ValidationError';
import UnauthorizedError from '../../Errors/UnauthorizedError';

export const fetchWithdrawReasonsAsync = createAsyncThunk(
  'withdraw_reason/fetchWithdrawReasonsAsync',
  async (_, thunkAPI) => {
    try {
      const {data} = await Service.get('tenancy/application/withdraw_reasons');
      return data;
    } catch (e) {
      if (e instanceof ValidationError) {
        return thunkAPI.rejectWithValue({
          error: {
            code: 422,
            message: e.message,
          },
        });
      }
      if (e instanceof UnauthorizedError) {
        return thunkAPI.rejectWithValue({
          error: {
            code: e.code,
            message: e.message,
          },
        });
      }
      return thunkAPI.rejectWithValue({code: '', message: e});
    }
  }
);

export const withdrawReasonSlice = createSlice({
  name: 'withdraw_reason',
  initialState: {
    fetching: true,
    error: false,
    errorMsg: '',
    reasons: [],
  },
  reducers: {
    setFetching: (state, {payload}) => {
      state.fetching = payload;
    },
    setReasons: (state, {payload}) => {
      state.reasons = payload;
    },
  },
  extraReducers: {
    [fetchWithdrawReasonsAsync.fulfilled]: (state, {payload}) => {
      state.fetching = false;
      state.error = false;
      state.errorMsg = '';
      state.reasons = payload;
    },
    [fetchWithdrawReasonsAsync.pending]: state => {
      state.fetching = true;
      state.error = false;
      state.errorMsg = '';
    },
    [fetchWithdrawReasonsAsync.rejected]: state => {
      state.fetching = false;
      state.error = true;
      state.errorMsg = '';
    },
  },
});

export default withdrawReasonSlice.reducer;

export const {setFetching, setReasons} = withdrawReasonSlice.actions;

export const withdrawReasonState = state => state.withdrawReason;
