/* eslint-disable no-param-reassign */
/* eslint-disable fp/no-mutation */
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import Service from '../../api/Service';
import ValidationError from '../../Errors/ValidationError';
import {
  API_ID,
  API_PETS,
  API_ADDRESSES,
  ENDPOINT_TENANT,
  ENDPOINT_TENANT_ADDRESSES,
  ENDPOINT_TENANT_DOCUMENTS,
  ENDPOINT_TENANT_PETS,
  ENDPOINT_TENANT_RESIDENTS,
  ENDPOINT_TENANT_REFERENCES,
  ENDPOINT_TENANT_VEHICLES,
  API_GUARANTOR,
  API_DOCUMENTS,
} from '../../utils/constants';
import {implode} from '../../utils/helpers';

export const createSupportingDocument = createAsyncThunk(
  'tenant/createSupportingDocument',
  async (data, thunkAPI) => {
    try {
      const response = await Service.post(ENDPOINT_TENANT_DOCUMENTS, data);
      return response.data;
    } catch (error) {
      if (error instanceof ValidationError) {
        return thunkAPI.rejectWithValue({
          error,
        });
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteAddressAsync = createAsyncThunk(
  'tenant/deleteAddressAsync',
  async (id, thunkAPI) => {
    try {
      const response = await Service.delete(
        `${ENDPOINT_TENANT_ADDRESSES}/${id}`
      );
      return response.data;
    } catch (error) {
      if (error instanceof ValidationError) {
        return thunkAPI.rejectWithValue({
          error,
        });
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deletePetAsync = createAsyncThunk(
  'tenant/deletePetAsync',
  async (id, thunkAPI) => {
    try {
      const response = await Service.delete(`${ENDPOINT_TENANT_PETS}/${id}`);
      return response.data;
    } catch (error) {
      if (error instanceof ValidationError) {
        return thunkAPI.rejectWithValue({
          error,
        });
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteResidentAsync = createAsyncThunk(
  'tenant/deleteResidentAsync',
  async (id, thunkAPI) => {
    try {
      const response = await Service.delete(
        `${ENDPOINT_TENANT_RESIDENTS}/${id}`
      );
      return response.data;
    } catch (error) {
      if (error instanceof ValidationError) {
        return thunkAPI.rejectWithValue({
          error,
        });
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteReferenceAsync = createAsyncThunk(
  'tenant/deleteReferenceAsync',
  async (id, thunkAPI) => {
    try {
      const response = await Service.delete(
        `${ENDPOINT_TENANT_REFERENCES}/${id}`
      );
      return response.data;
    } catch (error) {
      if (error instanceof ValidationError) {
        return thunkAPI.rejectWithValue({
          error,
        });
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteSupportingDocument = createAsyncThunk(
  'tenant/deleteSupportingDocument',
  async (file, thunkAPI) => {
    try {
      const response = await Service.delete(
        `${ENDPOINT_TENANT_DOCUMENTS}/${file[API_ID]}`
      );
      return response.data;
    } catch (error) {
      if (error instanceof ValidationError) {
        return thunkAPI.rejectWithValue({
          error,
        });
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteVehicleAsync = async id => {
  try {
    const response = await Service.delete(`${ENDPOINT_TENANT_VEHICLES}/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const fetchProfileAsync = createAsyncThunk(
  'tenant/fetchProfileAsync',
  async (fullProfile, thunkAPI) => {
    try {
      const otherIncludes = [
        'addresses',
        'documents',
        'guarantor',
        'identity',
        'pets',
        'references',
        'residents',
      ];
      const includes = ['profile', ...(fullProfile ? otherIncludes : [])];
      const response = await Service.get(
        `${ENDPOINT_TENANT}?include=${implode(',', includes)}`
      );
      return response.data;
    } catch (error) {
      if (error instanceof ValidationError) {
        return thunkAPI.rejectWithValue({
          error,
        });
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateProfileAsync = createAsyncThunk(
  'tenant/updateProfileAsync',
  async (data, thunkAPI) => {
    try {
      const response = await Service.post(ENDPOINT_TENANT, data);
      return response.data;
    } catch (error) {
      if (error instanceof ValidationError) {
        return thunkAPI.rejectWithValue({
          error,
        });
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  addresses: [],
  documents: [],
  employment: {},
  error: false,
  id: null,
  identity: null,
  pending: true,
  profile: {},
  [API_GUARANTOR]: null,
  references: [],
  residents: [],
  vehicles: [],
  username: null,
  saved: false,
  saving: false,
};

export const tenantSlice = createSlice({
  name: 'tenant',
  initialState,
  reducers: {
    setError: (state, {payload}) => {
      state.error = payload;
    },
    setPending: (state, {payload}) => {
      state.pending = payload;
    },
    setSaved: (state, {payload}) => {
      state.saved = payload;
    },
    setSaving: (state, {payload}) => {
      state.saving = payload;
    },
    setProfileInitialData: state => {
      state = {...state, ...initialState};
      return state;
    },
  },
  extraReducers: {
    // Get tenant profile
    [fetchProfileAsync.fulfilled]: (state, {payload}) => {
      state = {
        ...state,
        ...payload,
        error: false,
        pending: false,
      };
      return state;
    },
    [fetchProfileAsync.pending]: (state, {payload}) => {
      state.pending = true;
      state.error = false;
    },
    [fetchProfileAsync.rejected]: (state, {payload}) => {
      state.pending = false;
      state.error = true;
    },
    // Update tenant profile details
    [updateProfileAsync.fulfilled]: (state, {payload}) => {
      state = {
        ...state,
        error: false,
        ...payload,
      };
      return state;
    },
    [updateProfileAsync.pending]: (state, {payload}) => {
      state.error = false;
    },
    [updateProfileAsync.rejected]: (state, {payload}) => {
      state.error = true;
    },
    [createSupportingDocument.fulfilled]: (state, {payload}) => {
      state.documents = [...state.documents, payload];
      return state;
    },
    [deletePetAsync.fulfilled]: (state, {payload, meta: {arg}}) => {
      state[API_PETS] = state[API_PETS].filter(pets => pets[API_ID] !== arg);
      return state;
    },
    [deleteSupportingDocument.fulfilled]: (state, {payload, meta: {arg}}) => {
      state[API_DOCUMENTS] = state[API_DOCUMENTS].filter(
        docs => docs[API_ID] !== arg[API_ID]
      );
      return state;
    },
    [deleteAddressAsync.fulfilled]: (state, {payload, meta: {arg}}) => {
      state[API_ADDRESSES] = state[API_ADDRESSES].filter(
        addresses => addresses[API_ID] !== arg
      );
      return state;
    },
  },
});

export const tenantState = state => state.tenant;

export const {
  setError,
  setPending,
  setSaved,
  setSaving,
  setProfileInitialData,
} = tenantSlice.actions;

export default tenantSlice.reducer;
