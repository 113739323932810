/**
 * @type {string}
 */
export const VALUE_DRIVERS_LICENCE = 'dl';

/**
 * @type {string}
 */
export const VALUE_PASSPORT = 'pp';

/**
 * @type {string}
 */
export const VALUE_EIGHTEEN_PLUS = 'eighteenplus';

/**
 * @type {string}
 */
export const VALUE_EXTRA_ID = 'extra_id';

/**
 * @type {string}
 */
export const VALUE_PROOF_INCOME = 'Proof of Income';

/**
 * @type {string}
 */
export const VALUE_PROOF_ADDRESS = 'Proof of Address';

/**
 * @type {string}
 */
export const VALUE_PROOF_OTHERS = 'Other';

/**
 * @type {string}
 */
export const VALUE_EMPLOYMENT = 'employment';

/**
 * @type {string}
 */
export const VALUE_WINZ_BENIFIT = 'benifit';

/**
 * @type {string}
 */
export const VALUE_SELF_EMPLOYED = 'self_employment';

/**
 * @type {string}
 */
export const VALUE_NO = 'no';

/**
 * @type {string}
 */
export const VALUE_YES = 'yes';

/**
 * @type {string}
 */
export const PROP_FILE_NAME = 'name';
