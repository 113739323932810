/* eslint-disable consistent-return */
/* eslint-disable fp/no-mutation */
import {browserSupports} from './browserSupports';

const BROWSER_SUPPORTS = browserSupports();

export default class NotificationHandler {
  constructor() {
    throw new Error(`${this.constructor.name} cannot be instantiated.`);
  }

  static isNotificationsSupported() {
    return BROWSER_SUPPORTS.notifications;
  }

  static getNotificationClass() {
    if (!NotificationHandler.isNotificationsSupported()) {
      throw new Error('Unsupported browser notifications.');
    }
    return Notification;
  }

  static async requestPermission() {
    return NotificationHandler.getNotificationClass().requestPermission();
  }

  static async getNotificationPermission() {
    return NotificationHandler.getNotificationClass().permission;
  }

  static async handleRequestNotificationPermission() {
    if ((await NotificationHandler.getNotificationPermission()) !== 'granted') {
      const notificationPermission =
        await NotificationHandler.requestPermission();
      if (notificationPermission !== 'granted') {
        throw new Error('Notification permission not granted');
      }
    }
    return NotificationHandler.getNotificationClass().permission;
  }
}
