/* eslint-disable no-param-reassign */
/* eslint-disable fp/no-mutation */
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import Service from '../../api/Service';
import {
  prepareOrderProductParams,
  findCheck,
  abortHandler,
  prepareTenantCheck,
  compareChecksSame,
  checkTypesMatches,
} from '../../utils/helpers';
import {UPGRADE_PRODUCTS, CHECK_TYPES} from '../../utils/constants';

export const creditCheckUpgrade = createAsyncThunk(
  'upgrade/creditCheckUpgrade',
  async ({type, order_product_id, meta}, thunkAPI) => {
    const {dispatch, rejectWithValue} = thunkAPI;
    try {
      const [source, done] = abortHandler(thunkAPI);
      const result = await Service.post(
        '/tenant/checks',
        {
          type,
          order_product_id,
          meta,
        },
        {
          cancelToken: source.token,
        }
      ).then(done);
      await dispatch(fetchUserUpgrades()).unwrap();
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const refreshCheck = createAsyncThunk(
  'upgrade/refreshCheck',
  async (checkId, thunkAPI) => {
    const {dispatch, rejectWithValue} = thunkAPI;
    try {
      const [source, done] = abortHandler(thunkAPI);
      const result = await Service.post(
        `/tenant/checks/${checkId}/refresh`,
        {},
        {
          cancelToken: source.token,
        }
      ).then(done);
      await dispatch(fetchUserUpgrades()).unwrap();
      await dispatch(tenantChecks()).unwrap();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const propertyManagerChecks = createAsyncThunk(
  'upgrade/propertyManagerChecks',
  async (_, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
      const [source, done] = abortHandler(thunkAPI);
      const result = await Service.get('/tenant/checks/pm/who', {
        cancelToken: source.token,
      }).then(done);
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const tenantChecks = createAsyncThunk(
  'upgrade/checks',
  async (_, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
      const [source, done] = abortHandler(thunkAPI);
      const result = await Service.get('/tenant/checks', {
        cancelToken: source.token,
        params: {
          include: ['credit_check'].join(','),
          sort: 'id desc',
          'filter[type]': `${CHECK_TYPES.centrix_credit_check}||${CHECK_TYPES.equifax_credit_check}`,
        },
      }).then(done);
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchUserUpgrades = createAsyncThunk(
  'upgrade/fetchUserUpgrades',
  async (_, thunkAPI) => {
    try {
      const [source, done] = abortHandler(thunkAPI);

      const {data, meta, links} = await Service.get('/user/order_products', {
        cancelToken: source.token,
        params: prepareOrderProductParams(),
      }).then(done);

      return {
        data,
        meta,
        links,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
  {
    condition: (_, {getState}) => {
      const {upgrade} = getState();
      return upgrade && !upgrade.pending;
    },
  }
);

const initialState = () => ({
  pending: false,
  error: null,
  items: [],
  pmChecks: [],
  checks: [],
  pmChecksPending: false,
  checksPending: false,
  refreshingCheck: false,
  creditCheckUpgrade: undefined,
  verifiedUpgrade: undefined,
  verifiedIdCheck: undefined,
  cetrixCreditCheck: undefined,
  equifaxCreditCheck: undefined,
  // equifaxLimitedCheck: undefined,
  centrixLimitedCheck: undefined,
  lastFetchedUpgrades: null,
});

export const upgradeProductsSlice = createSlice({
  name: 'upgrade',
  initialState: initialState(),
  reducers: {
    resetState() {
      return initialState();
    },
  },
  extraReducers: {
    // Latest upgrades
    [fetchUserUpgrades.fulfilled]: (state, {payload}) => {
      state.items = payload.data;
      state.error = null;
      state.verifiedUpgrade = state.items.find(
        u => u.product_id === UPGRADE_PRODUCTS.verified
      );

      state.creditCheckUpgrade = state.items.find(
        u => u.product_id === UPGRADE_PRODUCTS.credit_checks
      );

      state.verifiedIdCheck = findCheck(
        state.verifiedUpgrade,
        CHECK_TYPES.centrix_id_check
      );

      state.centrixLimitedCheck = prepareTenantCheck(
        findCheck(
          state.creditCheckUpgrade,
          CHECK_TYPES.centrix_limited_check
        ) ?? findCheck(state.verifiedUpgrade, CHECK_TYPES.centrix_limited_check)
      );

      state.centrixCreditCheck = prepareTenantCheck(
        findCheck(state.creditCheckUpgrade, CHECK_TYPES.centrix_credit_check)
      );

      state.equifaxCreditCheck = prepareTenantCheck(
        findCheck(state.creditCheckUpgrade, CHECK_TYPES.equifax_credit_check)
      );
      state.lastFetchedUpgrades = Date.now();
      state.pending = false;
    },
    [fetchUserUpgrades.pending]: state => {
      state.pending = true;
      state.error = null;
    },
    [fetchUserUpgrades.rejected]: (state, {payload}) => {
      state.pending = false;
      state.error = payload && payload.error;
    },
    // All checks (to display in list)
    [tenantChecks.pending]: state => {
      state.checksPending = true;
    },
    [tenantChecks.fulfilled]: (state, {payload}) => {
      state.checks = payload.data?.map(prepareTenantCheck) ?? [];
      state.checksPending = false;
    },
    [tenantChecks.rejected]: state => {
      state.checksPending = false;
    },
    // Refresh check
    [refreshCheck.fulfilled]: state => {
      state.refreshingCheck = false;
    },
    [refreshCheck.pending]: state => {
      state.refreshingCheck = true;
    },
    [refreshCheck.rejected]: state => {
      state.refreshingCheck = false;
    },
    // PM checks against this user
    [propertyManagerChecks.fulfilled]: (state, {payload}) => {
      state.pmChecks = payload.data;
      state.pmChecksPending = false;
    },
    [propertyManagerChecks.pending]: (state, {payload}) => {
      state.pmChecksPending = true;
    },
    [propertyManagerChecks.rejected]: (state, {payload}) => {
      state.pmChecksPending = false;
    },
    [creditCheckUpgrade.fulfilled]: (state, {payload}) => {
      state.upgradingCheck = false;
    },
    [creditCheckUpgrade.pending]: (state, {payload}) => {
      state.upgradingCheck = true;
    },
    [creditCheckUpgrade.rejected]: (state, {payload}) => {
      state.upgradingCheck = false;
    },
  },
});

export const upgradeState = state => state.upgrade;

export const checksState = state =>
  state.upgrade.items.reduce((a, b) => [...a, ...(b.checks || [])], []);

export const previousChecksState = state => {
  const {
    centrixCreditCheck = {},
    equifaxCreditCheck = {},
    checks = [],
  } = upgradeState(state);

  return checks.filter(
    check =>
      checkTypesMatches(check, [
        CHECK_TYPES.equifax_credit_check,
        CHECK_TYPES.centrix_credit_check,
      ]) &&
      !compareChecksSame(check, centrixCreditCheck) &&
      !compareChecksSame(check, equifaxCreditCheck)
  );
};

export const hasVerifiedIDState = state =>
  upgradeState(state)?.verifiedIdCheck?.id_check?.verified?.allVerified;

export const {resetState} = upgradeProductsSlice.actions;

export default upgradeProductsSlice.reducer;
