import React from 'react';
import PropTypes from 'prop-types';
import {StyledLogo, StyledLogoImg} from './Styles';

function Logo(props) {
  const {alt, height, src, width} = props;
  const figureProps = {
    height,
    width,
  };
  const imgProps = {
    alt,
    src,
  };
  // {srcMobile && <StyledLogoImgMobile {...imgProps} src={srcMobile} />}
  return (
    <StyledLogo {...figureProps}>
      <StyledLogoImg {...imgProps} />
    </StyledLogo>
  );
}

Logo.propTypes = {
  /**
   * Alt text to display if image link is broken
   */
  alt: PropTypes.string.isRequired,

  /**
   * Defines the height of logo
   */
  height: PropTypes.string,

  /**
   * Image link
   */
  src: PropTypes.string.isRequired,

  /**
   * Image link for mobile devices
   */
  srcMobile: PropTypes.string,

  /**
   * Defines the width of logo
   */
  width: PropTypes.string,
};

Logo.defaultProps = {
  height: 'auto',
  srcMobile: '',
  width: '150px',
};

export default Logo;
