/**
 * @type {string}
 */
export const CREDIT_CHECK_API_LEGAL_FIRST_NAME = 'legal_first_name';

/**
 * @type {string}
 */
export const CREDIT_CHECK_API_LEGAL_MIDDLE_NAME = 'legal_middle_name';

/**
 * @type {string}
 */
export const CREDIT_CHECK_API_MIDDLE_ON_LICENCE = 'middle_name_on_licence';

/**
 * @type {string}
 */
export const CREDIT_CHECK_API_FIRST_ON_LICENCE = 'first_name_on_licence';

/**
 * @type {string}
 */
export const CREDIT_CHECK_API_LEGAL_LAST_NAME = 'legal_last_name';

/**
 * @type {string}
 */
export const CREDIT_CHECK_API_DATE_OF_BIRTH = 'date_of_birth';

/**
 * @type {string}
 */
export const CREDIT_CHECK_API_ID_TYPE = 'id_type';

/**
 * @type {string}
 */
export const CREDIT_CHECK_API_DRIVERS_LICENCE_NUMBER = 'drivers_license_number';

/**
 * @type {string}
 */
export const CREDIT_CHECK_API_DRIVERS_LICENCE_VERSION =
  'drivers_license_version';

/**
 * @type {string}
 */
export const CREDIT_CHECK_API_PASSPORT_NUMBER = 'passport_number';

/**
 * @type {string}
 */
export const CREDIT_CHECK_API_PASSPORT_VERSION = 'passport_version';

/**
 * @type {string}
 */
export const CREDIT_CHECK_API_STREET_NUMBER = 'street_number';

/**
 * @type {string}
 */
export const CREDIT_CHECK_API_STREET = 'street_name';

/**
 * @type {string}
 */
export const CREDIT_CHECK_API_STREET_TYPE = 'street_type';

/**
 * @type {string}
 */
export const CREDIT_CHECK_API_SUBURB = 'suburb';

/**
 * @type {string}
 */
export const CREDIT_CHECK_API_CITY = 'city';

/**
 * @type {string}
 */
export const CREDIT_CHECK_API_COUNTRY = 'country';

/**
 * @type {string}
 */
export const CREDIT_CHECK_API_POST_CODE = 'post_code';

/**
 * @type {string}
 */
export const CREDIT_CHECK_API_YEARS_AT_ADDRESS = 'years_at_address';

/**
 * @type {string}
 */
export const CREDIT_CHECK_API_IS_CURRENT_ADDRESS = 'is_current_address';

/**
 * @type {string}
 */
export const CREDIT_CHECK_API_CONFIRM_PRIVACY = 'privacy_confirm';

/**
 * @type {string}
 */
export const CREDIT_CHECK_API_CARD_NAME = 'card_name';

/**
 * @type {string}
 */
export const CREDIT_CHECK_API_CARD_NUMBER = 'card_number';

/**
 * @type {string}
 */
export const CREDIT_CHECK_API_CARD_EXP_MONTH = 'card_exp_month';

/**
 * @type {string}
 */
export const CREDIT_CHECK_API_CARD_EXP_YEAR = 'card_exp_year';

/**
 * @type {string}
 */
export const CREDIT_CHECK_API_CARD_CVC = 'card_cvc';

/**
 * @type {string}
 */
export const CREDIT_CHECK_API_DISCOUNT_CODE = 'card_discount_code';

// Tenant check response
export const TENANT_CHECK_API_ID = 'id;';
export const TENANT_CHECK_API_TYPE = 'type';
export const TENANT_CHECK_API_STATUS = 'status';
export const TENANT_CHECK_API_ERROR = 'error';
export const TENANT_CHECK_API_REQUEST = 'request';
export const TENANT_CHECK_API_RESPONSE = 'response';
export const TENANT_CHECK_API_REFRESHED = 'refreshed';
export const TENANT_CHECK_API_USER_ID = 'user_id';
export const TENANT_CHECK_API_ORDER_ID = 'order_id';
export const TENANT_CHECK_API_TENANT_ID = 'tenant_id';
export const TENANT_CHECK_API_CREDIT_CHECK = 'credit_check';
export const TENANT_CHECK_API_CREATED_AT = 'created_at';
export const TENANT_CHECK_API_UPDATED_AT = 'updated_at';

// Credit check response
export const CREDIT_CHECK_API_FIRST_NAME = 'first_name';
export const CREDIT_CHECK_API_MIDDLE_NAME = 'middle_name';
export const CREDIT_CHECK_API_LAST_NAME = 'last_name';
export const CREDIT_CHECK_API_DOB = 'dob';
export const CREDIT_CHECK_API_STATUS = 'status';
export const CREDIT_CHECK_API_IS_CENTRIX = 'is_centrix';
export const CREDIT_CHECK_API_IS_EQUIFAX = 'is_equifax';
export const CREDIT_CHECK_API_EQUIFAX = 'equifax';
export const CREDIT_CHECK_API_CENTRIX = 'centrix';

// Equifax response
export const EQUIFAX_CREDIT_CHECK_API_RESULT = 'result';
export const EQUIFAX_CREDIT_CHECK_API_REPORT = 'report';
export const EQUIFAX_CREDIT_CHECK_API_REPORT_ID = 'id';
export const EQUIFAX_CREDIT_CHECK_API_REPORT_SUMMARY = 'summary';
export const EQUIFAX_CREDIT_CHECK_API_ERRORS = 'errors';

// Centrix response
export const CENTRIX_CREDIT_CHECK_API_RESULT = 'status';
export const CENTRIX_CREDIT_CHECK_API_ERRORS = 'errors';

/**
 * @type {object}
 */
export const CHECK_TYPES = Object.freeze({
  centrix_id_check: 'CENTRIX_ID_CHECK',
  centrix_credit_check: 'CENTRIX_CREDIT_CHECK',
  equifax_credit_check: 'EQUIFAX_CREDIT_CHECK',
  equifax_limited_check: 'EQUIFAX_LIMITED_CREDIT_CHECK',
  centrix_limited_check: 'CENTRIX_LIMITED_CREDIT_CHECK',
});

/**
 * @type {object}
 */
export const CHECK_STATUSES = Object.freeze({
  pending: 'PENDING',
  queued: 'QUEUED',
  complete: 'COMPLETE',
  failed: 'FAILED',
  error: 'ERROR',
});

/**
 * @type {object}
 */
export const CHECK_ACTION_TYPES = Object.freeze({
  fix: 'fix',
  view: 'view',
  run: 'run',
  refresh: 'refresh',
});
