import styled, {css} from 'styled-components';
import {Link} from 'react-router-dom';

const activeStyles = css`
  ${({active, color, theme}) => css`
    ${active === 1 &&
    color === 'primary' &&
    css`
      color: ${theme.palette.primary.dark};
    `}
    ${active === 1 &&
    color !== 'primary' &&
    css`
      color: ${theme.palette.primary.main};
    `}
  `}
`;

const baseStyles = css`
  text-decoration: none;
  color: inherit;
  transition: all 230ms ease-in-out;
  display: inline-flex;
  font-size: inherit;
`;

const hoverStyles = css`
  ${({color, theme}) => css`
    &:hover {
      ${color === 'primary' &&
      css`
        color: ${theme.palette.primary.dark};
      `}
      ${color !== 'primary' &&
      css`
        color: ${theme.palette.primary.main};
      `}
    }
  `}
`;

const StyledNavLink = styled(Link)`
  ${baseStyles}
  ${activeStyles}
  ${hoverStyles}
`;

export const StyledNavHyperlink = styled.a`
  ${baseStyles}
  ${activeStyles}
  ${hoverStyles}
`;

export default StyledNavLink;
