import React from 'react';
import {Box, Container, Heading, Text} from '@tenancy.nz/design-system';

function Maintenance() {
  return (
    <Container fixed>
      <Box marginTop="70px">
        <Heading as="h1" align="center" color="inherit">
          We&#39;ll be back soon
        </Heading>
        <Text align="center">
          The Tenant Portal is currently undergoing some essential maintenance.
        </Text>
        <Text paragraph align="center">
          Check in a little later to see how it is going, but we will be back
          online before too long.
        </Text>
        <Text paragraph align="center">
          Thank you for your understanding.
        </Text>
      </Box>
    </Container>
  );
}

export default Maintenance;
