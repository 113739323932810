import {getObjectProp} from './object';
import * as consts from '../constants/agency';

/**
 * Transforms api results to object which is parsed to React components
 *
 * @param {object} agency
 *
 * @return {object}
 */
export const prepareAgencyProps = agency => ({
  name: getObjectProp(agency, consts.API_AGENCY_APPLICATION_NAME),
  logo: getObjectProp(agency, consts.API_AGENCY_LOGO),
});
