/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {Link as BaseLink, Text} from '@tenancy.nz/design-system';

function Link({
  children,
  history,
  location,
  match,
  staticContext,
  to,
  onClick,
  ...rest
}) {
  return (
    <BaseLink
      href="#"
      underline="hover"
      onClick={e => {
        e.preventDefault();
        onClick && onClick(e);
        // eslint-disable-next-line fp/no-mutating-methods
        history.push(to);
      }}
      {...rest}>
      <Text as="span" weight="600" display="inline" variant="inherit">
        {children}
      </Text>
    </BaseLink>
  );
}

Link.propTypes = {
  /**
   * Component, node or text
   */
  children: PropTypes.node.isRequired,

  /**
   * Url to which redirect to
   */
  to: PropTypes.string.isRequired,
};

export default withRouter(Link);
