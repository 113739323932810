import {isEmpty, isArray} from './boolean';

export const implode = (delimiter, arr, noEmpty = true) =>
  (noEmpty ? arr.filter(item => !isEmpty(item)) : arr).join(delimiter);

export const range = (start, end) =>
  Array.from({length: end - start}, (v, k) => k + start);

export const arrayUnique = arr => {
  if (!isArray(arr)) {
    // eslint-disable-next-line no-console
    console.error(`first argument should be an array.`);
    return false;
  }
  const iterator = (value, index, self) => self.indexOf(value) === index;
  return arr.filter(iterator);
};

export const sortArrByDateAsc = (arr, prop) => {
  if (!isArray(arr)) {
    return [];
  }
  // eslint-disable-next-line fp/no-mutating-methods
  return arr.slice().sort((a, b) =>
    // eslint-disable-next-line no-nested-ternary
    a[prop] < b[prop] ? -1 : a[prop] > b[prop] ? 1 : 0
  );
};

export const sortArrByDateDesc = (arr, prop) => {
  if (!isArray(arr)) {
    return [];
  }
  // eslint-disable-next-line fp/no-mutating-methods
  return arr.slice().sort((a, b) =>
    // eslint-disable-next-line no-nested-ternary
    a[prop] > b[prop] ? -1 : a[prop] < b[prop] ? 1 : 0
  );
};

export const unflatten = obj =>
  Object.keys(obj).reduce((res, k) => {
    k.split('.').reduce((acc, e, i, keys) => {
      if (acc[e]) {
        return acc[e];
      }
      // eslint-disable-next-line no-return-assign, fp/no-mutation, no-nested-ternary
      return (acc[e] = isNaN(Number(keys[i + 1]))
        ? keys.length - 1 === i
          ? obj[k]
          : {}
        : []);
    }, res);
    return res;
  }, {});
