import {isInteger} from './boolean';

/**
 * Converts the provided numeric string in to number.
 *
 * @param {string} val
 * @param {int} radix
 *
 * @returns {int}
 */
export const toNumber = (val, radix = 10) =>
  isInteger(val) ? parseInt(val, radix) : false;

/**
 * Converts the provided numeric string in to float number.
 *
 * @param {string} val
 * @param {int} decimals
 *
 * @returns {int}
 */
export const toFloat = (val, decimals) => {
  if (isInteger(val)) {
    if (decimals) {
      return parseFloat(val).toFixed(decimals);
    }
    return parseFloat(val);
  }
  return false;
};

/**
 * Coverts to a number using Number consutrctor,
 * if v === NaN|null|undefined returns defaultVal otherwise
 * returns the value as a number.
 *
 * @param {*} val
 * @param {*} defaultVal
 *
 */
export const numberOrDefault = (val, defaultVal = null) => {
  if (val === null || val === undefined) return defaultVal;
  const v = Number(val);
  return isNaN(v) ? defaultVal : v;
};
