/* eslint-disable no-console */
/* eslint-disable fp/no-mutation */
import {browserSupports} from './browserSupports';

const BROWSER_SUPPORTS = browserSupports();

export default class ServiceWorkerHandler {
  constructor() {
    throw new Error(`${this.constructor.name} cannot be instantiated.`);
  }

  static getServiceWorker() {
    if (!ServiceWorkerHandler.isServiceWorkerSupported()) {
      throw new Error('Unsupported service worker.');
    }
    return navigator.serviceWorker;
  }

  static isServiceWorkerSupported() {
    if (!BROWSER_SUPPORTS.serviceWorker) {
      return false;
    }
    return true;
  }

  static async registerServiceWorker(url, options) {
    let registration = null;
    let pushSubscription = null;
    try {
      registration = await ServiceWorkerHandler.getServiceWorkerRegistration(
        url
      );
      if (registration) {
        console.log('Returning existing registration');
        return registration;
      }

      // If theres another controller
      if (ServiceWorkerHandler.getServiceWorker().controller) {
        registration = await ServiceWorkerHandler.getServiceWorker().ready;
        pushSubscription = await registration.pushManager.getSubscription();

        if (pushSubscription) {
          console.log('Removing push subscription');
          await pushSubscription.unsubscribe();
        }

        console.log('Removing existing service worker');
        await registration.unregister();
      }

      console.log('Registering service worker: ', url);
      return ServiceWorkerHandler.getServiceWorker().register(url, options);
    } catch (err) {
      console.error(err);
      if (registration) {
        await registration.unregister();
      }
      if (pushSubscription) {
        await pushSubscription.unsubscribe();
      }
      throw err;
    }
  }

  static async getServiceWorkerRegistration(url) {
    return ServiceWorkerHandler.getServiceWorker().getRegistration(url);
  }

  static async getRegisteredServiceWorker(url) {
    const serviceWorkerRegistration =
      await ServiceWorkerHandler.getServiceWorkerRegistration(url);
    if (!serviceWorkerRegistration) {
      throw new Error('No service worker registered');
    }
    const serviceWorker =
      serviceWorkerRegistration.waiting ||
      serviceWorkerRegistration.installing ||
      serviceWorkerRegistration.active;
    if (!serviceWorker) {
      throw new Error(
        'Could not find service worker in any state, has one been registered?'
      );
    }
    return serviceWorker;
  }
}
