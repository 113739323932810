import React from 'react';
import PropTypes from 'prop-types';
import {Text} from '@tenancy.nz/design-system';
import StyledNavLink, {StyledNavHyperlink} from './NavLink.styled';

function NavLink({active, children, color, href, to, ...textProps}) {
  const commonProps = {active: +active, color};

  const text = (
    <Text as="span" variant="inherit" {...textProps}>
      {children}
    </Text>
  );

  if (!href && !to) {
    return null;
  }

  if (href && !to) {
    return (
      <StyledNavHyperlink {...commonProps} href={href}>
        {text}
      </StyledNavHyperlink>
    );
  }

  return (
    <StyledNavLink {...commonProps} to={to}>
      {text}
    </StyledNavLink>
  );
}

NavLink.propTypes = {
  /**
   */
  active: PropTypes.bool,

  /**
   */
  color: PropTypes.oneOf(['inherit']),

  /**
   */
  children: PropTypes.node.isRequired,

  /**
   */
  href: PropTypes.string,

  /**
   */
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
};

NavLink.defaultProps = {
  color: 'inherit',
  active: false,
  href: undefined,
  to: undefined,
};

export default NavLink;
