/**
 * Format in which(most) api calls will return dates
 *
 * @type {string}
 */
export const DATE_FORMAT_ISO8601 = 'YYYY-MM-DD';

/**
 * Human readable date format
 *
 * @type {string}
 */
export const DATE_FORMAT_DISPLAY = 'DD/MM/YYYY';

/**
 * Human readable time format.
 *
 * @type {string}
 */
export const TIME_FORMAT_DISPLAY = 'h:mma';

/**
 * Human readable date long format.
 *
 * @type {string}
 */
export const DATE_TIME_FORMAT_DISPLAY = `ddd, D MMM, ${TIME_FORMAT_DISPLAY}`;

/**
 * ISO8601  format.
 *
 * @type {string}
 */
export const DATE_ISO8601_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ[Z]';
