/* eslint-disable no-param-reassign */
/* eslint-disable fp/no-mutation */
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import Service from '../../api/Service';
import ValidationError from '../../Errors/ValidationError';
import UnauthorizedError from '../../Errors/UnauthorizedError';

export const sendEnquiry = createAsyncThunk(
  'contact/sendEnquiry',
  async (payload, thunkAPI) => {
    try {
      const response = await Service.post(`guest/tenant/enquiry`, payload);
      return response.data;
    } catch (e) {
      if (e instanceof ValidationError) {
        return thunkAPI.rejectWithValue({
          error: {
            code: 422,
            message: e.message,
          },
        });
      }
      if (e instanceof UnauthorizedError) {
        return thunkAPI.rejectWithValue({
          error: {
            code: e.code,
            message: e.message,
          },
        });
      }
      return thunkAPI.rejectWithValue({code: '', message: e});
    }
  }
);

export const contactSlice = createSlice({
  name: 'contact',
  initialState: {
    fetching: true,
    error: false,
    errorMsg: '',
  },
  reducers: {
    setFetching: (state, {payload}) => {
      state.fetching = payload;
    },
  },
  extraReducers: {
    [sendEnquiry.fulfilled]: (state, {payload}) => {
      state.fetching = false;
      state.error = false;
      state.errorMsg = '';
    },
    [sendEnquiry.pending]: state => {
      state.fetching = true;
      state.error = false;
      state.errorMsg = '';
    },
    [sendEnquiry.rejected]: state => {
      state.fetching = false;
      state.error = true;
      state.errorMsg = '';
    },
  },
});

export default contactSlice.reducer;

export const {setFetching} = contactSlice.actions;

export const contactState = state => state.contact;
