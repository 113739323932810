/* eslint-disable fp/no-mutation */
import {
  ID_VERIFIED_MODAL,
  KEY_AUTHENTICATED,
  NZTA_MAINTANENCE_MODE_MODAL,
} from '../constants';

export const getSessionItem = key => localStorage.getItem(key);

export const setSessionItem = (key, value) => localStorage.setItem(key, value);

export const unSetSessionItem = key => localStorage.removeItem(key);

export const getLoggedIn = () => getSessionItem(KEY_AUTHENTICATED) === 'true';

export const setLoggedIn = val =>
  setSessionItem(KEY_AUTHENTICATED, val || false);

export const unsetLoggedIn = () => unSetSessionItem(KEY_AUTHENTICATED);

export const setVerifiedUpgradeModalID = id =>
  setSessionItem(ID_VERIFIED_MODAL, id);

export const getVerifiedUpgradeModalID = () =>
  getSessionItem(ID_VERIFIED_MODAL);

export const getLastShownMaintanenceModal = () =>
  getSessionItem(NZTA_MAINTANENCE_MODE_MODAL);

export const setLastShownMaintanenceModal = date =>
  setSessionItem(NZTA_MAINTANENCE_MODE_MODAL, date);
