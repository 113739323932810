/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import CustomButton from '../CustomButton';

function LinkButton({
  children,
  history,
  location,
  match,
  staticContext,
  to,
  onClick,
  state,
  ...rest
}) {
  return (
    <CustomButton
      {...rest}
      onClick={e => {
        onClick && onClick(e);
        // eslint-disable-next-line fp/no-mutating-methods
        history.push(to, state);
      }}>
      {children}
    </CustomButton>
  );
}

LinkButton.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default withRouter(LinkButton);
