import {DEFAULT_MAX_UPLOAD_SIZE} from '../constants';

/**
 * Delay certain action.
 *
 * @param {number} ms | number of milliseconds
 *
 * @returns {Promise}
 */
export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

/**
 * Gets the value of given environment variable defined in `process.env`.
 *
 * @param {string} name | Name of the environment variable
 * @param {mixed} fallback | If  environment undefined, use as fallback value
 *
 * @returns {mixed}
 */
export const env = (name, fallback) => {
  if (process && process.env && process.env[name] !== undefined) {
    return process.env[name];
  }
  return fallback;
};

/**
 * Format number of bytes to display in human readable number.
 *
 * @param {float} bytes | Number of bytes
 * @param {number} decimals | Number of trailing decimal numbers.
 *
 * @returns {string}
 */
export const formatBytes = (bytes, decimals = 2) => {
  const sizes = ['Bytes', 'KB', 'MB'];
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  if (bytes === 0) return `0 ${sizes[0]}`;
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / k ** i).toFixed(dm))}${sizes[i]}`;
};

/**
 * Gets the max file upload size.
 *
 * @returns {number}
 */
export const getMaxUploadSize = () =>
  parseInt(env('REACT_APP_MAX_UPLOAD_SIZE', DEFAULT_MAX_UPLOAD_SIZE), 10);

/**
 * Runs func and catches exceptions
 *
 * @returns {*}
 */
export const failSafe = (func, fallback = undefined) => {
  try {
    return func();
  } catch (e) {
    return fallback;
  }
};
