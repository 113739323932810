import {implode} from './array';
import {isArray, isObject} from './boolean';
import {getObjectProp} from './object';
import * as consts from '../constants/property';
import PropertyPlaceholder from '../../assets/placeholder-property.jpeg';

/**
 * Gets the list of property images
 *
 * @param {object} property
 *
 * @return {array}
 */
export const propertyImages = property => {
  if (getObjectProp(property, consts.API_PROPERTY_IMAGES)) {
    const images = property[consts.API_PROPERTY_IMAGES];
    if (isArray(images)) {
      return images;
    }
  }
  return [];
};

/**
 * Gets the first image of property from list
 *
 * @param {object|array} data - object of property or array of images
 *
 * @return {string}
 */
export const propertyMainImage = data => {
  const images = isArray(data) ? data : propertyImages(data);
  if (images.length > 0) {
    return (
      getObjectProp(images[0], 'large_url') || getObjectProp(images[0], 'url')
    );
  }
  return '';
};

/**
 * Creates string of full address from address components
 *
 * @param {object} obj - object of address components
 *
 * @return {string}
 */
export const propertyFullAddress = obj => {
  let str = '';
  if (isObject(obj)) {
    // eslint-disable-next-line fp/no-mutation
    str = implode('/', [
      obj[consts.API_PROPERTY_ADDR_UNIT],
      obj[consts.API_PROPERTY_ADDR_STREET_NUMBER],
    ]);
    // eslint-disable-next-line fp/no-mutation
    str += `${' '}`;
    // eslint-disable-next-line fp/no-mutation
    str += implode(', ', [
      obj[consts.API_PROPERTY_ADDR_STREET],
      obj[consts.API_PROPERTY_ADDR_SUBURB],
      obj[consts.API_PROPERTY_ADDR_CITY],
    ]);
  }
  return str;
};

/**
 * Transforms api results to object which is parsed to React components
 *
 * @param {object} property
 *
 * @return {object}
 */
export const preparePropertyProps = property => {
  const images = propertyImages(property);
  const fullAddress = propertyFullAddress(property);
  const [shortAddress] = fullAddress.split(',');
  return {
    agentName: getObjectProp(property, consts.API_PROPERTY_AGENT_NAME),
    city: getObjectProp(property, consts.API_PROPERTY_ADDR_CITY),
    email: getObjectProp(property, consts.API_PROPERTY_EMAIL),
    mobile: getObjectProp(property, consts.API_PROPERTY_MOBILE),
    id: getObjectProp(property, consts.API_PROPERTY_ADDR_ID),
    images,
    postcode: getObjectProp(property, consts.API_PROPERTY_ADDR_POSTCODE),
    primaryImage: (images[0] && images[0].url) || PropertyPlaceholder,
    street: getObjectProp(property, consts.API_PROPERTY_ADDR_STREET),
    streetNumber: getObjectProp(
      property,
      consts.API_PROPERTY_ADDR_STREET_NUMBER
    ),
    suburb: getObjectProp(property, consts.API_PROPERTY_ADDR_SUBURB),
    unit: getObjectProp(property, consts.API_PROPERTY_ADDR_UNIT),
    fullAddress,
    shortAddress,
  };
};
