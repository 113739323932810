/* eslint-disable fp/no-mutation */
/* eslint-disable prefer-const */
import CreditCheck from './CreditCheck';
import * as consts from '../../constants';

class EquifaxCreditCheck {
  constructor(data) {
    this.data = data;
  }

  build() {
    const {data} = this;

    const enquiryReport =
      data.BCAservices.BCAservice['BCAservice-data'].response['enquiry-report'];

    // Summary data
    const {summary = {}} = data;

    let {
      individual: {
        'individual-name': {
          'first-given-name': firstName,
          'other-given-name': middleName,
          'family-name': lastName,
        } = {},
        address,
        'date-of-birth': dateOfBirth,
      } = {},
      'score-data': scoreData = {},
      // eslint-disable-next-line prettier/prettier
      quotations: {'quotation-enquiry': quotationEnquiries} = {},
      'credit-file': {'credit-enquiry': creditEnquiries} = {},
      'default-file': {default: defaults} = {},
      'judgement-file': {judgement: judgements} = {},
      'insolvency-file': {insolvency: insolvencies} = {},
      'public-notice-file': {'public-notice': publicNotices} = {},
      'company-officer-details': {'company-officer': directorships} = {},
    } = enquiryReport;

    const partialName = `${firstName} ${lastName}`;

    const accessAsArray = (arrayData = [], mapFunc) => {
      if (Array.isArray(arrayData)) {
        return arrayData.map(mapFunc);
      }
      if (arrayData) {
        return [mapFunc(arrayData)];
      }
      return [];
    };

    const mapEnquiries = ({
      'credit-enquirer': enquirer_name,
      'client-reference': enquiry_reason,
      'enquiry-amount': amount_sought,
      'account-type': product_type,
      '@attributes': attributes,
    }) => ({
      display_date: attributes['create-date'],
      enquirer_name,
      enquiry_reason,
      amount_sought,
      product_type,
      applicant_type: 'N/A',
    });

    const mapAddress = ({
      'street-name': streetName,
      'street-number': streetNumber,
      suburb,
      city,
      '@attributes': {'create-date': dateLoaded, type},
    }) => ({
      address: `${streetNumber} ${streetName}, ${
        suburb ? `${suburb}, ` : ''
      }${city}`,
      display_date: dateLoaded,
      isCurrent: type === 'current',
    });

    const mapInsolvencies = ({
      'adjudication-court': adjudication_court,
      'adjudication-date': adjudication_date,
      'insolvency-number': number,
      'insolvency-status': status,
      'insolvency-type': type,
      'discharge-date': discharge_date = 'Unknown',
    }) => ({
      adjudication_court,
      adjudication_date,
      discharge_date,
      number,
      type,
      status,
    });

    const mapDefaults = ({
      '@attributes': {
        'company-loan': companyLoan,
        'defaulter-detail-number': defaulterDetailNumber,
        'joint-liability': jointLiability,
      },
      'default-date': display_date,
      'account-type': account_type,
      'credit-provider': credit_provider,
      'default-original-amount': original,
      'default-balance-amount': balance,
      'default-status': status,
      'status-date': status_date = 'N/A',
    }) => ({
      display_date,
      account_type,
      credit_provider,
      original,
      balance,
      status,
      status_date,
    });

    const mapJudgements = ({
      '@attributes': {'unique-id': id},
      'court-type': court_type,
      creditor,
      'judgement-amount': judgement_amount,
      'judgement-status': judgement_status,
      'date-paid': date_paid,
      'load-date': load_date,
      narrative,
    }) => ({
      id,
      court_type,
      creditor,
      judgement_amount,
      judgement_status,
      date_paid,
      load_date,
      narrative,
    });

    const mapPublicNotices = ({
      narrative: description,
      'notice-type': type,
      'publication-date': date,
    }) => ({
      description,
      type,
      date,
    });

    const mapDirectorships = ({
      'company-name': company_name,
      'company-status': status,
      'first-given-name': first_name,
      'other-given-name': last_name,
      'registration-number': registration_number,
    }) => ({
      company_name,
      status,
      first_name,
      last_name,
      registration_number,
    });

    creditEnquiries = accessAsArray(creditEnquiries, mapEnquiries);
    quotationEnquiries = accessAsArray(quotationEnquiries, mapEnquiries);
    insolvencies = accessAsArray(insolvencies, mapInsolvencies);
    judgements = accessAsArray(judgements, mapJudgements);
    publicNotices = accessAsArray(publicNotices, mapPublicNotices);
    defaults = accessAsArray(defaults, mapDefaults);
    directorships = accessAsArray(directorships, mapDirectorships);

    const previousAddresses = accessAsArray(address, mapAddress);
    const totalAddresses = previousAddresses.length;
    const currentAddress = previousAddresses.find(a => a.isCurrent)?.address;

    return new CreditCheck()
      .setId(data.id)
      .setAddress(currentAddress)
      .setType(consts.CHECK_TYPES.equifax_credit_check)
      .setTotalAddresses(totalAddresses)
      .setPreviousAddresses(previousAddresses)
      .setFirstName(firstName)
      .setMiddleName(middleName)
      .setPublicNotices(publicNotices)
      .setDirectorships(directorships)
      .setLastName(lastName)
      .setAge(summary['age-of-subject'])
      .setDob(dateOfBirth)
      .setName(partialName)
      .setCreditScore(scoreData['risk-odds'])
      .setPreviousEnquiries(creditEnquiries)
      .setQuotationEnquiries(quotationEnquiries)
      .setDefaults(defaults)
      .setJudgments(judgements)
      .setInsolvencies(insolvencies)
      .setTotalJudgments(summary['judgement-count'])
      .setTotalPrevEnq(summary['total-enquiries'])
      .setCreditAmount(summary['credit-amount'])
      .setTotalDefaults(summary.defaults)
      .setTotalInsolvencies(summary['insolvency-count'])
      .setTotalBankruptcies(summary['bankruptcy-count'])
      .setTotalCoAffil(null)
      .setTotalNames(null);
  }

  getCheck() {
    return this.creditCheck;
  }

  get check() {
    return this.getCheck();
  }
}

export default EquifaxCreditCheck;
