/* eslint-disable consistent-return */
/* eslint-disable fp/no-mutation */
import {browserSupports} from './browserSupports';
import NotificationHandler from './notificationHandler';
import ServiceWorkerHandler from './serviceWorkerHandler';

const BROWSER_SUPPORTS = browserSupports();

export default class PushSubscriptionHandler {
  constructor() {
    throw new Error(`${this.constructor.name} cannot be instantiated.`);
  }

  static async unsubscribePushNotifications(swUrl) {
    let pushSubscription;
    try {
      const registration =
        await ServiceWorkerHandler.getServiceWorkerRegistration(swUrl);
      if (!registration) {
        return;
      }
      pushSubscription = await registration.pushManager.getSubscription();
      if (!pushSubscription) {
        return;
      }
      await pushSubscription.unsubscribe();
    } catch (err) {
      if (pushSubscription) {
        await pushSubscription.unsubscribe().catch(() => {});
      }
    }
    return pushSubscription;
  }

  static async handleRegisterPushNotification(swUrl, options) {
    try {
      if (!BROWSER_SUPPORTS.pushNotifications) {
        throw new Error('Unsupported push notifications');
      }

      const serviceWorkerRegistration =
        await ServiceWorkerHandler.getServiceWorkerRegistration(swUrl);

      if (!serviceWorkerRegistration) {
        throw new Error('Unable to find service worker registration');
      }

      let pushSubscription =
        await serviceWorkerRegistration.pushManager.getSubscription();

      const pushSubscriptionExpired =
        pushSubscription?.expirationTime &&
        pushSubscription?.expirationTime < Date.now();

      if (!pushSubscription || pushSubscriptionExpired) {
        // Create or re-use an existing push subscription
        pushSubscription =
          await serviceWorkerRegistration.pushManager.subscribe(options);
      }

      if (!pushSubscription) {
        throw new Error('Could not create push subscription');
      }

      return pushSubscription;
    } catch (err) {
      try {
        await NotificationHandler.unsubscribePushNotifications(swUrl);
      } catch (_) {
        //
      }
      throw err;
    }
  }
}
