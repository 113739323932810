/* eslint-disable no-param-reassign */
/* eslint-disable fp/no-mutation */
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import Service from '../../api/Service';
import ValidationError from '../../Errors/ValidationError';
import UnauthorizedError from '../../Errors/UnauthorizedError';

export const sendDataRequest = createAsyncThunk(
  'contact/sendDataRequest',
  async (payload, thunkAPI) => {
    try {
      const response = await Service.post(`guest/tenant/request_data`, payload);
      return response.data;
    } catch (e) {
      if (e instanceof ValidationError) {
        return thunkAPI.rejectWithValue({
          error: {
            code: 422,
            message: e.message,
          },
        });
      }
      if (e instanceof UnauthorizedError) {
        return thunkAPI.rejectWithValue({
          error: {
            code: e.code,
            message: e.message,
          },
        });
      }
      return thunkAPI.rejectWithValue({code: '', message: e});
    }
  }
);

export const requestDataSlice = createSlice({
  name: 'requestData',
  initialState: {
    fetching: true,
    error: false,
    errorMsg: '',
  },
  reducers: {
    setFetching: (state, {payload}) => {
      state.fetching = payload;
    },
  },
  extraReducers: {
    [sendDataRequest.fulfilled]: (state, {payload}) => {
      state.fetching = false;
      state.error = false;
      state.errorMsg = '';
    },
    [sendDataRequest.pending]: state => {
      state.fetching = true;
      state.error = false;
      state.errorMsg = '';
    },
    [sendDataRequest.rejected]: state => {
      state.fetching = false;
      state.error = true;
      state.errorMsg = '';
    },
  },
});

export default requestDataSlice.reducer;

export const {setFetching} = requestDataSlice.actions;

export const requestDataState = state => state.requestData;
