/* eslint-disable no-param-reassign */
/* eslint-disable fp/no-mutation */
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import Service from '../../api/Service';
import TrackerService from '../../api/TrackerService';
import ValidationError from '../../Errors/ValidationError';
import UnauthorizedError from '../../Errors/UnauthorizedError';
import {
  getPastDateByMonthsUtc,
  prepareGetRequestParams,
  parseParams,
} from '../../utils/helpers';

export const fetchViewingsAsync = createAsyncThunk(
  'viewing/fetchViewingsAsync',
  async (extraParams, thunkAPI) => {
    try {
      const params = prepareGetRequestParams({
        [`filter[viewing.date_time>=]`]: getPastDateByMonthsUtc().toISOString(),
        ...extraParams,
      });
      const {data: viewings} = await TrackerService.get(`bookings?${params}`);
      const agencyIds = viewings
        .map(item => item.agency.id)
        .filter((value, index, self) => self.indexOf(value) === index);

      const {data: agencies} = await Service.get(
        `tenant/agency_logos?${parseParams(agencyIds, 'id')}`
      );

      const logos = agencies.reduce(
        (prev, curr) => ({
          ...prev,
          [curr.id]: curr.logo,
        }),
        {}
      );

      return viewings.map(viewing => ({
        ...viewing,
        agency: {...viewing.agency, logo: logos[viewing.agency.id]},
      }));
    } catch (error) {
      if (error instanceof ValidationError) {
        return thunkAPI.rejectWithValue({
          error,
        });
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateViewingAsync = createAsyncThunk(
  'viewing/updateViewingAsync',
  async ({id, payload}, thunkAPI) => {
    try {
      const response = await TrackerService.patch(`bookings/${id}`, payload);
      return response.data;
    } catch (e) {
      if (e instanceof ValidationError) {
        return thunkAPI.rejectWithValue({
          error: {
            code: 422,
            message: e.message,
          },
        });
      }
      if (e instanceof UnauthorizedError) {
        return thunkAPI.rejectWithValue({
          error: {
            code: e.code,
            message: e.message,
          },
        });
      }
      return thunkAPI.rejectWithValue({code: '', message: e});
    }
  }
);

export const markViewingNotificationReadAsync = createAsyncThunk(
  'viewing/markViewingNotificationReadAsync',
  async (id, thunkAPI) => {
    try {
      const response = await TrackerService.patch(`bookings/${id}`, {
        updates: null,
      });
      return response.data;
    } catch (e) {
      if (e instanceof ValidationError) {
        return thunkAPI.rejectWithValue({
          error: {
            code: 422,
            message: e.message,
          },
        });
      }
      if (e instanceof UnauthorizedError) {
        return thunkAPI.rejectWithValue({
          error: {
            code: e.code,
            message: e.message,
          },
        });
      }
      return thunkAPI.rejectWithValue({code: '', message: e});
    }
  }
);

export const viewingSlice = createSlice({
  name: 'viewing',
  initialState: {
    fetching: true,
    error: false,
    errorMsg: '',
    viewings: [],
  },
  reducers: {
    setFetching: (state, {payload}) => {
      state.fetching = payload;
    },
    setViewings: (state, {payload}) => {
      state.viewings = payload;
    },
    updateViewing: (state, {payload}) => {
      if (payload.id) {
        const {id, ...rest} = payload;
        state.viewings = state.viewings.map(item => {
          if (item.id === id) {
            return {...item, ...rest};
          }
          return item;
        });
      }
    },
  },
  extraReducers: {
    [fetchViewingsAsync.fulfilled]: (state, {payload, ...rest}) => {
      state.fetching = false;
      state.error = false;
      state.errorMsg = '';
      state.viewings = payload;
    },
    [fetchViewingsAsync.pending]: state => {
      state.fetching = true;
      state.error = false;
      state.errorMsg = '';
    },
    [fetchViewingsAsync.rejected]: state => {
      state.fetching = false;
      state.error = true;
      state.errorMsg = '';
    },
    [updateViewingAsync.rejected]: (state, {payload}) => {
      state.error = true;
      state.errorMsg = payload.message;
    },
    [markViewingNotificationReadAsync.fulfilled]: (state, {payload}) => {
      state.fetching = false;
      state.error = false;
      state.errorMsg = '';
      state.viewings = state.viewings.map(item => {
        if (item.id === payload.id) {
          return {...item, updates: null};
        }
        return item;
      });
    },
  },
});

export default viewingSlice.reducer;

export const {setFetching, setViewings, updateViewing} = viewingSlice.actions;

export const viewingState = state => state.viewing;
