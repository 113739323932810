/* eslint-disable no-param-reassign */
/* eslint-disable fp/no-mutation */
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {defaultHandlers} from 'utils/helpers';
import ValidationError from 'Errors/ValidationError';
import Service from '../../api/Service';

export const fetchCart = createAsyncThunk(
  'cart/replace',
  async (_, thunkAPI) => {
    try {
      return await Service.get('/user/cart');
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const replaceCartItems = createAsyncThunk(
  'cart/replace',
  async (payload, thunkAPI) => {
    try {
      return await Service.put('/user/cart/items', payload);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createCartOrder = createAsyncThunk(
  'cart/order',
  async (payload, thunkAPI) => {
    try {
      return await Service.post('/user/cart/order', payload);
    } catch (error) {
      if (error instanceof ValidationError) {
        return thunkAPI.rejectWithValue({
          message: error.message,
          errors: Object.entries(error.getErrors() ?? {})
            .map(([key, value]) => ({
              [key.replace('meta.', '')]:
                value?.replace('meta.', '') ?? 'Invalid value',
            }))
            .reduce((a, b) => ({...a, ...b}), {}),
        });
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const applyCartDiscount = createAsyncThunk(
  'cart/discount',
  async (discount, thunkAPI) => {
    try {
      const result = await Service.post(`/user/cart/discount/${discount}`);
      await thunkAPI.dispatch(fetchCart());
      return result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    loading: false,
    replacingItems: false,
    creatingOrder: false,
    applyingDiscount: false,
    error: null,
    cart: {},
  },
  reducers: {},
  extraReducers: {
    [fetchCart.fulfilled]: (state, {payload}) => {
      state.cart = payload.data;
      state.loading = false;
      state.error = null;
    },
    [createCartOrder.fulfilled]: state => {
      state.creatingOrder = false;
      state.error = null;
    },
    [applyCartDiscount.fulfilled]: state => {
      state.applyingDiscount = false;
      state.error = null;
    },
    [replaceCartItems.fulfilled]: (state, {payload}) => {
      state.cart = payload.data;
      state.replacingItems = false;
      state.error = null;
    },
    ...defaultHandlers(fetchCart, 'loading'),
    ...defaultHandlers(replaceCartItems, 'replacingItems'),
    ...defaultHandlers(createCartOrder, 'creatingOrder'),
    ...defaultHandlers(applyCartDiscount, 'applyingDiscount'),
  },
});

export const cartState = state => state.cart;
export default cartSlice.reducer;
