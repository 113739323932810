import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import dayjsToObject from 'dayjs/plugin/toObject';
import dayjsUtc from 'dayjs/plugin/utc';
import dayjsTimezone from 'dayjs/plugin/timezone';
import {range} from './array';
import * as consts from '../constants/date';

dayjs.extend(customParseFormat);
dayjs.extend(dayjsToObject);
dayjs.extend(dayjsUtc);
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(dayjsTimezone);

export const dateFormat = (date, format) => {
  if (!format) {
    // eslint-disable-next-line no-console
    console.error(`Missing date format. eg. ${consts.DATE_FORMAT_DISPLAY}`);
    return false;
  }
  const dateStr = dayjs(date).format(format);
  return dateStr === 'Invalid Date' ? false : dateStr;
};

export const dateToDisplayFormat = date =>
  dateFormat(date, consts.DATE_FORMAT_DISPLAY);

export const dateGetDate = date => dayjs(date).date();

export const dateGetMonth = date => dayjs(date).month() + 1;

export const dateGetYear = date => dayjs(date).year();

export const dateToObject = (str, format) => {
  const {years, months, date} = dayjs(
    str,
    format || consts.DATE_FORMAT_ISO8601
  ).toObject();
  if (isNaN(years) && isNaN(months) && isNaN(date)) {
    return false;
  }
  return {years, months: months + 1, date};
};

export const isValidDate = value => {
  if (!value) {
    return undefined; // use required to handle this
  }
  // change the / format into something javascript can deal with...
  const values = value.split(/[/-]/);
  if (values.length !== 3) {
    return 'Invalid Date';
  }

  const year = values[0].length === 4 ? values[0] : values[2];
  const month = values[1];
  const day = values[0].length === 4 ? values[2] : values[0];

  if (!day) {
    return 'Please select a day.';
  }

  if (!month) {
    return 'Please select a month.';
  }

  if (!year) {
    return 'Please select a year.';
  }

  const date = new Date(year, month - 1, day);

  // make sure javascript doesn't do that stupid thing were Feb 31 becomes March 3...
  return Boolean(+date) && date.getDate() === parseInt(day, 10)
    ? undefined
    : 'Invalid Date.';
};

export const parseStringToDate = string => {
  if (string) {
    const values = string.split(/[/-]/);
    if (values.length === 3) {
      const year = parseInt(values[0], 10);
      const month = parseInt(values[1], 10) - 1;
      const day = parseInt(values[2], 10);
      return new Date(year, month, day);
    }
  }
  return string;
};

export const disableFuture = value => {
  if (!value) {
    return undefined; // use required to handle this
  }
  const today = new Date();
  const date = parseStringToDate(value);
  return date > today ? 'Please select a date in the past.' : undefined;
};

export const disablePast = value => {
  if (!value) {
    return undefined; // use required to handle this
  }
  const today = new Date();
  const date = parseStringToDate(value);
  return date < today ? 'Please select a date in the future.' : undefined;
};

export const thisYear = parseInt(new Date().getFullYear(), 10);

export const parseDateToString = ({day, month, year}) =>
  `${year}-${month.toString().padStart(2, '0')}-${day
    .toString()
    .padStart(2, '0')}`;

export const parseDateValue = string => {
  // sometimes dates come in dd-mm-yyyy format, sometimes in dd/mm/yyyy format...
  if (string) {
    const values = string.split(/[/-]/);
    if (values.length === 3) {
      if (values[0].length === 4) {
        // yyyy-mm-dd format...
        return {
          day: parseInt(values[2], 10),
          month: parseInt(values[1], 10),
          year: parseInt(values[0], 10),
        };
      }
      return {
        day: parseInt(values[0], 10),
        month: parseInt(values[1], 10),
        year: parseInt(values[2], 10),
      };
    }
  }
  return {day: '', month: '', year: ''};
};

export const parseDobToObj = str => {
  const defaults = {dob_year: '', dob_month: '', dob_day: ''};
  if (!str) {
    return defaults;
  }
  const fromSqlFormat = dateToObject(str, consts.DATE_FORMAT_ISO8601);
  const {years, months, date} = fromSqlFormat || {};
  return {
    dob_year: years ? years.toString() : '',
    dob_month: months ? months.toString().padStart(2, '0') : '',
    dob_day: date ? date.toString().padStart(2, '0') : '',
  };
};

export const getDateOpts = () => {
  const options = range(1, 32).map(day => {
    const formatted = day.toString().padStart(2, '0');
    return {
      value: formatted,
      label: formatted,
    };
  });
  return [
    {
      value: '',
      label: 'DD',
    },
    ...options,
  ];
};

export const getMonthOpts = () => {
  const options = range(1, 13).map(day => {
    const formatted = day.toString().padStart(2, '0');
    return {
      value: formatted,
      label: formatted,
    };
  });
  return [
    {
      value: '',
      label: 'MM',
    },
    ...options,
  ];
};

export const getYearsOpts = () => {
  const currentYear = dayjs().year();
  // eslint-disable-next-line fp/no-mutating-methods
  const options = range(currentYear - 101, currentYear)
    .reverse()
    .map(year => {
      const formatted = year.toString();
      return {
        value: formatted,
        label: formatted,
      };
    });
  return [
    {
      value: '',
      label: 'YYYY',
    },
    ...options,
  ];
};

/**
 * Check if the provided string is valid date
 *
 * @param {string} str
 *
 * @return {mixed}
 */
export const isDate = str => {
  if (str === 'Invalid date') {
    return false;
  }
  return str;
};

/**
 * Converts UTC datetime to local datetime.
 *
 * @param {string} date
 *
 * @return {instanceof} dayjs
 */
export const formUtcToLocal = date => {
  const localDateStr = dayjs.utc(date).tz('Pacific/Auckland');
  return isDate(localDateStr);
};

/**
 * Converts UTC datetime to local display format .
 *
 * @param {string} date String in `YYYY-MM-DD HH:mm:ss` format.
 * @return {mixed}
 */
export const formUtcToLocalDisplayFormat = date => {
  const dateObj = formUtcToLocal(date);
  if (dateObj) {
    return dateObj.format(consts.DATE_TIME_FORMAT_DISPLAY);
  }
  return false;
};

/**
 * Converts UTC datetime to local ISO string.
 *
 * @param {string} date String in `YYYY-MM-DD HH:mm:ss` format.
 * @return {mixed}
 */
export const formUtcToLocalISOString = date => {
  const dateObj = formUtcToLocal(date);
  if (dateObj) {
    return dateObj.format(consts.DATE_ISO8601_FORMAT);
  }
  return false;
};

/**
 * Gets the dayjs instance for provided date string.
 * If date string not provided then will return current date.
 *
 * @param {str}
 * @return {dayjs} instance of dayjs object.
 */
export const getDate = (str = '', utc = false) => {
  if (str && utc) {
    return dayjs.utc(str);
  }
  if (!str && utc) {
    return dayjs.utc();
  }
  if (str && !utc) {
    return dayjs(str);
  }
  return dayjs();
};

/**
 * Gets past month from current datetime in UTC
 *
 * @return {dayjs} instance of dayjs object.
 */
export const getPastDateByMonthsUtc = (months = 1) =>
  dayjs.utc().subtract(months, 'month');

/**
 * Gets date in future by provided no of days.
 *
 * @param {int} days - no of days in future
 * @param {string} str - valid date/datetime string or will use now if empty
 * @param {bool} utc - If `true`, will treat date as UTC
 * @return {dayjs} instance of dayjs object.
 */
export const getDateByNoOfDays = (days, str = '', utc = false) => {
  if (utc) {
    return getDate(str, utc).add(days, 'day');
  }
  return getDate(str, utc).add(days, 'day');
};

/**
 * Gets date in past by provided no of days.
 *
 * @param {int} days - no of days in past
 * @param {string} str - valid date/datetime string or will use now if empty
 * @param {bool} utc - If `true`, will treat date as UTC
 * @return {dayjs} instance of dayjs object.
 */
export const getPastDateByNoOfDays = (days, str = '', utc = false) => {
  if (utc) {
    return getDate(str).utc().subtract(days, 'day');
  }
  return getDate(str).subtract(days, 'day');
};
