import {isArray, isEmpty, isString} from './boolean';
import {toCents, toDollars} from './currency';
import {parseBool} from './form';
import {toFloat} from './number';
import {getObjectProp} from './object';
import {propertyFullAddress} from './property';
import {strToLower} from './string';
import * as consts from '../constants';

/**
 * Plucks a tenant from the list which matches with provided email
 *
 * @param {array} tenants - List of tenants
 * @param {string} email - A tenant email
 *
 * @return {object}
 */
export const tenancyTenant = (tenants, email) => {
  if (isArray(tenants)) {
    const tenant = tenants.find(
      iterator =>
        strToLower(iterator[consts.API_TENANCY_TENANT_EMAIL]) ===
        strToLower(email)
    );
    if (tenant) {
      const {tc_for_signing_agreement, ...rest} = tenant;
      return {
        ...rest,
        tcType:
          tc_for_signing_agreement[consts.API_TENANCY_TENANT_SIGN_TERMS_TYPE],
        bondTenant: tc_for_signing_agreement.tenant || {},
        bondAgreement: tc_for_signing_agreement.agreement || {},
      };
    }
  }
  return false;
};

/**
 * Checks if tenant has signed the documents/have e-sig id
 *
 * @param {object} tenant
 *
 * @return {bool}
 */
export const hasTenantSignedDocuments = tenant => {
  if (!isEmpty(tenant)) {
    return (
      !isEmpty(tenant[consts.API_TENANCY_TENANT_SIG_DATE]) &&
      !isEmpty(tenant[consts.API_TENANCY_TENANT_SIG_FILE_ID])
    );
  }
  return false;
};

/**
 * Gets the tenancy status details for display
 *
 * @param {string} status
 *
 * @return {object}
 */
export const tenancyStatus = (data, tenant) => {
  if (data) {
    if (!isEmpty(data[consts.API_TENANCY_IS_FINALISED])) {
      return {
        color: 'tertiary',
        label: 'Finalised',
        reference: consts.STATUS_FINALISED,
      };
    }
    if (
      !isEmpty(tenant[consts.API_TENANCY_TENANT_SIG_FILE_ID]) &&
      !isEmpty(tenant[consts.API_TENANCY_TENANT_SIG_DATE]) &&
      isEmpty(data[consts.API_TENANCY_IS_FINALISED])
    ) {
      return {
        color: 'default',
        label: 'Agent to sign',
        reference: consts.STATUS_WAITING_AGENT_SIGNATURE,
      };
    }
  }
  return {
    color: 'secondary',
    label: 'Waiting Signature',
    reference: consts.STATUS_WAITING_SIGNATURE,
  };
};

/**
 * Gets the tenancy type details for display
 *
 * @param {string} type
 *
 * @return {object}
 */
export const tenancyAgreementType = (subType, type) => {
  const sundaryColor = type === 'sundry' ? 'secondary' : 'tertiary';
  const mapObj = {
    renewal: {color: sundaryColor, label: 'Renewal agreement'},
    variation: {color: sundaryColor, label: 'Variation agreement'},
    extension: {color: sundaryColor, label: 'Extension agreement'},
    standard: {color: 'primary', label: 'Standard agreement'},
    unknown: {color: 'primary', label: 'Standard agreement'},
  };
  return mapObj[subType];
};

/**
 * Gets the list of tenancy property images
 *
 * @param {object} property
 *
 * @return {array}
 */
export const tenancyPropertyImages = property => {
  if (!isEmpty(property)) {
    const images = property[consts.API_TENANCY_PROPERTY_IMAGES];
    if (!isEmpty(images)) {
      return images;
    }
  }
  return [];
};

/**
 * Gets the first image of property from list
 *
 * @param {object} property
 *
 * @return {string}
 */
export const tenancyPropertyMainImage = property => {
  const images = tenancyPropertyImages(property);
  if (images.length > 0) {
    return (
      getObjectProp(images[0], 'large_url') || getObjectProp(images[0], 'url')
    );
  }
  return '';
};

/**
 * Updates the details of provided tenant in the list
 *
 * @param {array} tenants
 * @param {object} tenant
 *
 * @return {object}
 */
export const tenancyUpdateTenants = (tenants, tenant) =>
  tenants.map(item => {
    if (
      item[consts.API_TENANCY_TENANT_ID] ===
      tenant[consts.API_TENANCY_TENANT_ID]
    ) {
      return {...item, ...tenant};
    }
    return item;
  });

/**
 * Checks if agreemnt qualify as renewal.
 *
 * @param {string} subType
 *
 * @return {boolean}
 */
export const tenancyQualifyAsRenewal = subType =>
  typeof subType === 'undefined'
    ? false
    : ['renewal', 'variation', 'extension'].indexOf(subType) >= 0;

/**
 * Checks if tenant needs to supply bond amount when signing tenancy.
 *
 * @param {object} agreement
 * @param {object} tenant
 *
 * @return {boolean}
 */
export const tenancyRequireBondContribution = (agreement, tenant) => {
  if (hasNotSendAnyBondForm(agreement)) {
    return false;
  }
  const tenantBondAmountIsEmpty = isEmpty(
    toFloat(tenant[consts.API_TENANCY_TENANT_BOND_CONTRIBUTION])
  );
  if (tenancyQualifyAsRenewal(agreement[consts.API_TENANCY_TENANT_SUB_TYPE])) {
    if (tenant[consts.API_TENANCY_TENANT_RENEWAL_STATUS] === consts.JOINING) {
      return tenantBondAmountIsEmpty;
    }
    if (
      tenant[consts.API_TENANCY_TENANT_RENEWAL_STATUS] === consts.REMAINING &&
      tenant[consts.API_BOND_TENANT][
        consts.API_TENANCY_TENANT_SIGN_BOND_LODGE
      ] &&
      tenant[consts.API_BOND_AGREEMENT][
        consts.API_TENANCY_TENANT_BOND_CONTRIBUTION
      ] &&
      !isEmpty(
        Number.parseFloat(
          tenant[consts.API_BOND_AGREEMENT][
            consts.API_TENANCY_TENANT_BOND_CONTRIBUTION
          ]
        )
      )
    ) {
      return tenantBondAmountIsEmpty;
    }
    return false;
  }
  return tenantBondAmountIsEmpty;
};

/**
 * Gets the sum of bond contribution provided by tenants
 *
 * @param {array} tenants
 * @param {boolean} inCents - to return amount in cents
 *
 * @return {number}
 */
export const tenancyTotalBondContributed = (tenants, inCents = false) => {
  const total = tenants.reduce((sum, tenant) => {
    const amount = toFloat(tenant[consts.API_TENANCY_TENANT_BOND_CONTRIBUTION]);
    if (!amount) {
      return sum;
    }
    return sum + (inCents ? toCents(amount) : amount);
  }, 0);
  return total;
};

/**
 * Gets the remaining bond contribution amount of tenancy
 *
 * @param {number} total - total bond amount required for tenancy
 * @param {number} contributed - total contributed amount
 *
 * @return {number}
 */
export const tenancyRemainingBondContribution = (total, contributed) => {
  const remainder = total - contributed;
  return remainder > 0 ? remainder : 0;
};

/**
 *
 * @param {object} obj - object of address components
 *
 * @return {string}
 */
export const prepareTenantDocumentSignData = (agreement, tenant) => {
  if (tenant) {
    const renewal = tenancyQualifyAsRenewal(
      agreement[consts.API_TENANCY_TENANT_SUB_TYPE]
    );

    const refund =
      tenant.bondTenant[consts.API_TENANCY_TENANT_SIGN_BOND_REFUND];

    const lodgement =
      tenant.bondTenant[consts.API_TENANCY_TENANT_SIGN_BOND_LODGE];

    const changeOfTenant =
      tenant.bondTenant[consts.API_TENANCY_TENANT_SIGN_CHANGE_TENANT];

    const bondAmount = toFloat(
      tenant.bondTenant[consts.API_TENANCY_TENANT_BOND_CONTRIBUTION]
    );

    const totalBondAmount = toFloat(
      tenant.bondAgreement[consts.API_TENANCY_TENANT_BOND_CONTRIBUTION]
    );

    const disputeAmount = toFloat(
      tenant.bondTenant[consts.API_TENANCY_TENANT_BOND_DISPUTE_AMOUNT]
    );

    const refundAmount = toFloat(
      tenant.bondTenant[consts.API_TENANCY_TENANT_BOND_REFUND_AMOUNT]
    );

    const totalDisputeAmount = toFloat(
      tenant.bondAgreement[consts.API_TENANCY_TENANT_BOND_DISPUTE_AMOUNT]
    );

    const totalRefundAmount = toFloat(
      tenant.bondAgreement[consts.API_TENANCY_TENANT_BOND_AGREEMENT_REFUND_AMT]
    );

    return {
      bondAmount: bondAmount || null,
      changeOfTenant,
      disputeAmount: disputeAmount || null,
      lodgement,
      renewal,
      refund,
      refundAmount: refundAmount || null,
      totalBondAmount: totalBondAmount || null,
      totalDisputeAmount: totalDisputeAmount || null,
      totalRefundAmount: totalRefundAmount || null,
    };
  }
  return {};
};

export const tenancyIsTenantLastToSign = (tenancy, tenant) => {
  if (tenancy) {
    const tenants = tenancy[consts.API_TENANCY_TENANTS];
    const renewal = tenancyQualifyAsRenewal(
      tenancy[consts.API_TENANCY_TENANT_SUB_TYPE]
    );

    return tenants.reduce((value, currTenant) => {
      const isJoining = renewal
        ? isJoingingCheckByTenancy(tenancy, currTenant)
        : true;
      if (
        currTenant[consts.API_TENANCY_TENANT_ID] !==
          tenant[consts.API_TENANCY_TENANT_ID] &&
        isJoining
      ) {
        return (
          (hasTenantSignedDocuments(currTenant) ||
            toFloat(currTenant[consts.API_TENANCY_TENANT_BOND_CONTRIBUTION]) >
              0) &&
          value
        );
      }
      return true && value;
    }, true);
  }
  return false;
};

const isJoingingCheckByTenancy = (tenancy, currTenant) => {
  if (tenancy[consts.API_TENANCY_TENANT_SUB_TYPE] === consts.VARIATION) {
    return (
      currTenant[consts.API_TENANCY_TENANT_RENEWAL_STATUS] === consts.JOINING
    );
  }
  return (
    currTenant[consts.API_TENANCY_TENANT_RENEWAL_STATUS] === consts.JOINING ||
    currTenant[consts.API_TENANCY_TENANT_RENEWAL_STATUS] === consts.REMAINING
  );
};

/**
 * Prepares utilities props
 *
 * @param {object} applicant
 *
 * @return {array}
 */
export const prepareTenancyUtilitiesProps = tenancy => {
  const utilities = getObjectProp(tenancy, consts.API_TENANCY_MOVING_HUB);
  if (utilities && isString(utilities)) {
    const parsed = JSON.parse(utilities);
    return parsed || [];
  }
  return utilities || [];
};

export const tenancyPrepareFullDetails = (tenancy, authUserEmail) => {
  if (!isEmpty(tenancy)) {
    const property = tenancy[consts.API_TENANCY_PROPERTY];
    const address = propertyFullAddress(property);
    const propertyImgSrc = tenancyPropertyMainImage(property);
    const tenant = tenancyTenant(
      tenancy[consts.API_TENANCY_TENANTS],
      authUserEmail
    );
    const tenantDocData = prepareTenantDocumentSignData(tenancy, tenant);
    const status = tenancyStatus(tenancy, tenant);
    const type = tenancyAgreementType(
      tenancy[consts.API_TENANCY_TENANT_SUB_TYPE],
      tenancy[consts.API_TENANCY_TENANT_TYPE]
    );

    // Total bond contribution in summed in cents
    const totalBondContributedCents = tenancyTotalBondContributed(
      tenancy[consts.API_TENANCY_TENANTS],
      true
    );

    // Converted to dollars to prevent overflow
    const totalBondContributed = toDollars(totalBondContributedCents);

    // Remaining bond contribution subtracted in cents
    const remainingBondContributionCents = tenancyRemainingBondContribution(
      toCents(tenantDocData.totalBondAmount),
      totalBondContributedCents
    );

    // Converted to dollars after to prevent float overflow
    const remainingBondContribution = toDollars(remainingBondContributionCents);

    const isSigned = hasTenantSignedDocuments(tenant);

    const isBondContributionRequired = tenancyRequireBondContribution(
      tenancy,
      tenant
    );

    const isLast = tenancyIsTenantLastToSign(tenancy, tenant);

    const vehicles = isArray(tenancy[consts.API_TENANCY_VEHICLES])
      ? tenancy[consts.API_TENANCY_VEHICLES]
      : [];
    return {
      address,
      agencyLogoFileId:
        tenancy[consts.API_TENANCY_AGENCY][
          consts.API_TENANCY_AGENCY_LOGO_FILE_ID
        ],
      agreementType: tenancy[consts.API_TENANCY_TENANT_TYPE],
      agreementSubType: tenancy[consts.API_TENANCY_TENANT_SUB_TYPE],
      bondAmount: tenancy[consts.API_TENANCY_BOND_AMOUNT],
      id: tenancy[consts.API_TENANCY_ID],
      isBondContributionRequired,
      isFinalised: parseBool(tenancy[consts.API_TENANCY_IS_FINALISED]),
      isSigned,
      movingHub: prepareTenancyUtilitiesProps(tenancy),
      pmCompanyName: tenancy[consts.API_TENANCY_PM_COMPANY],
      pmContactPerson: tenancy[consts.API_TENANCY_PM_NAME],
      pmEmail: tenancy[consts.API_TENANCY_PM_EMAIL_ADDRESS],
      pmMobile: tenancy[consts.API_TENANCY_PM_MOBILE_NUMBER],
      pmPhone: tenancy[consts.API_TENANCY_PM_PHONE_NUMBER],
      propertyId: tenancy[consts.API_TENANCY_PROPERTY_ID],
      propertyImgSrc,
      remainingBondContribution:
        remainingBondContribution === false ? 0.0 : remainingBondContribution,
      startDate: tenancy[consts.API_TENANCY_START_DATE],
      status,
      tenant: {
        ...tenant,
        isLast,
      },
      vehicles,
      ...tenantDocData,
      tenants: tenancy[consts.API_TENANCY_TENANTS],
      term: tenancy[consts.API_TENANCY_TERM],
      totalBondContributed:
        totalBondContributed === false ? 0.0 : totalBondContributed,
      type,
      hasNotSendAnyBondForm: hasNotSendAnyBondForm(tenancy),
    };
  }
  return {};
};

export const hasNotSendAnyBondForm = agreement =>
  !!agreement.bond &&
  agreement.bond[consts.API_NOT_SEND_ANY_BOND_FORMS] === true;
