/* eslint-disable no-console */
import {handleServiceWorkerSubscriptions} from 'push';
import observeStore from 'utils/helpers/store';

const loginSubscriber = store =>
  observeStore(
    store,
    state => state.user.token,
    token =>
      handleServiceWorkerSubscriptions(
        process.env.REACT_APP_SERVICE_WORKER_RELATIVE_URL,
        {
          subscriptionEndpoint:
            process.env.REACT_APP_WEB_PUSH_SUBSCRIPTION_ENDPOINT,
          applicationServerKey: process.env.REACT_APP_WEB_PUSH_SERVER_KEY,
          userVisible: process.env.REACT_APP_USER_VISIBLE,
          isLoggedIn: !!token,
        }
      ).catch(console.error)
  );

export default loginSubscriber;
