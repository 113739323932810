import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'react-final-form';
import {Checkbox} from '@tenancy.nz/design-system';
import ValidationMessage from '../ValidationMessage';
import {getFieldProps} from '../../utils/helpers';

function FieldCheckbox({label, name, value, validate, ...rest}) {
  return (
    <Field
      name={name}
      validate={validate}
      type="checkbox"
      {...(value && {value})}>
      {({input, meta}) => {
        const {errorMessage, showError, ...fieldProps} = getFieldProps({
          input,
          meta,
        });
        return (
          <>
            <Checkbox
              color="primary"
              filledOnChecked
              label={label}
              {...fieldProps}
              {...rest}
            />
            <ValidationMessage
              message={errorMessage}
              name={`${fieldProps.name}_hidden`}
              show={showError}
            />
          </>
        );
      }}
    </Field>
  );
}

FieldCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  validate: PropTypes.func,
};

FieldCheckbox.defaultProps = {
  value: undefined,
  validate: null,
};

export default FieldCheckbox;
