import {UPGRADE_PRODUCTS} from '../constants';

export const prepareOrderProductParams = (params = {}) => ({
  include: ['order', 'product', 'checks'].join(','),
  'filter[product_id]': Object.values(UPGRADE_PRODUCTS).join('||'),
  hasActivePurchased: 1,
  mostRecentChecks: 1,
  latestUnique: 1,
  ...params,
});
